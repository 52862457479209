import { Options } from 'ky';
import { Err, Ok } from '../../lib/result';
import { HttpUtils } from '../../lib/utils.http';
import { ApsHttp } from '../../providers/http/aps-http.provider';
import { OasisHttp } from '../../providers/http/oasis-http.provider';
import { Segment } from '../../providers/segment/segment.provider';
import { Storage } from '../../providers/storage/storage.provider';
import { Session } from '../session/session.service';
import { UsersSchemas } from './users.schemas';

const _parse = HttpUtils.createScopedParser('Users', UsersSchemas);

export const Users = {
  async updateUserProfile(params: {
    currentVersion?: string;
    displayName?: string;
    companyName?: string;
    companyType?: string;
    industry?: string;
    industrySegment?: string;
    jobTitle?: string;
    phone?: string;
    favoriteProjectIds?: string[];
    consentToComms?: boolean;
  }) {
    try {
      const version = params.currentVersion
        ? String(Number(params.currentVersion) + 1)
        : Session.store.user?.profile?.version || '1';

      const {
        consentToComms,
        favoriteProjectIds,
        companyName,
        jobTitle,
        industry,
        industrySegment,
        ...settings
      } = params;

      const searchParams = consentToComms
        ? new URLSearchParams({ consentToCommunications: 'true' })
        : undefined;

      await OasisHttp.put('v1/users/@me', {
        body: JSON.stringify({
          email: Session.store.user?.email,
          settings: { ...Session.store.user?.profile, ...settings, version },
          favoriteProjectIds,
          companyName,
          jobTitle,
          industry,
          industrySegment,
        }),
        searchParams,
      }).json();

      if (version === '1') {
        Segment.track('User Profile Created', params);
      } else {
        Segment.track('User Profile Updated', params);
      }

      return Ok(params);
    } catch (error) {
      return HttpUtils.handleError(error, '[users.updateUserProfile]');
    }
  },

  async listActiveWorkshops(projectId: string, opts?: Options) {
    try {
      const res = await OasisHttp.get(`v1/workshops/@me/active?projectId=${projectId}`, opts).json();
      const data = _parse('listActiveWorkshops', res);
      const activeWorkshopId = data?.results?.[0]?.id;

      if (activeWorkshopId) {
        Session.setActiveWorkshop(activeWorkshopId);
      } else {
        Session.clearActiveWorkshop();
      }

      return Ok({
        pagination: data.pagination,
        results: data?.results || [],
      });
    } catch (error) {
      return HttpUtils.handleError(error, '[Users.listActiveWorkshops]');
    }
  },

  /**
   * @name toggleFavoriteProject
   */
  async toggleFavoriteProject(projectId: string) {
    const old = Session.store.favoriteProjectIds || [];

    try {
      if (!Session.store.user?.profile) {
        return Err({ code: 'REQUIRES_PROFILE' });
      }

      const favoriteProjectIds = Session.store.favoriteProjectIds.includes(projectId)
        ? Session.store.favoriteProjectIds.filter(id => id !== projectId)
        : [...Session.store.favoriteProjectIds, projectId];

      Session.store.favoriteProjectIds = favoriteProjectIds;

      const res = await Users.updateUserProfile({ favoriteProjectIds });

      return res;
    } catch (error) {
      Session.store.favoriteProjectIds = old;
      return HttpUtils.handleError(error, '[Users.toggleFavoriteProject]');
    }
  },

  async listUsersDevices(opts?: Options) {
    try {
      const res = await OasisHttp.get('v1/users/@me/devices', opts).json();
      const devices = _parse('listUsersDevices', res);
      const hasVrDevice = devices?.results.some(device => device.type.toLowerCase() === 'vr');

      Storage.set('hasVrDevice', hasVrDevice);

      return Ok({ devices, hasVrDevice });
    } catch (error) {
      return HttpUtils.handleError(error, '[Users.listUsersDevices]');
    }
  },

  /**
   * @name verifyTrialEligibility
   * Check the current user's eligibility to create a trial subscription.
   */
  async verifyTrialEligibility() {
    try {
      const ref = `${Session.store.user?.id}-${Date.now()}`;

      const data = _parse(
        'trials',
        await ApsHttp.trialsClient
          .post('v2', {
            headers: {
              'X-Request-Ref': ref,
              'X-Transaction-Ref': ref,
            },
            body: JSON.stringify({
              productLineExternalKey: 'WKSXR',
              userExternalKey: Session.store.user?.id,
              createSubscription: false,
            }),
          })
          .json()
      );

      return data.eligible;
    } catch (error) {
      await HttpUtils.handleError(error, '[Users.verifyTrialEligibility]');
      return false;
    }
  },

  /**
   * @name createTrial
   * Attempt to create a free trial for the current user.
   */
  async createTrial() {
    try {
      const ref = `${Session.store.user?.id}-${Date.now()}`;
      const data = _parse(
        'trials',
        await ApsHttp.trialsClient
          .post('v2', {
            headers: {
              'X-Request-Ref': ref,
              'X-Transaction-Ref': ref,
            },
            body: JSON.stringify({
              productLineExternalKey: 'WKSXR',
              userExternalKey: Session.store.user?.id,
              createSubscription: true,
            }),
          })
          .json()
      );

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Users.createTrial]');
    }
  },
};
