import { Device, Oasis, WorkshopsSchemas } from '@oasis/sdk';
import { WorkshopUtils } from '@oasis/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { OasisError } from '~/shared/utils/oasis-error';

export const workshopsMutations = {
  useCreateWorkshop() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Workshops.createWorkshop>[0]) {
        const res = await Oasis.Workshops.createWorkshop({
          projectId: params.projectId,
          attrs: {
            ...params.attrs,
          },
        });

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to create workshop.',
              errorMessages: {
                BAD_REQUEST: 'Check your inputs and try again. If this problem persists contact support.',
                FORBIDDEN: 'You are not allowed to create a workshop.',
              },
            },
          });
        }

        // @TODO move this logic to backend
        console.info('Using backend to store fluid documentId');
        /**
         * 1- Create a new workshop without fluid document (because the workshop id is required to create a fluid document)
         * 2- Create a new fluid document to be associated with the newly created workshop
         * 3- update the workshop with the fluid document id
         **/
        const fluidRes = await Oasis.Fluid.createWorkshopDocument(res.value.id);
        if (!fluidRes.ok) {
          // There is a migration logic when loading the collaboration extension, thus, we
          // want to gracefully handle this error and not disturb the workshop creation flow.
          Oasis.Logger.error({
            error: fluidRes.error,
            msg: '[WorkshopMutations.useCreateWorkshop] Failed to create fluid document',
          });
          return res.value;
        }

        await Oasis.Workshops.updateWorkshop({
          workshopId: res.value.id,
          attrs: {
            fluidState: {
              id: fluidRes.value.documentId,
            },
          },
        });

        return res.value;
      },
      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: ['workshops'],
        });
      },
    });
  },

  useUpdateWorkshop() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Workshops.updateWorkshop>[0]) {
        const res = await Oasis.Workshops.updateWorkshop(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to create workshop.',
              errorMessages: {
                BAD_REQUEST: 'Check your inputs and try again. If this problem persists contact support.',
                FORBIDDEN: 'You are not allowed to create a workshop.',
              },
            },
          });
        }

        return res.value;
      },
      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: ['workshops'],
        });

        NotificationManager.push({
          status: 'success',
          content: 'Successfully updated workshop.',
        });
      },
    });
  },

  useDeleteWorkshop() {
    const queryClient = useQueryClient();
    const [searchParams, setSearchParams] = useSearchParams();

    return useMutation({
      async mutationFn(params: { id: string; name?: string }) {
        const res = await Oasis.Workshops.deleteWorkshop(params.id);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: (
                <p>
                  <span className="font-bold">{params.name}</span> failed to delete. Try again.
                </p>
              ),
              errorMessages: {
                NOT_FOUND: (
                  <p>
                    <span className="font-bold">{params.name}</span> does not exist.
                  </p>
                ),
                FORBIDDEN: (
                  <p>
                    You do not have permission to delete <span className="font-bold">{params.name}</span>
                  </p>
                ),
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({
          queryKey: ['workshops'],
        });

        if (searchParams.get('workshopId') === 'id') {
          searchParams.delete('workshopId');
          setSearchParams(searchParams);
        }

        NotificationManager.push({
          status: 'success',
          content: (
            <p>
              <span className="font-bold">{params.name || 'Workshop'}</span> deleted successfully.
            </p>
          ),
        });
      },
    });
  },

  useUpdateWorkshopPermissions() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Workshops.updateWorkshopPermissions>[0]) {
        const res = await Oasis.Workshops.updateWorkshopPermissions(params);

        if (!res.ok) {
          NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage:
                'Failed to update workshop permissions. If this problem persists contact support.',
              errorMessages: {
                BAD_REQUEST: 'Check your inputs and try again. If this problem persists contact support.',
                FORBIDDEN: "You are not allowed to update this workshop's permissions.",
              },
            },
          });

          throw new OasisError({ code: res.error.code }, '[Mutations.useUpdateWorkshopPermissions]');
        }

        return res.value;
      },
      onMutate(params) {
        const value = WorkshopsSchemas.listWorkshopMembers.safeParse(
          queryClient.getQueryData(['workshop', params.workshopId, 'members'])
        );

        if (value.success) {
          const optimisticData = cloneDeep(value.data);

          for (const id of params.data.userIds) {
            for (const member of optimisticData.results.members) {
              if (member.autodeskId === id) {
                member.permission = params.data.permission;
              }
            }
          }

          queryClient.setQueryData(['workshop', params.workshopId, 'members'], optimisticData);
        }

        return value.data;
      },
      onError(_error, params, previousData) {
        if (previousData) {
          queryClient.setQueryData(['workshop', params.workshopId, 'members'], previousData);
        }
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({
          queryKey: ['workshop', params.workshopId, 'members'],
        });
      },
    });
  },

  useUpdateWorkshopSettings() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Workshops.updateWorkshopSettings>[0]) {
        const res = await Oasis.Workshops.updateWorkshopSettings(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to update workshop settings. If this problem persists contact support.',
              errorMessages: {
                BAD_REQUEST: 'Check your inputs and try again. If this problem persists contact support.',
                FORBIDDEN: "You are not allowed to update this workshop's settings.",
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({
          queryKey: ['workshop', params.workshopId, 'settings'],
        });
      },
    });
  },

  useUpdateWorkshopSettingsModel() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: {
        workshopId: string;
        projectId: string;
        documentUrn: string;
        viewGuid?: string;
        currentSettingsVersion: number;
      }) {
        if (!Oasis.Session.store.user?.id) {
          throw new OasisError({ code: 'UNAUTHORIZED' }, '[Mutations.useUpdateWorkshopSettingsModel]');
        }

        const item = await Oasis.Files.findDocumentById({
          documentId: params.documentUrn,
          projectId: params.projectId,
        });

        if (!item.ok) {
          throw new OasisError(item.error, '[Mutations.useUpdateWorkshopSettingsModel]');
        }

        const attrs: Record<string, { value: string }> = {
          murnxfrm: {
            value: WorkshopUtils.generateMurnxfrmValue(params.projectId, item.value.latestVersionId),
          },
          prid: { value: params.projectId },
          murn: { value: item.value.latestVersionId },
          mupdatedby: { value: Oasis.Session.store.user.id },
          mupdatedat: { value: new Date().toISOString() },
          mrxfrm: { value: 'T:0.0,0.0,0.0;R:1.0,0.0,0.0,0.0;S:0.0,0.0,0.0' },
        };

        if (params.viewGuid) {
          attrs.mvid = { value: params.viewGuid };
        }

        const res = await Oasis.Workshops.updateWorkshopSettings({
          workshopId: params.workshopId,
          currentSettingsVersion: params.currentSettingsVersion || 0,
          attrs,
        });

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to update workshop settings. If this problem persists contact support.',
              errorMessages: {
                BAD_REQUEST: 'Check your inputs and try again. If this problem persists contact support.',
                FORBIDDEN: "You are not allowed to update this workshop's settings.",
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({
          queryKey: ['workshop', params.workshopId, 'settings'],
        });
      },
    });
  },

  useRemoveWorkshopSettingsModel() {
    const queryClient = useQueryClient();

    return useMutation({
      async mutationFn(params: { projectId: string; workshopId: string; currentSettingsVersion?: number }) {
        if (!Oasis.Session.store.user?.id) {
          throw new OasisError({ code: 'UNAUTHORIZED' }, '[Mutations.useUpdateWorkshopSettingsModel]');
        }

        const res = await Oasis.Workshops.updateWorkshopSettings({
          workshopId: params.workshopId,
          currentSettingsVersion: params.currentSettingsVersion || 0,
          attrs: {
            murnxfrm: { value: '' },
            prid: { value: '' },
            murn: { value: '' },
            mvid: { value: '' },
            mupdatedby: { value: Oasis.Session.store.user.id },
            mupdatedat: { value: new Date().toISOString() },
          },
        });

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to update workshop settings. If this problem persists contact support.',
              errorMessages: {
                BAD_REQUEST: 'Check your inputs and try again. If this problem persists contact support.',
                FORBIDDEN: "You are not allowed to update this workshop's settings.",
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({
          queryKey: ['workshop', params.workshopId, 'settings'],
        });
      },
    });
  },

  useOpenWorkshopOnDevice() {
    return useMutation({
      async mutationFn(params: { device: Device; id: string }) {
        const res = await Oasis.NetworkCommands.openWorkshopOnDevice({
          id: params.id,
          device: params.device,
        });

        if (!res.ok) {
          NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Error launching workshop. Please try again.',
              errorMessages: {
                UNAUTHORIZED: 'User id not properly set',
                WORKSHOP_NOT_FOUND: 'Failed to find workshop',
                FORK_FAILURE: 'Error preparing auth for local viewer',
                TOKEN_WRITE_FAILURE: 'Error preparing auth for local viewer',
                VIEWER_LAUNCH_ERROR: 'Error launching viewer',
              },
            },
          });
        }
      },
      onSuccess(_data) {},
    });
  },
};
