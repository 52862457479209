import Avatar from '@adsk/alloy-react-avatar';
import Button from '@adsk/alloy-react-button';
import { MoreVerticalIcon } from '@adsk/alloy-react-icon';
import type { WorkshopXRUser } from '@oasis/fluid-interop';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { Popover } from '~/shared/components/base/popover';
import { MicIcon } from '~/shared/components/icons/mic';
import { MicOffIcon } from '~/shared/components/icons/mic-off';

interface Props {
  user: WorkshopXRUser;
  displayMode: 'viewer-panel' | 'vr-people-menu';
  isCurrentUser?: boolean;
  isHost?: boolean;
  showShadow?: boolean;
}

export function WorkshopPeopleListItem({ user, displayMode, isCurrentUser, isHost, showShadow }: Props) {
  const $env = Oasis.Env.useStore();
  const isCompact = displayMode === 'viewer-panel';

  const fullName =
    user.additionalDetails.userName && user.additionalDetails.userLastName
      ? `${user.additionalDetails.userName} ${user.additionalDetails.userLastName}`
      : 'Anonymous';

  const device = user.additionalDetails.device;

  return (
    <li
      className={clsx(
        'flex items-center border-b border-charcoal-200 px-4 hover:bg-charcoal-50',
        isCompact ? 'py-3' : 'py-4',
        isCurrentUser && 'transition-shadow sticky top-0 bg-white z-10',
        isCurrentUser && showShadow && 'shadow-low'
      )}
    >
      <div className="flex items-center">
        <div className={clsx('rounded-full border-4', isHost ? 'border-blue-300' : 'border-transparent')}>
          <Avatar name={fullName} id={user.userId} imageUrl={user.additionalDetails.userImage} size="medium" />
        </div>
        <div className="ml-2">
          <p className="font-bold">{isCurrentUser ? `${fullName} (Me)` : fullName}</p>
          <p className="text-body-sm text-charcoal-500">
            {isHost ? (
              'VR host (following)'
            ) : (
              <>
                {device === 'VR' && 'VR collaborator'}
                {device === 'DESKTOP' && 'Desktop viewer'}
                {device === 'WEB' && 'Web viewer'}
                {device === 'WEB-VR' && 'Console'}
              </>
            )}
          </p>
        </div>
      </div>

      <div className={clsx('ml-auto flex items-center', isCompact ? 'gap-2' : 'gap-4')}>
        {$env.isDevMode && (isCurrentUser || (device !== 'WEB' && !isCompact)) && (
          <>
            {isCompact ? (
              <Popover>
                <Popover.Trigger className="data-state-open:text-blue-500">
                  <div className="flex items-center justify-center w-6 h-6">
                    <MoreVerticalIcon />
                  </div>
                </Popover.Trigger>

                <Popover.Content align="end" hideArrow className="!min-w-36 mt-2">
                  <_Actions deviceId={user.deviceId} isCurrentUser={isCurrentUser} inPopover />
                </Popover.Content>
              </Popover>
            ) : (
              <_Actions deviceId={user.deviceId} isCurrentUser={isCurrentUser} />
            )}
          </>
        )}

        <_MuteButton userId={user.userId} isCurrentUser={isCurrentUser} />
      </div>
    </li>
  );
}

interface MuteButtonProps {
  userId: string;
  isCurrentUser?: boolean;
}

function _MuteButton(props: MuteButtonProps) {
  const $voice = Oasis.Voice.useStore();

  if (!props.isCurrentUser) {
    const isMuted = $voice.mutedUsers.get(props.userId);

    return (
      <button
        className={clsx(
          'flex items-center justify-center w-6 h-6 text-charcoal-700',
          isMuted ? 'text-red-500 hover:text-red-700' : 'hover:text-blue-500'
        )}
      >
        {isMuted ? <MicOffIcon className="w-6 h-6" /> : <MicIcon className="w-6 h-6" />}
      </button>
    );
  }

  return (
    <button
      className={clsx(
        'flex items-center justify-center w-6 h-6 text-charcoal-700',
        $voice.muted ? 'text-red-500 hover:text-red-700' : 'hover:text-blue-500'
      )}
      onClick={() => Oasis.Voice.toggleMuted()}
    >
      {$voice.muted ? <MicOffIcon className="w-6 h-6" /> : <MicIcon className="w-6 h-6" />}
    </button>
  );
}

interface ActionsProps {
  deviceId: string;
  isCurrentUser?: boolean;
  inPopover?: boolean;
}

function _Actions({ deviceId, isCurrentUser, inPopover }: ActionsProps) {
  if (isCurrentUser) {
    if (inPopover) {
      return (
        <div>
          {/* <Popover.Button hideIcon onClick={() => Oasis.WebViewerRpc.gatherAll()}>
            Gather all
          </Popover.Button> */}
          <Popover.Button hideIcon onClick={() => Oasis.WebViewerRpc.muteAll()} className="rounded">
            Mute all
          </Popover.Button>
        </div>
      );
    }

    return (
      <div className="flex gap-4">
        <Button variant="secondary" onClick={() => Oasis.WebViewerRpc.gatherAll()}>
          Gather all
        </Button>
        <Button variant="primary" onClick={() => Oasis.WebViewerRpc.muteAll()}>
          Mute all
        </Button>
      </div>
    );
  }

  if (inPopover) {
    return (
      <div className="py-2">
        <Popover.Button hideIcon onClick={() => Oasis.WebViewerRpc.gather(deviceId)}>
          Gather
        </Popover.Button>
        <Popover.Button hideIcon onClick={() => Oasis.WebViewerRpc.goToUser(deviceId)}>
          Go-to
        </Popover.Button>
      </div>
    );
  }

  return (
    <div className="flex gap-4">
      <Button variant="secondary" onClick={() => Oasis.WebViewerRpc.gather(deviceId)}>
        Gather
      </Button>
      <Button variant="primary" onClick={() => Oasis.WebViewerRpc.goToUser(deviceId)}>
        Go-to
      </Button>
    </div>
  );
}
