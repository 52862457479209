import Button from '@adsk/alloy-react-button/es/Button';
import { Oasis } from '@oasis/sdk';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import { landingUrl } from '~/shared/utils/const.asset-urls';
import { MARKETING_URLS } from '~/shared/utils/const.marketing';

interface Props {
  isTrial?: boolean;
}

export default function LoginPage(props: Props) {
  const $session = Oasis.Session.useStore();
  const navigate = useNavigate();
  const branding = useTemporaryBranding();

  useEffect(() => {
    if ($session.status === 'AUTHENTICATED') {
      navigate($session.license.trialEligible ? '/oauth/trial?preauthorized=true' : '/projects');
    }
  }, [$session.status, $session.license.trialEligible, navigate]);

  return (
    <main
      className="min-w-screen min-h-screen flex items-center justify-center bg-cover"
      style={{ backgroundImage: `url(${landingUrl})` }}
    >
      <section className="w-full max-w-[26.25rem] bg-white shadow-high rounded-md py-10 px-7">
        <h1 className="text-center text-heading-2">Welcome to {branding.productName}</h1>

        {!props.isTrial && (
          <div className="space-y-6 mt-6">
            <div>
              <h2 className="text-body-sm mb-2">New to Autodesk?</h2>
              <p>
                <Button
                  as="a"
                  variant="primary"
                  href="https://accounts.autodesk.com/register"
                  target="_blank"
                  rel="noreferrer"
                  className="w-full"
                >
                  Create account
                </Button>
              </p>
            </div>

            <div>
              <h2 className="text-body-sm mb-2">Already have an account?</h2>
              <p>
                <Button
                  as="a"
                  variant="secondary"
                  className="w-full"
                  href="/oauth"
                  onClick={e => {
                    e.preventDefault();
                    navigate('/oauth');
                  }}
                >
                  Sign in
                </Button>
              </p>
            </div>

            <p className="mt-6">
              <a
                href={MARKETING_URLS.FREE_TRIAL_FORM}
                target="_blank"
                rel="noreferrer"
                className="text-label-md text-blue-700 hover:text-blue-500"
              >
                Learn more
              </a>
            </p>
          </div>
        )}

        {props.isTrial && (
          <div className="mt-4">
            <h2 className="text-body-md font-bold mb-6">
              Sign in with your Autodesk account to begin your trial.
            </h2>
            <p>
              <Button
                as="a"
                variant="primary"
                className="w-full"
                href="/oauth?trial=true"
                onClick={e => {
                  e.preventDefault();
                  navigate('/oauth?trial=true');
                }}
              >
                Sign in
              </Button>
            </p>
          </div>
        )}

        <div className="mt-8 flex justify-center">
          <img src={branding.logoUrl} alt={branding.productName + ' logo'} className="h-[2.125rem]" />
        </div>
      </section>
    </main>
  );
}
