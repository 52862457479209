import { Oasis } from '@oasis/sdk';
import { FileUtils } from '@oasis/utils';
import { Slot } from '@radix-ui/react-slot';
import { useQueryClient } from '@tanstack/react-query';
import { MouseEvent, ReactElement, cloneElement, useMemo } from 'react';
import { useProjectContext } from '~/shared/contexts/project-context';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';
import { useOpenModal } from '~/shared/hooks/use-modal';
import { useMonitorDocProcessingState } from '~/shared/hooks/use-monitor-doc-processing-state';
import { NotificationManager } from '../notification-manager';
import { Tooltip } from '../tooltip';

const viewOnlyTooltipChunk = <p>You have view only permissions in the workshop you are trying to share to.</p>;
const notInWorkshopChunk = <p>You must be in a workshop to share models to the stage.</p>;
const notInSameProjectAsWorkshopChunk = (
  <p>You can only share files from the same project your current active workshop is in.</p>
);
const modelProcessingChunk = <p>Unprocessed files cannot be shared to a workshop.</p>;
const notSupportedChunk = (
  <>
    <p className="mb-3">This is an unsupported file type.</p>
    <p>Supported file types:</p>
    <ul className="list-disc list-inside ml-1">
      <li>Revit</li>
      <li>Navisworks</li>
    </ul>
  </>
);

interface Props {
  fileVersionUrn: string;
  viewGuid?: string;
  tooltipPlacement: 'top' | 'bottom' | 'right' | 'left';
  fileType?: string;
  children: ReactElement;
  isShortHand?: boolean;
  isFileShared?: boolean;
  isFileProcessing?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  is3dView?: boolean;
}

export function ShareToWorkshopButton({
  tooltipPlacement,
  fileVersionUrn,
  viewGuid,
  fileType = '',
  children,
  isShortHand = false,
  isFileShared = false,
  isFileProcessing = false,
  onClick,
  is3dView,
}: Props) {
  const $mqtt = Oasis.Mqtt.useStore();
  const $session = Oasis.Session.useStore();
  const queryClient = useQueryClient();

  const { projectId } = useProjectContext();
  const openModal = useOpenModal();
  const [enableAllFileTypes, enableIfc] = useFeatureFlags([
    '231128-4667-enable-all-file-types',
    '241209-7730-ifc-support',
  ]);
  const monitoredProccessState = useMonitorDocProcessingState({
    documentVersionId: fileVersionUrn,
    isProcessingDefault: isFileProcessing,
  });

  const isInWorkshop = !!$session.activeWorkshop;
  const isInSameProjectAsWorkshop = isInWorkshop && $session.activeWorkshop?.projectId === projectId;
  const isSupported = FileUtils.supportedInVR(fileType, { enableAllFileTypes, enableIfc });
  const userIsViewOnly = $session.activeWorkshop?.permission === 'VIEW';

  async function shareToWorkshop(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();

    if ($mqtt.status !== 'CONNECTED') {
      return NotificationManager.push({
        status: 'error',
        type: 'toast',
        content: 'Unable to establish connection. Failed to share model to stage.',
      });
    }

    if ($session.activeWorkshop && $session.activeWorkshop.permission !== 'VIEW') {
      const args = {
        projectId,
        fileVersionUrn,
        viewGuid,
        workshopId: $session.activeWorkshop.id,
      };

      const res = await Oasis.NetworkCommands.shareToWorkshop(args);

      if (!res.ok) {
        NotificationManager.push({
          status: 'error',
          content: 'Failed to send share to workshop message. Try again later.',
        });
      }

      if (is3dView) {
        Oasis.Segment.track('3d View Shared From Workshop Details Panel', args);
      }

      if (isShortHand) {
        NotificationManager.push({
          status: 'success',
          type: 'toast',
          content: (
            <p>
              File shared to <span className="font-bold">{$session.activeWorkshop?.name}</span>.
            </p>
          ),
        });
      }

      // Optimistically update the active model and view in the cache
      const model = await Oasis.Files.findDocumentVersionById({
        projectId,
        documentVersionId: fileVersionUrn,
      });
      const activeView = await Oasis.Files.find3dView({
        projectId,
        documentVersionId: fileVersionUrn,
        viewGuid,
      });

      queryClient.setQueryData(['@me', 'active', 'model', projectId], () => {
        return {
          inProject: true,
          model: model.ok ? model.value : null,
          activeViewableGuid: viewGuid,
          activeView: activeView.ok ? activeView.value : null,
        };
      });

      // If the shareToWorkshop call is from context menu click, set menu to closed state.
      onClick?.(e);
      return;
    }

    if (!Oasis.Env.store.isVr) {
      openModal('shared-to-workshop');
    }
  }

  const shortHandTooltipContent = useMemo(() => {
    const fileSharedChunk = <p>Shared to {$session.activeWorkshop?.name}</p>;
    const fileToBeSharedChunk = <p>Share to {$session.activeWorkshop?.name}</p>;

    if ($mqtt.status !== 'CONNECTED') {
      return <div className="text-body-sm space-y-5">Unable to share. Waiting for connection...</div>;
    }

    if (monitoredProccessState.isProcessing) {
      return <div className="text-body-sm space-y-5">{modelProcessingChunk}</div>;
    }

    return <div className="text-body-sm space-y-5">{isFileShared ? fileSharedChunk : fileToBeSharedChunk}</div>;
  }, [isFileShared, $session.activeWorkshop, $mqtt.status, monitoredProccessState.isProcessing]);

  const tooltipContent = useMemo(() => {
    if (
      !isSupported ||
      !isInWorkshop ||
      !isInSameProjectAsWorkshop ||
      userIsViewOnly ||
      monitoredProccessState.isProcessing
    ) {
      const shareable = isSupported && isInSameProjectAsWorkshop && !userIsViewOnly;

      return (
        <div data-testid="tooltip-content" className="text-body-sm space-y-5">
          {!isSupported && notSupportedChunk}
          {!isInWorkshop && notInWorkshopChunk}
          {isInWorkshop && !isInSameProjectAsWorkshop && notInSameProjectAsWorkshopChunk}
          {userIsViewOnly && viewOnlyTooltipChunk}
          {shareable && monitoredProccessState.isProcessing && modelProcessingChunk}
        </div>
      );
    }

    return null;
  }, [isSupported, isInWorkshop, isInSameProjectAsWorkshop, userIsViewOnly, monitoredProccessState]);

  // If we're using the shorthand button we hide it under the following conditions:
  // - The file is not supported in VR
  // - The user is not in a workshop
  // - The user is not in the same project as their current workshop
  // - The user only has view permissions
  if (isShortHand && (!isSupported || !isInWorkshop || !isInSameProjectAsWorkshop || userIsViewOnly)) {
    return null;
  }

  if (isShortHand) {
    return (
      <Tooltip content={shortHandTooltipContent} placement={tooltipPlacement} className="pointer-events-none">
        <Slot onClick={shareToWorkshop}>
          {cloneElement(children, {
            'data-testid': 'shorthand-btn-children',
            disabled:
              $mqtt.status !== 'CONNECTED' ||
              userIsViewOnly ||
              isFileShared ||
              monitoredProccessState.isProcessing,
          })}
        </Slot>
      </Tooltip>
    );
  }

  return (
    <Tooltip content={tooltipContent} placement={tooltipPlacement} className="mx-2">
      <Slot onClick={shareToWorkshop}>
        {cloneElement(children, {
          'data-testid': 'full-btn-children',
          disabled:
            $mqtt.status !== 'CONNECTED' ||
            !isSupported ||
            !isInWorkshop ||
            !isInSameProjectAsWorkshop ||
            userIsViewOnly ||
            monitoredProccessState.isProcessing,
        })}
      </Slot>
    </Tooltip>
  );
}
