import { Oasis } from '@oasis/sdk';
import { Link } from 'react-router-dom';
import { Popover } from '~/shared/components/base/popover';
import { ExternalLinkIcon } from '~/shared/components/icons/external-link-icon';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';

export function InfoMenu() {
  const $env = Oasis.Env.useStore();
  const branding = useTemporaryBranding();

  const linkClasses = 'flex items-center py-2 px-4 w-full text-charcoal-800 hover:text-blue-500';

  return (
    <Popover>
      <Popover.Trigger className="flex items-center justify-center w-10 h-12 text-charcoal-600 hover:text-charcoal-800">
        <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 .5A6.5 6.5 0 1 0 13.507 7 6.507 6.507 0 0 0 7 .5Zm0 11.213a.894.894 0 1 1 0-1.787.894.894 0 0 1 0 1.787Zm1.447-4.666a8.7 8.7 0 0 0-.414.366 1.2 1.2 0 0 0-.373.667.667.667 0 0 1-1.333 0c.04-.63.32-1.221.78-1.653l.473-.42c.467-.4.593-.507.593-1.087A1.093 1.093 0 0 0 7 3.9 1.093 1.093 0 0 0 5.84 5a.667.667 0 0 1-1.333 0A2.42 2.42 0 0 1 7 2.567a2.4 2.4 0 0 1 2.513 2.38 2.373 2.373 0 0 1-1.053 2.1h-.013Z"
            fill="currentColor"
          />
        </svg>
      </Popover.Trigger>

      <Popover.Content side="bottom" align="end" alignOffset={-28} className="w-64 text-body-sm py-2">
        <a href={$env.metaStoreUrl} target="_blank" rel="noreferrer" className={linkClasses}>
          {branding.shortProductName} for Meta Quest <ExternalLinkIcon className="text-inherit ml-auto" />
        </a>

        <a
          href="https://help.autodesk.com/view/WKSXR/ENU/"
          target="_blank"
          className={linkClasses}
          rel="noreferrer"
        >
          Help center <ExternalLinkIcon className="text-inherit ml-auto" />
        </a>

        <a
          href="https://www.autodesk.com/support/technical/product/workshop-xr"
          target="_blank"
          className={linkClasses}
          rel="noreferrer"
        >
          Contact support <ExternalLinkIcon className="text-inherit ml-auto" />
        </a>

        <hr className="border-charcoal-200 my-2 mx-4" />

        <a
          href="https://www.autodesk.com/company/terms-of-use/en/general-terms"
          target="_blank"
          className={linkClasses}
          rel="noreferrer"
        >
          Terms and conditions <ExternalLinkIcon className="text-inherit ml-auto" />
        </a>

        <Link to="/about" className={linkClasses}>
          About <ExternalLinkIcon className="text-inherit ml-auto" />
        </Link>
      </Popover.Content>
    </Popover>
  );
}
