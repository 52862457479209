import { Oasis } from '@oasis/sdk';
import { shutdownViewer } from './useWorkshopXRViewer';

export enum LMVCPPBridgeEvent {
  ViewerCreated = 'viewerCreated',
}

type BridgeEventCallback = (result: string) => void;

class LMVCPPBridge {
  private eventListeners: { eventName: string; callback: BridgeEventCallback }[] = [];
  private eventTarget = new EventTarget();

  public viewer: Autodesk.Viewing.GuiViewer3D | undefined = undefined;
  public versionId: string | null = null;

  moveEventListenersToViewer() {
    if (this.viewer) {
      this.eventListeners.forEach(({ eventName, callback }) => {
        this.viewer?.addEventListener(eventName, callback);
      });
      this.eventListeners = [];
    } else {
      console.error('Viewer not initialized. Cannot move event listeners.');
    }
  }

  shutdownViewer() {
    if (this.viewer) {
      shutdownViewer(this.viewer);
    }
    this.viewer = undefined;
    this.versionId = null;
  }

  addEventListener(eventName: string, callback: BridgeEventCallback) {
    const cb = (event: any) => {
      // We only send the type information, because the event objects can contain large amounts of data with circular references.
      // This needs to be changed once we know what data we are interested in.
      // For now we only want to know that an event was triggered.
      callback(JSON.stringify({ type: event.type }));
    };

    if (this.viewer) {
      this.viewer.addEventListener(eventName, cb);
    } else {
      if (eventName === LMVCPPBridgeEvent.ViewerCreated) {
        this.eventTarget.addEventListener(eventName, cb);
      } else {
        this.eventListeners.push({ eventName, callback: cb });
      }
    }
  }

  dispatchEvent(eventName: LMVCPPBridgeEvent, event: any) {
    this.eventTarget.dispatchEvent(new CustomEvent(eventName, { detail: event }));
  }

  getProperties2(dbId: number, onSuccess: BridgeEventCallback, onError: BridgeEventCallback) {
    if (!this.viewer?.model) {
      // this should not happen, because the C++ code should not call this method before the viewer is initialized
      onError('Cloud data access failed (viewer not initialized)');
      console.error('Cloud data access failed (viewer not initialized)');
      return;
    }
    this.viewer.model.getProperties2(
      // @ts-ignore - LMV Typescript typings are incorrect
      dbId,
      result => {
        onSuccess(JSON.stringify(result));
      },
      error => {
        onError(JSON.stringify(error));
        console.error(error);
      }
    );
  }

  getObjectTree(onSuccess: BridgeEventCallback, onError: BridgeEventCallback) {
    try {
      const instanceTree = this.viewer?.model?.getInstanceTree();
      if (!instanceTree) {
        // this should not happen, because the C++ code should not call this method before the viewer is initialized
        onError('Cloud data access failed (viewer not initialized)');
        console.error('Cloud data access failed (viewer not initialized)');
        return;
      }
      const rootNodeId = instanceTree.getRootId();
      const instanceTreeData: {
        root: { dbId: number; children: any[] };
        dbIdToInstanceTreeNode: { [key: number]: { dbId: number; children: any[] } };
      } = {
        root: {
          dbId: rootNodeId,
          children: [],
        },
        dbIdToInstanceTreeNode: {},
      };

      const stack: { dbId: number; parentId: number | undefined }[] = [
        { dbId: rootNodeId, parentId: undefined },
      ];
      while (stack.length > 0) {
        const { dbId, parentId } = stack.pop()!;
        const node = {
          dbId: dbId,
          children: [],
        };
        instanceTreeData.dbIdToInstanceTreeNode[dbId] = node;
        if (parentId !== undefined) {
          if (instanceTreeData.dbIdToInstanceTreeNode[parentId]) {
            instanceTreeData.dbIdToInstanceTreeNode[parentId].children.push(node);
          }
        } else {
          instanceTreeData.root.children.push(node);
        }
        instanceTree.enumNodeChildren(dbId, childId => {
          stack.push({ dbId: childId, parentId: dbId });
        });
      }

      // Convert instanceTreeData to JSON and pass to onSuccess
      onSuccess(JSON.stringify(instanceTreeData));
    } catch (error) {
      Oasis.Logger.error({
        msg: 'An error occurred while getting the object tree.',
      });
      onError('An error occurred while getting the object tree.');
    }
  }
}

export default LMVCPPBridge;
