import { PushPinExtension } from '@adsk/acc-issues/es/acs-issues-ui/state/pushPinExtension/pushPinExtensionContext';
import { transformIssuesToPushPinData } from '@adsk/acc-issues/es/acs-issues-ui/utils/data/pushpinHelpers';
import { useMemo, useEffect } from 'react';
import { useCurrentIssuesData } from '~/features/workshops/pages/viewer/components/useCurrentIssuesData';
import { Hooks } from '@adsk/acc-viewer-ui/es';
const { useExtension } = Hooks;
export const pushpinExtensionName = 'Autodesk.BIM360.Extension.PushPin';

interface UseWebViewerPushpinExtensionProps {
  versionId: string;
  viewer: Autodesk.Viewing.GuiViewer3D;
  modelLoaded: boolean;
}

export const useWebViewerPushpinExtension = ({
  versionId,
  viewer,
  modelLoaded,
}: UseWebViewerPushpinExtensionProps) => {
  const pushpinExtension = useExtension(viewer, pushpinExtensionName) as PushPinExtension;
  const { issues } = useCurrentIssuesData({
    versionId,
  });
  const issuesUpdatedAtString = issues?.map(issue => issue.updatedAt).join(',');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoedIssues = useMemo(() => issues, [issuesUpdatedAtString]);

  useEffect(() => {
    // @ts-ignore
    if (viewer && viewer.impl?.modelQueue()) {
      viewer.loadExtension(pushpinExtensionName);
    }
  }, [viewer]);

  useEffect(() => {
    if (pushpinExtension && memoedIssues && modelLoaded) {
      const issuesPinsTransformed = transformIssuesToPushPinData(pushpinExtension, memoedIssues);
      pushpinExtension.loadItemsV2(issuesPinsTransformed);
      pushpinExtension.showAll();

      pushpinExtension.pushPinManager?.items().forEach(item => {
        const foundIssueId = memoedIssues.find(issue => issue.id === item.data.id);
        const isFoundIssueLocationChanged =
          foundIssueId && foundIssueId.linkedDocuments[0]?.details.position !== item.data.position;
        if (!foundIssueId || isFoundIssueLocationChanged) {
          pushpinExtension.removeItemById(item.data.id);
        }

        if (isFoundIssueLocationChanged) {
          pushpinExtension.loadItemsV2(transformIssuesToPushPinData(pushpinExtension, [foundIssueId]));
        }
      });
    }
  }, [pushpinExtension, memoedIssues, modelLoaded, viewer]);
};
