import Button, { LinkButton } from '@adsk/alloy-react-button';
import Modal from '@adsk/alloy-react-modal';
import { Oasis } from '@oasis/sdk';
import { Label } from '@radix-ui/react-context-menu';
import { useQueryClient } from '@tanstack/react-query';
import { MouseEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { CodeInput } from '~/shared/components/forms/code-input';
import { useModal } from '~/shared/hooks/use-modal';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';

const EXPIRATION_DURATION = 1000 * 60 * 5 - 1000; // 10 minutes
let expirationTimeout: NodeJS.Timeout | undefined;
let voice: SpeechSynthesisVoice | undefined = undefined;

if ('speechSynthesis' in window) {
  speechSynthesis.addEventListener(
    'voiceschanged',
    () => {
      voice = speechSynthesis.getVoices()[108];
    },
    { once: true }
  );
}

export function PairHeadsetModal() {
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const modal = useModal('pair-headset');

  const [code, setCode] = useState(new Array(6).fill(''));
  const [expiration, setExpiration] = useState(EXPIRATION_DURATION);

  const codeParam = searchParams.get('code');

  useEffect(() => {
    if (!codeParam && modal.isOpen) {
      Oasis.Session.getPairingCode().then(res => {
        if (!res.ok) {
          modal.close();
          NotificationManager.push({
            status: 'error',
            content: (
              <p>
                <span className="font-bold">Pairing code</span> failed to generate. Try again.
              </p>
            ),
          });
          return;
        }

        modal.open({ code: res.value });
      });

      return;
    }

    if (codeParam) {
      clearTimeout(expirationTimeout);
      setExpiration(EXPIRATION_DURATION);

      const codeArr = codeParam.split('');
      setCode(codeArr);

      if (
        Oasis.Env.store.isDevMode &&
        Oasis.Storage.get('usePairingVoiceover') &&
        'speechSynthesis' in window
      ) {
        setTimeout(() => {
          const utterance = new SpeechSynthesisUtterance(codeArr.join(', '));
          utterance.rate = 0.7;
          if (voice) utterance.voice = voice;
          window.speechSynthesis.speak(utterance);
        }, 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeParam, modal.isOpen]);

  useEffect(() => {
    if (expiration) {
      expirationTimeout = setTimeout(() => {
        setExpiration(expiration < 300 ? 0 : expiration - 300);
      }, 300);

      return () => clearTimeout(expirationTimeout);
    }
  }, [expiration]);

  function closeModal() {
    queryClient.invalidateQueries({
      queryKey: ['@me', 'devices'],
    });

    clearTimeout(expirationTimeout);
    modal.close(['code']);
  }

  const hasVrDevice = Oasis.Storage.get('hasVrDevice') === 'true';
  const instructions = <_Instructions hasVrDevice={hasVrDevice} />;
  const codeInput = <_CodeInput expiration={expiration} code={code} setCode={setCode} />;

  return (
    // @ts-expect-error
    <Modal open={modal.isOpen} width={448} className="!h-auto">
      <Modal.Header>
        <h2>Pair a headset</h2>
        <Modal.Close showEscapeLabel={false} onClick={closeModal} />
      </Modal.Header>

      <Modal.Body className="!py-6">
        {!hasVrDevice ? instructions : codeInput}
        <hr className="border-t border-charcoal-200 w-full my-6" />
        {!hasVrDevice ? codeInput : instructions}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={closeModal}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function _CodeInput(props: { expiration: number; code: string[]; setCode: (value: string[]) => void }) {
  const [searchParams, setSearchParams] = useSearchParams();

  async function getCode(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const pairingCode = await Oasis.Session.getPairingCode();

    if (!pairingCode.ok) {
      return NotificationManager.push({
        status: 'error',
        content: 'Failed to generate pairing code',
      });
    }

    searchParams.set('code', pairingCode.value);
    setSearchParams(searchParams);
  }

  const minutes = Math.floor(props.expiration / 60_000);
  const seconds = Number(((props.expiration % 60_000) / 1000).toFixed(0));
  const formatted = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  return (
    <>
      <div>
        <Label className="mb-2">Pairing code</Label>
        <CodeInput value={props.code} onChange={value => props.setCode(value)} readOnly />
      </div>
      <div className="mt-4 space-y-2 text-body-sm">
        {props.expiration ? (
          <p>
            Code expires in <span className="font-bold">0{formatted}</span>
          </p>
        ) : (
          <p className="mt-4 text-red-600">Code expired, please generate a new code.</p>
        )}

        <LinkButton onClick={getCode} className="text-blue-500 font-medium hover:underline">
          Generate new code
        </LinkButton>
      </div>
    </>
  );
}

function _Instructions(props: { hasVrDevice: boolean }) {
  const $env = Oasis.Env.useStore();
  const branding = useTemporaryBranding();

  return (
    <div className="flex flex-col gap-4">
      {!props.hasVrDevice ? (
        <p>
          It appears that you don&apos;t have a headset paired at the moment. To enter this workshop in VR,
          please follow the steps below to pair a headset:
        </p>
      ) : (
        <p>Pair your VR headset by following these steps:</p>
      )}

      <ol className="list-decimal ml-4 space-y-1">
        <li>
          <a
            href={$env.metaStoreUrl}
            target="_blank"
            rel="noreferrer"
            className="text-blue-700 font-medium hover:underline"
          >
            Download {branding.shortProductName}
          </a>{' '}
          from the Meta Quest store.
        </li>
        <li>Launch {branding.shortProductName} on your headset.</li>
        <li>Enter the pairing code shown above.</li>
      </ol>

      {!props.hasVrDevice && (
        <p>
          Once you&apos;ve completed these steps, you will be automatically entered into the workshop you tried
          to access.
        </p>
      )}
    </div>
  );
}
