import clsx from 'clsx';

interface Props {
  level: number;
}

const ITEMS = [1, 2, 3];

export function PermissionsIndicator({ level }: Props) {
  return (
    <div className="flex items-center space-x-2">
      {ITEMS.map(i => {
        const filled = i <= level;

        return (
          <div
            key={i}
            className={clsx('relative rounded-full h-2 w-10', filled ? 'bg-blue-500' : 'bg-charcoal-100')}
          />
        );
      })}
    </div>
  );
}
