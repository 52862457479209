import { SeverityLevel } from '@sentry/react';
import { Primitive } from '@sentry/types';
import { noop } from 'lodash';
import { ZodError } from 'zod';
import { Oasis } from '../../oasis';
import { Sentry } from '../../providers/sentry/sentry.provider';
import { ReleaseChannel } from '../releases/releases.types';

enum Levels {
  trace = 0,
  debug = 1,
  info = 2,
  warn = 3,
  error = 4,
  fatal = 5,
}

const SentryLevels: Record<Levels, SeverityLevel> = {
  [Levels.trace]: 'debug',
  [Levels.debug]: 'log',
  [Levels.info]: 'info',
  [Levels.warn]: 'warning',
  [Levels.error]: 'error',
  [Levels.fatal]: 'fatal',
};

type LogFnParams =
  | {
      msg: string;
      tags?: { [key: string]: Primitive };
      [key: string]: unknown;
    }
  | string;

type LogFn = (params: LogFnParams, env?: typeof Oasis.Env.store) => void;

interface Opts {
  console?: boolean;
  sentry?: boolean;
}

export const Logger = {
  level: Levels.error,

  init(params: { releaseChannel: ReleaseChannel; sentryDsn: string | undefined }) {
    Logger.level = _getLoggingLevel(params.releaseChannel);

    const opts: Opts = {
      console: params.releaseChannel !== 'prod',
      sentry: !!params.sentryDsn,
    };

    if (params?.sentryDsn) {
      Sentry.init({
        dsn: params.sentryDsn,
        environment: params.releaseChannel || 'develop',
      });
    }

    Logger.trace = _createLogFn(Levels.trace, opts);
    Logger.debug = _createLogFn(Levels.debug, opts);
    Logger.info = _createLogFn(Levels.info, opts);
    Logger.warn = _createLogFn(Levels.warn, opts);
    Logger.error = _createLogFn(Levels.error, opts);
    Logger.fatal = _createLogFn(Levels.fatal, opts);
  },

  trace: noop as LogFn,
  debug: noop as LogFn,
  info: noop as LogFn,
  warn: noop as LogFn,
  error: noop as LogFn,
  fatal: noop as LogFn,
};

function _getLoggingLevel(channel: ReleaseChannel) {
  if (channel === 'prod') return Levels.warn;
  if (channel === 'beta') return Levels.info;
  return Levels.debug;
}

function _createLogFn(level: Levels, opts?: Opts): LogFn {
  if (level < Logger.level) {
    return () => {};
  }

  const logFns: LogFn[] = [];

  if (opts?.console) {
    let log = console.log.bind(console);
    if (level === Levels.warn) log = console.warn.bind(console);
    if (level === Levels.error) log = console.error.bind(console);

    logFns.push(function logToConsole(params: LogFnParams) {
      if (typeof params === 'string') {
        return log(params);
      }

      const { msg, ...rest } = params;
      log(msg, stringifyLogData(rest));
    });
  }

  if (opts?.sentry && level >= Levels.warn) {
    logFns.push(function logToSentry(params: LogFnParams, env) {
      if (typeof params === 'string') {
        Sentry.captureMessage(params, SentryLevels[level]);
        return;
      }

      const { msg, tags = {}, ...extra } = params;

      Sentry.captureMessage(msg, scope => {
        scope.setLevel(SentryLevels[level]);
        scope.setTags(tags);
        scope.setExtra('data', {
          extra: stringifyLogData(extra),
          env: env
            ? {
                context: env.context,
                releaseChannel: env.releaseChannel,
                gitSha: env.gitSha,
                isDevMode: env.isDevMode,
                lmvEnv: env.lmvEnv,
                online: env.online,
              }
            : 'unknown',
        });

        return scope;
      });
    });
  }

  return function callLogFns(params) {
    for (const logFn of logFns) {
      logFn(params, Oasis.Env.store);
    }
  };
}

function stringifyLogData(data: unknown): string {
  if (data instanceof Error) {
    return data.stack || data.message;
  }

  if (data instanceof ZodError) {
    return data.errors.map(e => e.message).join('\n');
  }

  if (typeof data === 'object') {
    try {
      return JSON.stringify(data, null, 2);
    } catch (error) {
      console.error('Failed to stringify log data', data, error);
    }
  }

  return String(data);
}
