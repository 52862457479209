import { ArrowLeftIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring/es/ProgressRing';
import { WorkshopXRUser } from '@oasis/fluid-interop/connection/connectionManager';
import { Oasis } from '@oasis/sdk';
import { FileUtils } from '@oasis/utils';
import { ErrorBoundary } from '@sentry/react';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useMatch, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { Button } from '~/shared/components/base/button';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { Tooltip } from '~/shared/components/base/tooltip';
import { Queries } from '~/shared/hooks/queries';
import { useWorkshopAudience } from '~/shared/hooks/use-workshop-audience';
import { LargeModelViewer } from '../../components/large-model-viewer';
import { WorkshopXRViewerState } from '../../components/large-model-viewer/useWorkshopXRViewer';
import { NotificationManager } from '~/shared/components/base/notification-manager';

// In order to show the correct "Back to X" button we set state on `location`
const stateSchema = z.object({
  from: z.union([z.literal('Workshops'), z.literal('Files'), z.literal('Issues')]),
  backUrl: z.string(),
});

export default function FilesShowPage() {
  const navigate = useNavigate();
  const loc = useLocation();
  const [searchParams] = useSearchParams();
  const params = useParams() as { projectId: string; documentId: string };
  const $env = Oasis.Env.useStore();
  const $workshopAudience = useWorkshopAudience();
  const fileMatch = useMatch('/projects/:projectId/files/:fileId');
  const $debug = Oasis.Debug.useStore();
  const showCollaborativeWebViewer = $debug.isWebViewer;
  const [showPeople, setShowPeople] = useState(false);
  const [followedUser, setFollowedUser] = useState<string | null>(null);
  const $viewerState = WorkshopXRViewerState.useStore();

  const { modelLoaded } = $viewerState;
  const viewer = $viewerState.getViewerInstance();
  const { data, isLoading } = Queries.Files.useFindDocumentById({
    projectId: params.projectId,
    documentId: params.documentId,
  });

  useEffect(() => {
    if (!searchParams.get('folder') && data?.folderId) {
      searchParams.set('folder', data.folderId);
      navigate(
        { pathname: loc.pathname, search: searchParams.toString() },
        { replace: true, state: loc.state }
      );
    }
  }, [data, loc, navigate, searchParams]);

  useEffect(
    () => {
      if (Oasis.Env.store.isVr) {
        Oasis.Segment.track('LMV Viewed in VR', params);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const backButton = useMemo(() => {
    const state = stateSchema.safeParse(loc.state);

    if (state.success) {
      return state.data;
    }

    return {
      from: 'Workshops',
      backUrl: `/projects/${params.projectId}?${searchParams.toString()}`,
    };
  }, [loc, params.projectId, searchParams]);

  const filename = fileMatch && data?.filename;
  const truncatedFilename = filename && FileUtils.truncateFilenameFromMiddle(filename, 40, 8);

  if (isLoading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <ProgressRing size="large" />
        </div>
      </div>
    );
  }

  function showAllObjects() {
    // @TODO - NOP_VIEWER could be not defined if LMV is not fully loaded, we should wait for it to be loaded
    // using React hooks for cleaner solution, then hide/show it accordingly.
    try {
      viewer?.showAll();
    } catch (error) {
      Oasis.Logger.error({ msg: 'showAll() failed for LMV.', error });
    }
  }

  if (data) {
    return (
      <div className="flex flex-col flex-1">
        {$env.isVr && (
          <div className="flex items-center min-h-12 pl-3">
            <Tooltip content={filename} placement="bottom-start" className="break-all max-w-[14rem] mt-2">
              <div className="flex items-center group w-full py-1">
                <p className="text-charcoal-900 text-heading-4">{truncatedFilename}</p>
              </div>
            </Tooltip>
            <div className="ml-auto pr-4">
              <Button variant="secondary" onClick={showAllObjects} disabled={!modelLoaded}>
                Show All Objects
              </Button>
            </div>
          </div>
        )}

        {!$env.isVr && (
          <section className="flex items-center py-2">
            <div className="w-14 flex items-center justify-center">
              <Button variant="tertiary" asChild>
                <Link to={backButton.backUrl} className="!px-1">
                  <ArrowLeftIcon />
                </Link>
              </Button>
            </div>

            {filename && (
              <>
                <div className="w-[1px] h-8 bg-charcoal-200 mr-4" />
                <Tooltip content={filename} placement="bottom-start" className="break-all max-w-[14rem] mt-2">
                  <div className="flex items-center group w-full py-1">
                    <p className="text-charcoal-900 text-heading-4">{truncatedFilename}</p>
                  </div>
                </Tooltip>
              </>
            )}

            {params.projectId && data && (
              <div className="flex space-x-2 ml-auto mr-4">
                <Button variant="secondary" onClick={showAllObjects} disabled={!modelLoaded}>
                  Show All Objects
                </Button>
                {showCollaborativeWebViewer && (
                  <Button
                    className={
                      'flex space-x-2 ml-auto mr-4 rounded-md' + (showPeople ? ' shadow bg-gray-500' : '')
                    }
                    onClick={() => {
                      setShowPeople(!showPeople);
                    }}
                  >
                    People
                  </Button>
                )}
              </div>
            )}
          </section>
        )}

        <ErrorBoundary
          beforeCapture={() => ({ ...params, ...Oasis.Env.getLoggableStore(), showCollaborativeWebViewer })}
        >
          <LargeModelViewer versionId={data.latestVersionId} />
          {showPeople && showCollaborativeWebViewer ? (
            // Need this menu to be a float menu on the right side of the page
            <div className="fixed right-4 top-40 w-72 h-96 bg-white shadow-lg rounded-md p-4 overflow-y-auto">
              <div className="flex flex-col space-y-2">
                {/* @TODO update the list as users join/leave */}
                {$workshopAudience.allUsers
                  .filter(user => user.additionalDetails.device)
                  .map((user: WorkshopXRUser) => {
                    const { userImage, device, userLastName, userName } = user.additionalDetails;
                    return (
                      <div className="flex items-center space-x-2" key={user.clientId}>
                        <img src={userImage} alt="avatar" className="w-8 h-8 rounded-full" />
                        <p className="text-charcoal-900 text-body-2">
                          {userName} {userLastName}
                        </p>
                        <p className="text-charcoal-500 text-body-3">({device})</p>
                        {viewer && (
                          <div className="flex ml-auto">
                            <Button
                              onClick={() => {
                                if (followedUser === user.deviceId || followedUser !== null) {
                                  setFollowedUser(null);
                                } else {
                                  setFollowedUser(user.deviceId!);
                                }

                                const ext = viewer.getExtension('Autodesk.ConcurrentCollaboration') as any;
                                if (!ext) {
                                  NotificationManager.push({
                                    content: 'Collaboration extension not found',
                                    status: 'error',
                                  });
                                  return;
                                }

                                if (followedUser === user.deviceId) {
                                  setFollowedUser(null);
                                  ext.startFollow(null);
                                } else {
                                  ext.startFollow(user.deviceId);
                                  setFollowedUser(user.deviceId!);
                                }
                              }}
                            >
                              {followedUser === user.clientId ? 'Unfollow' : 'Follow'}
                            </Button>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : null}
        </ErrorBoundary>
      </div>
    );
  }

  return <OasisErrorState code="NOT_FOUND" />;
}
