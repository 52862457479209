import { CrownFilledIcon, CrownStrikethroughIcon, TrashCanIcon, XIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import { Oasis, UpdateIssueParams } from '@oasis/sdk';
import { DateUtils } from '@oasis/utils';
import { clsx } from 'clsx';
import { memo } from 'react';
import { Button } from '~/shared/components/base/button';
import { Confirmation } from '~/shared/components/base/confirmation';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { OasisErrorBoundary } from '~/shared/components/base/oasis-error-boundary';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { Tooltip } from '~/shared/components/base/tooltip';
import { HeadsetIcon } from '~/shared/components/icons/headset-icon';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Mutations } from '~/shared/hooks/mutations';
import { Queries } from '~/shared/hooks/queries';
import { UpdateIssueForm } from './update-issue-form';

interface Props {
  issueId: string;
  onClose?: () => void;
}

export const IssuesDetailPanel = memo(function _IssueDetailPanel(props: Props) {
  const $env = Oasis.Env.useStore();
  const { projectId, platform } = useProjectContext();

  const issueQuery = Queries.Issues.useFindIssueById({
    issueId: props.issueId,
    projectId,
    platform,
  });
  const projectUsersQuery = Queries.Projects.useListProjectUsers({ projectId });

  const updateIssue = Mutations.Issues.useUpdateIssue();
  const togglePublishedOnIssue = Mutations.Issues.useTogglePublishedIssue();
  const deleteIssue = Mutations.Issues.useDeleteIssue();

  const closePanel = props.onClose;
  async function handleOpenIssue() {
    const openIssue = await Oasis.NetworkCommands.openIssueOnDevice({
      device: Oasis.Env.store.vrTargetDevice,
      projectId,
      issueId: props.issueId,
    });

    NotificationManager.push({
      status: openIssue.ok ? 'success' : 'error',
      content: openIssue.ok ? 'Opening issue on headset...' : 'Error attempting to open issue on headset',
    });
  }

  async function handleUpdateIssue(attrs: Partial<UpdateIssueParams['attrs']>) {
    updateIssue.mutate({
      issueId: props.issueId,
      projectId,
      platform,
      attrs,
    });
  }

  async function handleTogglePublishedIssue(published: boolean) {
    let ok = true;

    if (!published) {
      ok = await Confirmation.show({
        title: 'Unpublish issue?',
        description:
          'If you unpublish this issue, it will no longer be visible to you or other members on this project. The issue’s creator and assignee will still be able to see it on the issues log and its sheet or model.',
        confirmLabel: 'Unpublish',
      });
    }

    if (ok) {
      togglePublishedOnIssue.mutate({
        issueId: props.issueId,
        projectId,
        platform,
        published,
      });
    }
  }

  async function handleDeleteIssue() {
    Confirmation.show({
      title: 'Delete issue?',
      description:
        'This issue has references associated with it. Deleting the issue will remove those references, and it will also be removed from the log and sheet or model.',
      confirmLabel: 'Delete issue',
      confirm() {
        deleteIssue.mutate({
          projectId,
          issueId: props.issueId,
        });
      },
    });
  }

  if (issueQuery.isPending && !issueQuery.data) {
    return (
      <>
        <header className="flex items-center pl-4 pt-2 pb-2">
          <button className="p-2 appearance-none opacity-50 hover:opacity-100 ml-auto" onClick={closePanel}>
            <XIcon />
          </button>
        </header>
        <div className="w-full h-full flex flex-col items-center justify-center">
          <ProgressRing size="medium" />
        </div>
      </>
    );
  }

  if (issueQuery.data) {
    const canPublish =
      issueQuery.data.permittedAttributes && issueQuery.data.permittedAttributes.includes('published');
    const canDelete = issueQuery.data.permittedActions && issueQuery.data.permittedActions.includes('delete');
    const creator = projectUsersQuery.data?.results.find(user => user.autodeskId === issueQuery.data.createdBy);

    return (
      <OasisErrorBoundary>
        <section className="h-full overflow-hidden flex flex-col">
          <header className="flex items-center pl-4 pt-2 pb-2">
            <h2 className="text-heading-2 mr-10 flex-1 truncate">Issue #{issueQuery.data.displayId}</h2>

            <div className="flex items-center ml-auto space-x-1 pr-2">
              {(issueQuery.isFetching || updateIssue.isPending) && (
                <ProgressRing size="xsmall" className="ml-2" />
              )}
              <button className="p-2 appearance-none opacity-50 hover:opacity-100" onClick={closePanel}>
                <XIcon />
              </button>
            </div>
          </header>

          <div className="border-t border-charcoal-200 mb-2" />

          {!issueQuery.data.deleted && (
            <>
              <div
                className={clsx(
                  'flex items-center mx-4',
                  platform === 'acc' && 'border-b border-charcoal-200 pb-2'
                )}
              >
                {$env.isVr && Boolean(issueQuery.data.linkedDocuments[0]) && (
                  <Button variant="primary" onClick={handleOpenIssue} className="mr-auto">
                    <HeadsetIcon className="-ml-1 mr-2 w-6 h-6" /> Go To Issue
                  </Button>
                )}

                {platform === 'acc' && (
                  <>
                    <Tooltip
                      content={
                        !canPublish &&
                        `You dont have permission ${issueQuery.data.published ? 'unpublish' : 'publish'} this issue`
                      }
                    >
                      <div>
                        <Button
                          variant="tertiary"
                          onClick={() => handleTogglePublishedIssue(!issueQuery.data.published)}
                          disabled={!canPublish}
                        >
                          {issueQuery.data.published ? (
                            <>
                              <CrownStrikethroughIcon className="-ml-1 mr-2" /> Unpublish
                            </>
                          ) : (
                            <>
                              <CrownFilledIcon className="-ml-1 mr-2" /> Publish
                            </>
                          )}
                        </Button>
                      </div>
                    </Tooltip>

                    <div className="w-[1px] h-6 bg-charcoal-200" />

                    <Tooltip content={!canDelete && 'You dont have permission to delete this issue'}>
                      <Button
                        variant="tertiary-destructive"
                        onClick={handleDeleteIssue}
                        isLoading={deleteIssue.isPending}
                        disabled={!canDelete}
                      >
                        <TrashCanIcon className="-ml-1 mr-2" /> Delete
                      </Button>
                    </Tooltip>
                  </>
                )}
              </div>

              <div className="py-6 flex-1 overflow-auto">
                <UpdateIssueForm issue={issueQuery.data} updateIssue={handleUpdateIssue} />
              </div>

              {creator && (
                <footer className="mx-5 py-3 mt-auto border-t border-charcoal-100 text-charcoal-500">
                  <p>
                    Created by {creator.name} on {DateUtils.getFriendlyFormat(issueQuery.data.createdAt)}
                  </p>
                  <p>{creator.email}</p>
                </footer>
              )}
            </>
          )}

          {issueQuery.data.deleted && (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <p className="text-heading-2">This issue has been deleted.</p>
              <Button className="mt-4" onClick={closePanel}>
                Close
              </Button>
            </div>
          )}
        </section>
      </OasisErrorBoundary>
    );
  }

  return (
    <>
      <header className="flex items-center pl-4 pt-2 pb-2">
        <div className="flex items-center ml-auto space-x-1 pr-2">
          {issueQuery.isFetching && <ProgressRing size="xsmall" className="ml-2" />}
          <button className="p-2 appearance-none opacity-50 hover:opacity-100" onClick={closePanel}>
            <XIcon />
          </button>
        </div>
      </header>

      <OasisErrorState oasisError={issueQuery.error} />
    </>
  );
});
