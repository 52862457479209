import { Oasis, OasisSchemas, SubscriptionHandler, SubscriptionPayloads } from '@oasis/sdk';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { AppHistory } from '~/shared/utils/app-history';
import { useFeatureFlags } from './use-feature-flags';
import { useWorkshopSignal } from './use-workshop-state';
import { navigateToWorkshopModelTab } from './helpers/navigateToWorkshopModelTab';

export const Subscriptions = {
  useCommands() {
    const $mqtt = Oasis.Mqtt.useStore();
    const $session = Oasis.Session.useStore();
    const $env = Oasis.Env.useStore();
    const isReceivingCommandsLocally = useFeatureFlags('040924-6783-send-console-commands-locally');
    const useLMVForProperties = useFeatureFlags('250222-8163-use-lmv-for-properties') && $env.isVr;
    const { submitSignal } = useWorkshopSignal('workshop:issueOpened', { listen: false });
    const { projectId } = useParams<{ projectId: string }>();

    const handler: SubscriptionHandler = useCallback(
      async (payload, topic) => {
        const parsed = Oasis.Mqtt.parse(payload);

        if (!parsed.ok) {
          Oasis.Logger.warn({ msg: '[Subscriptions.useCommands] Failed to parse payload', payload });
          return;
        }

        const data = parsed.value;

        // If its a console only payload but not VR we can ignore it.
        // Likewise, if it's a payload without a command `type` ignore it.
        const nonApplicableVrPayload = $env.isVr && !topic.includes('console');
        const invalidPayload = !('type' in data);

        if (nonApplicableVrPayload || invalidPayload) return;

        switch (data.type) {
          case 'PairedDevice':
            NotificationManager.push({
              type: 'banner',
              status: 'success',
              content: (
                <p>
                  <span className="font-bold">Headset</span> successfully paired.
                </p>
              ),
            });

            AppHistory.searchParams.delete('dialog');
            AppHistory.setSearchParams(AppHistory.searchParams);
            break;

          case 'JoinedWorkshop': {
            if (useLMVForProperties) {
              const workshopId = data.args.id;
              await Oasis.Session.setActiveWorkshop(workshopId);
              if (projectId) {
                await navigateToWorkshopModelTab(workshopId, projectId);
              }
            }
            break;
          }
          case 'WorkshopModelChanged': {
            if (useLMVForProperties) {
              const workshopId = data.args.id;
              const modelUrn = data.args.newModelURN;
              if (projectId) {
                await navigateToWorkshopModelTab(workshopId, projectId, modelUrn);
              }
            }
            break;
          }
          case 'LeftWorkshop':
            if (Oasis.Session.store.activeWorkshop?.id === data.args.id) {
              Oasis.Session.clearActiveWorkshop();
              (window as any).LMVCPPBridge = undefined;
            }
            break;

          case 'Navigate':
            const [_, querystring] = data.args.path.split('?');
            const navigateParams = new URLSearchParams(querystring);
            const newContext = OasisSchemas.EnvContext.safeParse(navigateParams.get('context'));
            const newVrTargetDevice = OasisSchemas.Device.safeParse(navigateParams.get('vr-target'));

            const url = new URL(window.location.href);
            const urlPath = url.pathname + url.search;

            if (isReceivingCommandsLocally) {
              if (urlPath.indexOf(data.args.path) !== -1) {
                console.info('Ignoring navigate command as the path is already the same');
                break;
              }
            }
            if (newContext.success) {
              Oasis.Env.setContext(newContext.data);
            }

            if (newVrTargetDevice.success) {
              Oasis.Env.setVrTargetDevice(newVrTargetDevice.data);
            }

            AppHistory.navigate(data.args.path, {
              state: {
                fromNavigateCommand: true,
                path: data.args.path,
              },
            });
            break;

          case 'OpenIssue':
            AppHistory.navigate(`/projects/${data.args.projectId}/issues?issueId=${data.args.id}`);
            const res = await Oasis.NetworkCommands.emitNavigationProcessed({
              path: `${data.args.projectId}/${data.args.id}`,
            });

            if (!res.ok) {
              NotificationManager.push({
                status: 'error',
                content: 'Failed to send navigation command. Try logging back in.',
              });
            } else {
              submitSignal(data.args.id);
            }

            break;

          case 'ForwardToPendo':
            try {
              JSON.parse(JSON.stringify(data.args.data));
              Oasis.Pendo.track(data.args.label, data.args.data, true);
              Oasis.Pendo.flush();
            } catch (error) {
              Oasis.Logger.error({
                msg: '[Subscriptions.useCommands] Attempted to forward invalid data to Pendo.',
              });
            }
            break;

          case 'ForwardToSegment':
            try {
              JSON.parse(JSON.stringify(data.args.data));
              Oasis.Segment.track(data.args.label, data.args.data);
            } catch (error) {
              Oasis.Logger.error({
                msg: '[Subscriptions.useCommands] Attempted to forward invalid data to Segment.',
              });
            }
            break;

          case 'SetActiveTool':
            Oasis.Session.setActiveVrTool(data.args.name);
            break;
        }
      },
      [$env.isVr, isReceivingCommandsLocally, submitSignal, useLMVForProperties, projectId]
    );

    useEffect(() => {
      if (isReceivingCommandsLocally) {
        const listener = (event: CustomEvent<SubscriptionPayloads>) => {
          const payload = event.detail;
          Oasis.Logger.info({ payload, msg: '[Subscriptions.useCommands] Received client command locally' });
          handler(payload, 'console');
        };

        window.addEventListener<any>('clientCommands', listener);

        return () => {
          window.removeEventListener<any>('clientCommands', listener);
        };
      }
    }, [handler, isReceivingCommandsLocally]);

    useEffect(() => {
      const userId = $session.user?.id || Oasis.Storage.get('currentUserId');

      if (!userId || $mqtt.status !== 'CONNECTED') {
        return;
      }

      const activeContexts = $env.isVr ? ['console'] : ['web'];
      const subPromises: ReturnType<typeof Oasis.Mqtt.subscribe>[] = [];
      activeContexts.forEach(context => {
        const topic = `wsq/v1/cmd/${userId}/${context}`;
        const subPromise = Oasis.Mqtt.subscribe(topic, handler);

        subPromise
          .then(sub => {
            if (!sub.ok) {
              NotificationManager.push({
                type: 'toast',
                status: 'error',
                content: 'We were unable to process this request. Please refresh and try again.',
              });
            }
          })
          .catch(() => {
            NotificationManager.push({
              type: 'toast',
              status: 'error',
              content: 'We were unable to process this request. Please refresh and try again.',
            });
          });

        subPromises.push(subPromise);
      });

      return () => {
        Promise.all(subPromises).then(res => {
          res.forEach(sub => {
            if (sub.ok) {
              sub.value.removeHandler();
            }
          });
        });
      };
    }, [$session.user?.id, $env.isVr, $mqtt.status, handler]);
  },

  useIssueEvents(projectId: string) {
    const $session = Oasis.Session.useStore();
    const queryClient = useQueryClient();

    useEffect(() => {
      const workshopId = $session.activeWorkshop?.id;

      if (!workshopId) {
        return;
      }

      function invalidate() {
        queryClient.invalidateQueries({
          queryKey: ['projects', projectId, 'issues'],
        });
      }

      const unsubFns: (() => void)[] = [];
      const subPromises: ReturnType<typeof Oasis.Mqtt.subscribe>[] = [];
      ['add', 'update', 'del'].forEach(action => {
        const subject = action + 'i';

        const subPromise = Oasis.Mqtt.subscribe(`wsq/v1/ws1/${workshopId}/${subject}`, (payload: unknown) => {
          Oasis.Logger.info({ payload, msg: `[Subscriptions.useIssueEvents#${subject}]` });
          invalidate();
        });

        subPromise
          .then(sub => {
            if (!sub.ok) {
              NotificationManager.push({
                type: 'toast',
                status: 'error',
                content: 'We were unable to process this request. Please refresh and try again.',
              });
            } else {
              unsubFns.push(sub.value.removeHandler);
            }
          })
          .catch(() => {
            NotificationManager.push({
              type: 'toast',
              status: 'error',
              content: 'We were unable to process this request. Please refresh and try again.',
            });
          });

        subPromises.push(subPromise);
      });
      return () => {
        Promise.all(subPromises).then(res => {
          res.forEach(sub => {
            if (sub.ok) {
              sub.value.removeHandler();
            }
          });
        });
      };
    }, [$session.activeWorkshop?.id, projectId, queryClient]);
  },

  useVoiceLevels(workshopId: string) {
    useEffect(() => {
      let unsub = () => { };

      (async () => {
        const res = await Oasis.Mqtt.subscribe(`wsq/v1/ws0/${workshopId}/mupdbj`, payload => {
          const parsed = Oasis.Mqtt.parseWorkshopEvent(payload);

          if (!parsed.ok) {
            Oasis.Logger.warn({
              error: parsed.error,
              msg: '[Subscriptions.useVoiceLevels] Failed to parse payload',
            });
            return;
          }

          const data = parsed.value;

          if ('AV' in data && typeof data.AV?.volume === 'number') {
            Oasis.Voice.determineActiveSpeaker({
              deviceId: data.device_id,
              volumeLevel: data.AV.volume,
            });
          }
        });

        if (!res.ok) {
          return NotificationManager.push({
            type: 'toast',
            status: 'error',
            content: 'Unable to subscribe to active speaker levels. Voice may not work as expected.',
          });
        }

        unsub = res.value.unsubscribe;
      })();

      return () => {
        unsub();
      };
    }, [workshopId]);
  },
};
