import { ChevronDownIcon, ChevronUpIcon, XIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { useCombobox, useMultipleSelection } from 'downshift';
import { ReactNode, useMemo, useState } from 'react';
import { twvg } from 'twvg';

interface Props<T extends Record<string, unknown>> {
  items: T[];
  selectedItems: T[];
  disabled?: boolean;
  onChange(value: T[]): void;
  getFilteredItems(query: string): T[];
  renderEmptySelection(): ReactNode;
  renderSelectedItem(params: { item: T; index: number }): ReactNode;
  renderItem(params: { item: T; index: number; removeItem(): void }): ReactNode;
}

export function MultiComboBox<T extends Record<string, unknown>>({
  selectedItems,
  getFilteredItems,
  items,
  ...props
}: Props<T>) {
  const [query, setQuery] = useState('');

  const filteredItems = useMemo(
    () => (query || selectedItems.length ? getFilteredItems(query) : items),
    [query, selectedItems, getFilteredItems, items]
  );

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } = useMultipleSelection({
    selectedItems,
    onStateChange(next) {
      switch (next.type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          props.onChange(next.selectedItems || []);
          break;
        default:
          break;
      }
    },
  });

  const { isOpen, getToggleButtonProps, getMenuProps, getInputProps, highlightedIndex, getItemProps } =
    useCombobox({
      items: filteredItems,
      defaultHighlightedIndex: 0, // after selection, highlight the first item.
      selectedItem: null,
      inputValue: query,
      stateReducer(state, actionAndChanges) {
        const { changes, type } = actionAndChanges;

        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true, // keep the menu open after selection.
              highlightedIndex: 0, // with the first option highlighted.
            };
          default:
            return changes;
        }
      },
      onStateChange(next) {
        switch (next.type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
          case useCombobox.stateChangeTypes.InputBlur:
            if (next.selectedItem) {
              if (!selectedItems.includes(next.selectedItem)) {
                props.onChange([...selectedItems, next.selectedItem]);
                setQuery('');
              }
            }
            break;
          case useCombobox.stateChangeTypes.InputChange:
            setQuery(next.inputValue || '');
            break;
          default:
            break;
        }
      },
    });

  return (
    <div className="relative">
      <div
        className={clsx(
          'flex flex-col gap-2 border rounded-sm outline-0 border-charcoal-400 p-2 pr-8 cursor-pointer min-h-[2.38rem]',
          'hover:border-blue-500',
          twvg('focus-within', 'border-blue-500 shadow-blue-100'),
          selectedItems.length > 0 && 'pt-[0.30rem] pb-[0.30rem]'
        )}
        {...getToggleButtonProps()}
      >
        <div className="shadow-sm bg-white inline-flex gap-2 items-center flex-wrap">
          {selectedItems.length === 0 && !isOpen && (
            <div className="flex items-center justify-center">{props.renderEmptySelection()}</div>
          )}

          {selectedItems.map((item, index) => {
            return (
              <div
                key={`selected-item-${index}`}
                className="group inline-flex items-center pl-1 pr-2 bg-charcoal-100 rounded h-6"
                {...getSelectedItemProps({ selectedItem: item, index })}
              >
                {props.renderSelectedItem({ item, index })}

                {!props.disabled && (
                  <button
                    type="button"
                    onClick={e => {
                      e.stopPropagation();
                      removeSelectedItem(item);
                    }}
                  >
                    <XIcon size={16} className="ml-1 opacity-50 hover:opacity-100" />
                  </button>
                )}
              </div>
            );
          })}

          <input
            placeholder={selectedItems.length === 0 ? 'Search...' : ''}
            disabled={props.disabled}
            className={clsx('w-28 focus:outline-none', !isOpen && 'hidden')}
            {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
          />

          {!props.disabled && !isOpen && (
            <div className="w-10 h-8 absolute top-1/2 -translate-y-1/2 right-0 flex items-center justify-center">
              <ChevronDownIcon
                size={16}
                className={clsx('text-charcoal-900 hover:opacity-100', isOpen && 'rotate-180')}
              />
            </div>
          )}

          {!props.disabled && isOpen && (
            <div className="w-10 h-8 absolute right-0 flex items-center justify-center">
              <ChevronUpIcon size={16} className={clsx('text-charcoal-900 hover:opacity-100')} />
            </div>
          )}
        </div>
      </div>

      <ul
        className={clsx(
          'absolute bg-white rounded-md shadow-high relative z-10 overflow-auto max-h-[220px] w-full',
          !isOpen && 'hidden'
        )}
        {...getMenuProps()}
      >
        {filteredItems.length === 0 && (
          <li key={0} className="flex items-center justify-center h-[3.25rem] text-charcoal-700 text-body-sm">
            <p>No results found</p>
          </li>
        )}

        {isOpen &&
          filteredItems.map((item, index) => (
            <li
              key={`${item.value}${index}`}
              className={clsx(
                'p-2 cursor-pointer',
                twvg('hover', 'bg-charcoal-50 outline-none border-transparent shadow-transparent'),
                highlightedIndex === index && 'bg-charcoal-50'
              )}
              {...getItemProps({ item, index })}
            >
              {props.renderItem({
                item,
                index,
                removeItem: () => removeSelectedItem(item),
              })}
            </li>
          ))}
      </ul>
    </div>
  );
}
