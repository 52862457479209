import { AlertErrorIcon, CheckmarkCircleFilledIcon, PauseIcon, XIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring/es/ProgressRing';
import { FileUploadState } from '@oasis/sdk';
import { FileUtils } from '@oasis/utils';
import clsx from 'clsx';
import { Key, ReactNode, useMemo } from 'react';
import { QueueItemWithFile, UploadManager } from '..';
import { FileTypeIcon } from '../../../../../shared/components/base/file-type-icon';

interface Props extends QueueItemWithFile {
  small?: boolean;
  key?: Key;
}

export function QueueListItem(props: Props) {
  const $uploads = UploadManager.useStore();

  const statusContent = useMemo(() => {
    const options: Record<FileUploadState['status'], ReactNode> = {
      ERROR: (
        <div className="flex items-center">
          <AlertErrorIcon className="text-red-500 mr-2" size={16} />{' '}
          {props.message || 'Something went wrong. Please try again.'}
        </div>
      ),
      QUEUED: (
        <div className="flex items-center">
          <PauseIcon className="text-charcoal-300 mr-2" size={16} /> Queued
        </div>
      ),
      INITIALIZING: (
        <div className="flex items-center">
          <PauseIcon className="text-green-500 mr-2" size={16} /> Initializing
        </div>
      ),
      UPLOADING: (
        <div className="relative h-1 w-full bg-charcoal-50 rounded overflow-hidden">
          <div
            role="progressbar"
            aria-label={`${props.file.name} upload progress`}
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={props.progress}
            className="absolute top-0 bottom-0 left-0 bg-blue-500 w-full transition-transform duration-100 origin-left"
            style={{ transform: `scaleX(${props.progress / 100})` }}
          />
        </div>
      ),
      PREPROCESSING: (
        <div className="flex items-center">
          <ProgressRing size="xsmall" className="mr-2" /> Preprocessing
        </div>
      ),
      PROCESSING: (
        <div className="flex items-center">
          <ProgressRing size="xsmall" className="mr-2" /> Processing
        </div>
      ),
      DONE: (
        <div className="flex items-center">
          <CheckmarkCircleFilledIcon className="text-green-500 mr-2" size={16} /> Successfully uploaded
        </div>
      ),
    };

    if (props.status === 'DONE' && props.isProcessing) {
      return options.PROCESSING;
    }

    return options[props.status];
  }, [props.message, props.file.name, props.isProcessing, props.progress, props.status]);

  if (props.isCancelled) {
    return null;
  }

  return (
    <li
      className={clsx(
        'flex items-center border-b border-charcoal-100 last:border-transparent',
        props.small ? 'py-2 pl-2' : 'py-4 pl-4'
      )}
    >
      <div className="mr-5">
        <FileTypeIcon
          contentType="items"
          fileType={FileUtils.getFileType({ name: props.file.name, type: 'items' })}
        />
      </div>

      <div className="flex-1 overflow-hidden">
        <p className="flex items-center justify-between">
          <span className="mr-2 flex-1 w-full truncate">{props.file.name}</span>
          <span className="text-charcoal-500 min-w-[5rem] tabular-nums">
            {FileUtils.toHumanFileSize(props.file.size)}
          </span>
        </p>

        <div className="flex items-center w-full min-h-[2.25rem]">{statusContent}</div>
      </div>

      <div className="ml-2 w-12 text-center">
        {props.status !== 'DONE' ? (
          <button onClick={props.cancel} className="p-4 text-charcoal-600 hover:text-blue-500">
            <XIcon />
          </button>
        ) : (
          <button
            onClick={() => {
              $uploads.state = 'HIDDEN';
            }}
            className="py-2 text-charcoal-600 hover:text-blue-500"
          >
            View
          </button>
        )}
      </div>
    </li>
  );
}
