import Button, { LinkButton } from '@adsk/alloy-react-button';
import { Oasis } from '@oasis/sdk';
import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Prerequisites } from '~/features/auth/components/prerequisites';
import { Queries } from '~/shared/hooks/queries';
import { Subscriptions } from '~/shared/hooks/subscriptions';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import { useWorkshopState } from '~/shared/hooks/use-workshop-state';
import { landingUrl } from '~/shared/utils/const.asset-urls';
import { MARKETING_URLS } from '~/shared/utils/const.marketing';
import { Header } from './components/header';
import { OnboardingModal } from './components/onboarding-modal';
import { PairHeadsetModal } from './components/pair-headset-modal';
import { SharedToWorkshopModal } from './components/shared-to-workshop-modal';

interface Props {
  children?: ReactNode;
}

export default function DashboardLayout({ children }: Props) {
  const $session = Oasis.Session.useStore();
  const $env = Oasis.Env.useStore();
  const branding = useTemporaryBranding();

  const showTrialMessage =
    $session.license.type === 'NO_ENTITLEMENT' ||
    ($session.license.type === 'FREE_VIEWER' && $session.license.trialEligible);

  if ($session.status === 'AUTHENTICATED' && showTrialMessage) {
    return (
      <main
        className="min-w-screen min-h-screen flex items-center justify-center bg-cover"
        style={{ backgroundImage: `url(${landingUrl})` }}
      >
        <section className="w-full max-w-[26.25rem] bg-white shadow-high rounded-md py-10 px-7">
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-heading-2 mb-4 text-center">
              You have not been granted access to <br />
              {branding.productName}.
            </h1>

            <p className="text-body-md text-center mb-4">
              You can start a free trial to get access to {branding.productName}.
            </p>

            <Button variant="primary" as="a" href={MARKETING_URLS.INTAKE} target="_blank">
              Start free trial
            </Button>

            <div className="mt-8 mb-8">
              <p className="text-body-md text-center">
                Or, connect with our sales team if you need assistance.
              </p>
              <p className="text-center mt-2">
                <a
                  href={MARKETING_URLS.CONTACT_US}
                  className="inline-flex items-center text-blue-700 hover:text-blue-500"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact sales
                </a>
              </p>
            </div>

            <div className="flex justify-center">
              <img src={branding.logoUrl} alt={branding.productName + ' logo'} className="h-[2.125rem]" />
            </div>
          </div>
        </section>

        <LinkButton onClick={() => Oasis.Session.logout()} size="small" className="absolute top-5 right-5">
          Logout
        </LinkButton>
      </main>
    );
  }

  if ($session.missingPrerequisites.length > 0) {
    return <Prerequisites />;
  }

  return (
    <>
      <_DashboardListeners />
      {$env.isVr && <_VrOnlyDashboardListeners />}

      <Header />
      {children}
      <Outlet />

      <OnboardingModal />
      <PairHeadsetModal />
      <SharedToWorkshopModal />
    </>
  );
}

function _VrOnlyDashboardListeners() {
  // We connect always to the workshop state in VR mode
  useWorkshopState({ connectFluidBasedOnContext: true });
  return null;
}

function _DashboardListeners() {
  Queries.Users.useListUsersDevices(); // preload user devices for pairing modal
  Subscriptions.useCommands(); // listen for notifications
  return null;
}
