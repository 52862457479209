import FormField from '@adsk/alloy-react-form-field';
import Textarea from '@adsk/alloy-react-textarea';
import { Oasis } from '@oasis/sdk';
import { CryptoUtils } from '@oasis/utils';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '~/shared/components/base/button';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import { landingUrl } from '~/shared/utils/const.asset-urls';

/**
 * Instruct whoever does the email to send `?r=X` where X is 1, 2 or 3.
 * 1 is bad, 3 is good.
 */

const RESPONSE_TEXT = {
  1: 'Not disappointed if they could no longer use Workshop XR',
  2: 'Somewhat disappointed if they could no longer use Workshop XR',
  3: 'Disappointed if they could no longer use Workshop XR',
};

const USER_RESPONSE_TEXT = {
  1: 'You would not be disappointed if you could no longer use Workshop XR',
  2: 'You would be somewhat disappointed if you could no longer use Workshop XR',
  3: 'You would be disappointed if you could no longer use Workshop XR',
};

export default function FeedbackPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = useRef(CryptoUtils.randomUUID()).current;
  const response = searchParams.get('r') as '1' | '2' | '3' | null;
  const analyticsId = searchParams.get('aid');
  const branding = useTemporaryBranding();

  const sentInitialResponse = useRef(false);
  const [who, setWho] = useState('');
  const [what, setWhat] = useState('');
  const [how, setHow] = useState('');
  const [thanks, setThanks] = useState(false);

  useEffect(() => {
    if (sentInitialResponse.current) {
      return;
    }

    if (!response || !['1', '2', '3'].includes(response)) {
      return navigate('/');
    }

    if (analyticsId) {
      Oasis.Pendo.identify({ visitor: { id: analyticsId } });
    }

    Oasis.Pendo.track('PMF Feedback', { id, response: RESPONSE_TEXT[response] });
    Oasis.Pendo.flush();
    sentInitialResponse.current = true;
  }, [analyticsId, navigate, response, id]);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    Oasis.Pendo.track('PMF Feedback', { id, who });
    Oasis.Pendo.track('PMF Feedback', { id, what });
    Oasis.Pendo.track('PMF Feedback', { id, how });
    await Oasis.Pendo.flush();
    setThanks(true);
  }

  const questions = [
    ['What type of people do you think would most benefit from Autodesk Workshop XR?', who, setWho],
    ['What is the main benefit you receive from Workshop XR?', what, setWhat],
    ['How can we improve Workshop XR for you?', how, setHow],
  ] as const;

  return (
    <main
      className="min-w-screen min-h-screen flex bg-cover overflow-auto py-10"
      style={{ backgroundImage: `url(${landingUrl})` }}
    >
      <section className="w-full max-w-md bg-white shadow-high rounded-md p-10 m-auto">
        {thanks ? (
          <>
            <h1 className="text-center text-heading-2">Thank you!</h1>
            <p className="text-center text-body-md mt-4 mb-6">
              Your thoughtful responses will help our product team to make Autodesk Workshop XR the best place
              for your team to collaborate.
            </p>
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <h1 className="text-center text-heading-2">
              {response === '1' && 'We appreciate your honesty.'}
              {response === '2' && 'Thank you for your response.'}
              {response === '3' && "We're happy to hear you're finding value in Workshop XR"}
            </h1>

            {response && (
              <>
                <p className="text-center text-body-md mt-4">
                  Please help us understand why you selected this&nbsp;answer:{' '}
                  <span className="font-semibold">{USER_RESPONSE_TEXT[response]}</span>
                </p>
              </>
            )}

            {questions.map(([label, value, setValue]) => (
              <FormField key={label} label={label} labelVariant="top" className="mt-6">
                <Textarea
                  id="who"
                  value={value}
                  onChange={e => setValue(e.currentTarget.value)}
                  minHeight={78}
                  className="w-full"
                  maxLength={360}
                />
              </FormField>
            ))}

            <Button type="submit" className="w-full mt-6">
              Submit feedback
            </Button>
          </form>
        )}

        <div className="flex justify-center mt-6">
          <img src={branding.logoUrl} alt={branding.productName + ' logo'} className="h-[2.125rem]" />
        </div>
      </section>
    </main>
  );
}
