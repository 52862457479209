import ProgressRing from '@adsk/alloy-react-progress-ring';
import {
  Arrow,
  Content,
  PopoverContentProps,
  PopoverProps,
  PopoverTriggerProps,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-popover';
import clsx from 'clsx';
import { ComponentProps, ReactNode } from 'react';

interface Props extends PopoverProps {
  children: ReactNode;
}

export function Popover({ children, ...props }: Props) {
  return <Root {...props}>{children}</Root>;
}

Popover.Trigger = function PopoverTrigger({ children, ...props }: PopoverTriggerProps) {
  return (
    <Trigger {...props} className={clsx('relative text-left', props.className)}>
      {children}
    </Trigger>
  );
};

Popover.Content = function PopoverContent({
  children,
  hideArrow,
  ...props
}: PopoverContentProps & { hideArrow?: boolean }) {
  return (
    <Portal>
      <Content {...props} className={clsx('z-20', props.className)} asChild>
        <div className="bg-white min-w-[15rem] rounded-md shadow-high">
          <div className="block w-full">{children}</div>

          {!hideArrow && (
            <>
              <Arrow width={18} height={9} className="opacity-10" />
              <Arrow width={16} height={8} className="fill-white -translate-x-[1px]" />
            </>
          )}
        </div>
      </Content>
    </Portal>
  );
};

interface ButtonProps extends ComponentProps<'button'> {
  destructive?: boolean;
  isLoading?: boolean;
  icon?: ReactNode;
  hideIcon?: boolean;
}

Popover.Button = function PopoverButton({
  children,
  destructive,
  isLoading,
  icon,
  hideIcon,
  ...props
}: ButtonProps) {
  return (
    <button
      {...props}
      disabled={props.disabled || isLoading}
      className={clsx(
        'relative w-full flex items-center px-3 h-10 cursor-pointer outline-0 hover:bg-charcoal-50 focus-visible:bg-charcoal-50',
        destructive && 'text-red-600',
        props.className
      )}
    >
      {!hideIcon && (
        <span className="flex items-center justify-center w-6 h-6 mr-2">
          {isLoading ? <ProgressRing size="xsmall" /> : icon}
        </span>
      )}

      {children}
    </button>
  );
};
