import Button from '@adsk/alloy-react-button/es/Button';
import Modal from '@adsk/alloy-react-modal/es/Modal';
import { Oasis } from '@oasis/sdk';
import { type MouseEvent } from 'react';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { useModal } from '~/shared/hooks/use-modal';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import { workshopOnboardingUrl } from '~/shared/utils/const.asset-urls';

export function OnboardingModal() {
  const $env = Oasis.Env.useStore();
  const branding = useTemporaryBranding();
  const modal = useModal('onboarding');
  const pairingModal = useModal('pair-headset');

  async function getPairingCode(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    const pairingCode = await Oasis.Session.getPairingCode();

    if (!pairingCode.ok) {
      NotificationManager.push({
        status: 'error',
        content: (
          <p>
            <span className="font-bold">Pairing code</span> failed to generate. Try again.
          </p>
        ),
      });
      return;
    }

    pairingModal.open({ code: pairingCode.value });
  }

  if ($env.isDesktop) {
    return (
      // @ts-expect-error
      <Modal open={modal.isOpen} width={560} className="!h-auto">
        <Modal.Header>
          <h2 className="truncate">Welcome to {branding.productName}</h2>
          <Modal.Close showEscapeLabel={false} onClick={() => modal.close()} />
        </Modal.Header>

        <Modal.Body className="!py-6 text-body-sm space-y-3">
          <p>We recommend viewing your models in virtual reality for the most immersive experience.</p>
          <p>{branding.productName + ' currently runs on Meta Quest headsets.'}</p>
        </Modal.Body>

        <Modal.Footer className="space-x-1">
          <Button variant="tertiary" onClick={() => modal.close()}>
            Skip
          </Button>
          <Button as="a" href="/pair" variant="primary" onClick={getPairingCode}>
            Pair my headset
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    // @ts-expect-error
    <Modal open={modal.isOpen} width={448} className="!h-auto">
      <Modal.Close showEscapeLabel={false} onClick={() => modal.close()} className="absolute top-5 right-6" />

      <Modal.Body className="!py-6 text-body-sm space-y-3 text-center">
        <img src={workshopOnboardingUrl} alt="Welcome illustration" />

        <p className="text-body-lg">
          Welcome to {branding.isProd ? branding.shortProductName : branding.productName}!
        </p>

        {$env.releaseChannel === 'beta' ? (
          <>
            <p className="text-body-md">
              Thanks for your participation in our beta program! <br /> To get started, pair your Meta Quest
              headset here.
            </p>
          </>
        ) : (
          <>
            <p className="text-body-md max-w-80 mx-auto">
              You&apos;re just a few steps away from your first immersive&nbsp;design&nbsp;review.
            </p>
            <p className="text-body-md max-w-80 mx-auto">
              Get started by{' '}
              <a
                href={$env.metaStoreUrl}
                target="_blank"
                className="text-blue-700 hover:underline"
                rel="noreferrer"
              >
                downloading {branding.shortProductName}
              </a>{' '}
              from the Meta Quest store, and then pair your Meta Quest headset here.
            </p>
          </>
        )}
      </Modal.Body>

      <Modal.Footer className="space-x-1">
        <Button variant="tertiary" onClick={() => modal.close()}>
          Cancel
        </Button>
        <Button as="a" href="/pair" variant="primary" onClick={getPairingCode}>
          Pair Headset
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
