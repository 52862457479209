import { PencilIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { ComponentProps, ReactElement, ReactNode, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { Tooltip } from '../base/tooltip';

interface ChildrenFnParams {
  isEditing: boolean;
  setIsEditing(isEditing: boolean): void;
}

interface Props extends Omit<ComponentProps<'button'>, 'children'> {
  topEditButton?: boolean;
  canEdit?: boolean;
  onEdit?: () => void;
  onClickAway?: () => void;
  disableClickaway?: boolean;
  children: ReactNode | ((params: ChildrenFnParams) => ReactElement);
}

export function InlineEditable({
  topEditButton,
  canEdit,
  onEdit,
  onClickAway,
  children,
  disableClickaway,
  ...props
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  useClickAway(ref, () => {
    if (isEditing && !disableClickaway) {
      onClickAway?.();
      setIsEditing(false);
    }
  });

  const child = typeof children === 'function' ? children({ isEditing, setIsEditing }) : children;

  return (
    <div ref={ref}>
      {isEditing ? (
        child
      ) : (
        <Tooltip placement="left" content={!canEdit && 'You dont have permission to edit this field'}>
          <button
            {...props}
            onClick={e => {
              if (canEdit) {
                props.onClick?.(e);
                onEdit?.();
                setIsEditing(true);
                childRef.current?.focus();
              }
            }}
            className={clsx(
              'group inline-flex max-w-full min-h-[2.375rem] border border-dashed border-transparent p-2 rounded',
              canEdit ? 'hover:border-blue-500' : 'cursor-default',
              topEditButton ? 'items-start' : 'items-center',
              props.className
            )}
            disabled={!canEdit}
          >
            {child}

            {canEdit && (
              <PencilIcon
                size={16}
                className={clsx(
                  'ml-3 text-charcoal-700 opacity-50 group-hover:opacity-100',
                  topEditButton && 'mt-0.5'
                )}
              />
            )}
          </button>
        </Tooltip>
      )}
    </div>
  );
}
