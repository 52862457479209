import { ChevronRightIcon, FolderIcon, MoreVerticalIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import clsx from 'clsx';
import { Key, memo, useEffect, useMemo, useState } from 'react';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { Popover } from '~/shared/components/base/popover';
import { InlineEditText } from '~/shared/components/forms/inline-edit-text';
import { Mutations } from '~/shared/hooks/mutations';
import { Queries } from '~/shared/hooks/queries';
import { MoveToFolderModal } from '../../../../../features/files/components/move-to-folder-modal';
import { useFolderTreeContext } from '../context';
import { FolderTreeContextMenu } from './context-menu';
import { FileReference } from './file-reference';

interface Props {
  urn: string;
  name: string;
  isDisabled?: boolean;
  defaultExpanded?: boolean;
  hasChildren?: boolean;
  depth: number;
  path?: string;
  key?: Key;
}

export const Folder = memo(function _Folder({ urn, ...props }: Props) {
  const { onFolderSelect, activeFolderUrn, ...context } = useFolderTreeContext();

  const [expanded, setExpanded] = useState<boolean>(
    Boolean(
      props.defaultExpanded ||
        (props.hasChildren && props.depth === 0) ||
        props.path?.includes(urn.replace(/urn:adsk\.[^:]+:fs\.folder:co\./g, ''))
    )
  );
  const [isEditing, setIsEditing] = useState(false);
  const [addSubFolder, setAddSubFolder] = useState(false);
  const [moveToFolder, setMoveToFolder] = useState(false);

  const isSelected = activeFolderUrn === urn;
  const effectiveDepth = props.depth + 1;
  const createFolder = Mutations.Files.useCreateFolder();
  const moveFolder = Mutations.Files.useMoveFolder();
  const renameFolders = Mutations.Files.useRenameFolder();

  const folder = Queries.Files.useFindFolderById({
    projectId: context.projectId,
    folderId: urn,
    opts: {
      enabled: !props.isDisabled && expanded,
      active: isSelected,
    },
  });

  const contents = Queries.Files.useListFolderContents({
    projectId: context.projectId,
    platform: context.platform,
    folderId: urn,
    filter: {
      type: ['items'],
    },
    opts: {
      enabled: context.mode !== 'FOLDERS_ONLY' && expanded,
    },
  });

  useEffect(() => {
    if (isSelected && !expanded) {
      setExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only update when isSelected changes
  }, [isSelected]);

  useEffect(() => {
    if (isSelected && folder.data) {
      onFolderSelect?.(folder.data);
    }
  }, [onFolderSelect, isSelected, folder.data]);

  const folders = useMemo(() => {
    if (!folder.data) {
      return [];
    }

    return folder.data.children.sort((a, b) => {
      const lowerA = a.name.toLowerCase();
      const lowerB = b.name.toLowerCase();
      if (lowerA < lowerB) return -1;
      if (lowerA > lowerB) return 1;
      return 0;
    });
  }, [folder.data]);

  return (
    <li className="inline-flex flex-col min-w-full">
      <div className="group relative hover:bg-charcoal-50">
        <a
          role="button"
          href={`?folder=${urn}`}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            if (activeFolderUrn !== urn) {
              context.setActiveFolder(urn);
              if (expanded) return;
            }

            setExpanded(!expanded);
          }}
          className={clsx(
            'flex items-center min-w-full appearance-none font-medium py-1.5 border-l-2',
            isSelected ? 'bg-charcoal-50 border-charcoal-500' : 'border-transparent hover:bg-charcoal-50',
            props.isDisabled && 'opacity-50'
          )}
          style={{ paddingLeft: `${props.depth * 1.75}rem` }}
        >
          <button
            className="inline-flex items-center justify-center w-7"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setExpanded(!expanded);
              if (!expanded) folder.refetch();
            }}
          >
            {(props.hasChildren || props.depth === 0 || context.mode !== 'FOLDERS_ONLY') && (
              <ChevronRightIcon
                className={clsx('transition-transform duration-100 transform-gpu', expanded && 'rotate-90')}
              />
            )}
          </button>

          <div className="inline-flex items-center justify-center w-7 h-7 mr-1">
            {folder.isFetching || folder.isRefetching || renameFolders.isPending ? (
              <ProgressRing size="xsmall" />
            ) : (
              <FolderIcon />
            )}
          </div>

          {isEditing ? (
            <InlineEditText
              hideEditButton={true}
              initialValue={props.name}
              isEditing
              setIsEditing={setIsEditing}
              onSubmit={name =>
                renameFolders.mutate({ projectId: context.projectId, attrs: { name: name, urn: urn } })
              }
            />
          ) : (
            <div className="flex-1 flex items-center truncate relative">
              <p className="truncate pr-7">{props.name}</p>
            </div>
          )}
        </a>

        {context.mode === 'FOLDERS_ONLY' && !isEditing && !context.disableContextMenu && (
          <div className="absolute top-0 right-0 bottom-0 flex items-center">
            <Popover>
              <Popover.Trigger
                className={clsx(
                  'hover:text-blue-500 group-hover:opacity-50 px-1',
                  isSelected ? 'opacity-50' : 'opacity-0'
                )}
                onClick={e => {
                  e.stopPropagation();
                  context.setActiveFolder(urn);
                }}
              >
                <MoreVerticalIcon className="ml-auto" />
              </Popover.Trigger>
              <Popover.Content
                hideArrow
                hideWhenDetached
                side="bottom"
                align="end"
                sideOffset={-8}
                alignOffset={8}
                className="z-20"
              >
                <FolderTreeContextMenu
                  projectId={context.projectId}
                  folderId={urn}
                  setAddSubFolder={value => {
                    if (value) {
                      context.setActiveFolder(urn);
                      setExpanded(true);
                    }
                    setAddSubFolder(value);
                  }}
                  setIsEditing={setIsEditing}
                  setMoveToFolder={setMoveToFolder}
                  target={folder}
                />
              </Popover.Content>
            </Popover>
          </div>
        )}
      </div>

      {moveToFolder && (
        <MoveToFolderModal
          projectId={context.projectId}
          close={() => setMoveToFolder(false)}
          onSubmit={async folder => {
            if (!folder?.name) {
              return NotificationManager.push({
                status: 'error',
                content: 'Attempted file selection failed. Try again.',
              });
            }

            moveFolder.mutate({
              projectId: context.projectId,
              attrs: { folderUrn: urn, targetFolderUrn: folder.id },
            });
            setMoveToFolder(false);
          }}
        />
      )}

      {expanded && (
        <ul aria-expanded={expanded} className={clsx('inline-flex flex-col min-w-full', !expanded && 'hidden')}>
          {addSubFolder && (
            <li
              className={'flex items-center min-w-full appearance-none py-1.5 hover:bg-gray-100 group ml-[2px]'}
              style={{ paddingLeft: `${effectiveDepth * 1.75}rem` }}
            >
              <div className="pl-8 pr-2 flex-1">
                <InlineEditText
                  hideEditButton={true}
                  initialValue={props.name + '.' + Number(folders.length + 1)}
                  isEditing={addSubFolder}
                  setIsEditing={setAddSubFolder}
                  ignoreInitialValue={addSubFolder}
                  onSubmit={name =>
                    createFolder.mutate({
                      projectId: context.projectId,
                      attrs: { name: name, parentFolderUrn: urn },
                    })
                  }
                />
              </div>
            </li>
          )}

          {folders.map(folder => (
            <Folder
              key={folder.id}
              {...folder}
              urn={folder.id}
              hasChildren={folder.hasChildren}
              depth={effectiveDepth}
              path={props.path}
            />
          ))}

          {context.mode !== 'FOLDERS_ONLY' &&
            folders.length === 0 &&
            contents.data &&
            contents.data.length === 0 && (
              <li
                className="flex items-center min-w-full appearance-none py-1.5 hover:bg-gray-100 group ml-[2px] opacity-40"
                style={{ paddingLeft: `${effectiveDepth * 1.75}rem` }}
              >
                <span className="inline-flex w-8 pl-[0.125rem] opacity-30">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                  </svg>
                </span>
                This folder is empty
              </li>
            )}

          {context.mode !== 'FOLDERS_ONLY' &&
            contents.data &&
            contents.data.map(item => (
              <FileReference
                key={item.id}
                documentVersionId={item.latestVersionId}
                id={item.id}
                name={item.name}
                isProcessing={item.isProcessing}
                fileType={item.fileType}
                depth={effectiveDepth}
              />
            ))}
        </ul>
      )}
    </li>
  );
});
