import { ArrowFullScreenIcon, ChevronDownIcon, XIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { useState } from 'react';
import { UploadManager } from '..';
import { UploadDropArea } from '../../upload-drop-area';
import { UploadDropWrapper } from '../../upload-drop-wrapper';
import { usePushToUploadQueue } from '../hooks/use-push-to-upload-queue';
import { QueueListItem } from './queue-list-item';

interface Props {
  projectId?: string;
  folderId?: string;
}

export function MiniUploadMenu({ projectId, folderId }: Props) {
  const $uploads = UploadManager.useStore();
  const queue = UploadManager.useQueue();
  const pushToUploadQueue = usePushToUploadQueue();

  const [isCollapsed, setIsCollapsed] = useState(false);

  function queueFiles(files: File[]) {
    if (projectId && folderId) {
      for (const file of files) {
        pushToUploadQueue({
          file,
          projectId,
          parentFolderUrn: folderId,
        });
      }
    }
  }

  return (
    <section className="fixed z-20 right-6 bottom-6 w-full max-w-sm shadow-high bg-white rounded-md">
      <div className="flex items-center h-12 px-5 bg-charcoal-100">
        <p className="text-heading-3">Upload</p>

        <div className="ml-auto space-x-3 text-charcoal-600">
          <button
            className="hover:text-blue-500"
            onClick={() => {
              $uploads.state = 'MODAL';
            }}
          >
            <ArrowFullScreenIcon size={20} />
          </button>

          <button
            className="hover:text-blue-500"
            onClick={() => {
              setIsCollapsed(!isCollapsed);
            }}
            disabled={queue.length === 0}
          >
            <ChevronDownIcon
              size={20}
              className={clsx(
                'transition-transform',
                queue.length === 0 && 'opacity-50',
                isCollapsed && 'rotate-180'
              )}
            />
          </button>

          <button
            className="hover:text-blue-500"
            onClick={() => {
              $uploads.state = 'HIDDEN';
            }}
          >
            <XIcon size={20} />
          </button>
        </div>
      </div>

      {queue.length === 0 && (
        <div className="max-h-72 overflow-auto p-6">
          <UploadDropArea onFileDrop={queueFiles} className="flex-1 h-full p-4 pb-10" />
        </div>
      )}

      {queue.length > 0 && !isCollapsed && (
        <UploadDropWrapper onFileDrop={queueFiles} className="h-full">
          <div className="max-h-72">
            <ul>
              {queue.map(item => (
                <QueueListItem key={item.uploadUid} {...item} small />
              ))}
            </ul>
          </div>
        </UploadDropWrapper>
      )}
    </section>
  );
}
