import Button, { LinkButton } from '@adsk/alloy-react-button';
import { LaptopIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import { Oasis, WorkshopPermissions } from '@oasis/sdk';
import { ArrayUtils } from '@oasis/utils';
import clsx from 'clsx';
import { Key, memo, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { WorkshopThumbnail } from '~/features/workshops/components/workshop-thumbnail';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { InlineEditText } from '~/shared/components/forms/inline-edit-text';
import { HeadsetIcon } from '~/shared/components/icons/headset-icon';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Mutations } from '~/shared/hooks/mutations';
import { Queries } from '~/shared/hooks/queries';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';
import { useOpenModal } from '~/shared/hooks/use-modal';
import { tryOpenInNewTab } from '~/shared/utils/utils.network';
import { useWorkshopActiveUsers } from '../hooks/use-workshop-active-users';
import { useShowWorkshopPanel, useWorkshopDetails } from '../hooks/use-workshop-details';
import { WorkshopActiveUsers } from './workshop-active-users';
import { WorkshopOverflowMenu } from './workshop-overflow-menu';

interface Props {
  onlyMyWorkshops?: boolean;
  query: string;
  setQuery: (query: string) => void;
}

export const WorkshopsList = memo(function _WorkshopsList(props: Props) {
  const $session = Oasis.Session.useStore();
  const { projectId } = useProjectContext();
  const { isLoading, data, error } = Queries.Workshops.useListAllWorkshops(projectId);
  const openModal = useOpenModal();

  const showCollaborativeWebViewer = useFeatureFlags('241127-7456-web-viewer');
  const $workshopUsers = useWorkshopActiveUsers();

  const workshopsWithUsers = useMemo(() => {
    if (!data?.results) {
      return [];
    }

    let workshops = data.results
      .map(workshop => ({
        ...workshop,
        activeUsers: $workshopUsers.get(workshop.id) ?? [],
      }))
      .sort((a, b) => ArrayUtils.sortAlphanumeric(a.createdDate, b.createdDate, 'desc'))
      .sort((a, b) => ArrayUtils.sortAlphanumeric(a.activeUsers.length, b.activeUsers.length, 'desc'));

    if (props.onlyMyWorkshops) {
      workshops = workshops.filter(workshop => workshop.userId === $session.user?.id);
    }

    if (props.query) {
      workshops = workshops.filter(workshop => workshop.name.toLowerCase().includes(props.query.toLowerCase()));
    }

    return workshops;
  }, [data, $workshopUsers, props.onlyMyWorkshops, props.query, $session.user?.id]);

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <ProgressRing size="medium" />
      </div>
    );
  }

  const isFreeViewer = $session.license.type === 'FREE_VIEWER';

  if (data && data.results) {
    return (
      <section className="flex-1">
        {workshopsWithUsers.length > 0 && (
          <ul className="grid sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-5">
            {workshopsWithUsers.map((workshop, index) =>
              showCollaborativeWebViewer ? (
                <WorkshopItemV2 key={workshop.id} index={index} {...workshop} />
              ) : (
                <WorkshopItem key={workshop.id} index={index} {...workshop} />
              )
            )}
          </ul>
        )}

        {workshopsWithUsers.length === 0 && props.query.length === 0 && (
          <OasisErrorState
            illustration="skylineGrey"
            title="No workshops to display"
            description={
              isFreeViewer
                ? 'Your account is limited by a free license. You are not permitted to create new workshops.'
                : 'Create a workshop to get started'
            }
          >
            {!isFreeViewer && (
              <LinkButton
                onClick={() => openModal('create-workshop')}
                className="text-blue-500 font-medium hover:underline"
              >
                Create Workshop
              </LinkButton>
            )}
          </OasisErrorState>
        )}

        {workshopsWithUsers.length === 0 && props.query.length !== 0 && (
          <OasisErrorState
            illustration="skylineGrey"
            title="No workshops found"
            description="Check your search terms and try again"
          >
            <LinkButton
              onClick={() => props.setQuery('')}
              className="text-blue-500 font-medium hover:underline"
            >
              Show all workshops
            </LinkButton>
          </OasisErrorState>
        )}
      </section>
    );
  }

  return <OasisErrorState oasisError={error} />;
});

interface WorkshopItemV2Props {
  projectId: string;
  userId: string;
  id: string;
  name: string;
  createdDate: number;
  settings: Record<string, any>;
  permission: WorkshopPermissions;
  index: number;
  key: Key;
}

const WorkshopItemV2 = memo(function WorkshopItemV2(props: WorkshopItemV2Props) {
  const $env = Oasis.Env.useStore();
  const $session = Oasis.Session.useStore();
  const params = useParams<{ workshopId: string }>();
  const showPanel = useShowWorkshopPanel({ projectId: props.projectId });
  const openModal = useOpenModal();

  const [isEditing, setIsEditing] = useState(false);

  const updateWorkshop = Mutations.Workshops.useUpdateWorkshop();
  const cannotView = props.permission === 'VIEW' || $session.license.type === 'FREE_VIEWER';

  return (
    <li
      className={clsx(
        'group/item rounded-md bg-white border',
        cannotView
          ? 'border-charcoal-200 shadow-low'
          : params.workshopId === props.id
            ? 'shadow-halo-lg shadow-blue-500.35 border-blue-700'
            : 'shadow-low hover:shadow-halo-md hover:shadow-charcoal-100  border-charcoal-200 hover:border-charcoal-400'
      )}
    >
      <div
        role="button"
        className={clsx('appearance-none w-full', cannotView && 'cursor-default')}
        onClick={e => {
          if (cannotView) return;
          if (tryOpenInNewTab(e, `${window.location.href}/workshops/${props.id}`)) return;
          showPanel(props.id, 'details');
        }}
      >
        <div className="relative aspect-video border-b border-charcoal-200 rounded-t-[0.1875rem] overflow-hidden">
          <WorkshopThumbnail
            workshopId={props.id}
            workshopName={props.name}
            hideButtons={$env.isWeb}
            hideActiveUsers={$env.isVr}
          />
        </div>

        <div className="px-2 pt-1 web:pb-3 vr:pb-1 vr:flex vr:items-center">
          <div className="flex-1 overflow-hidden pr-2">
            <InlineEditText
              initialValue={props.name}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              disabled={props.permission === 'VIEW' || $session.license.type === 'FREE_VIEWER'}
              onSubmit={name => {
                updateWorkshop.mutate({
                  workshopId: props.id,
                  attrs: { name },
                });
              }}
            />
          </div>

          <div className="web:mt-1 flex items-center">
            {$env.isWeb && (
              <div className="space-x-1 opacity-50 group-hover/item:opacity-100 transition-opacity">
                <Button
                  as={Link}
                  // @ts-ignore
                  to={`/projects/${props.projectId}/workshops/${props.id}/viewer`}
                  onClick={e => e.stopPropagation()}
                  variant="secondary"
                  size="small"
                >
                  <LaptopIcon className="mr-1 w-4 h-4" /> Join on web
                </Button>
                <Button
                  variant="tertiary"
                  size="small"
                  onClick={e => {
                    e.stopPropagation();
                    openModal('open-workshop-headset', { workshopId: props.id, workshopName: props.name });
                  }}
                >
                  <HeadsetIcon className="mr-1 w-4 h-4" />
                  Enter in VR
                </Button>
              </div>
            )}
            <div className="ml-auto flex items-center">
              <WorkshopOverflowMenu
                id={props.id}
                name={props.name}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                canEdit={props.permission !== 'VIEW'}
                canManage={props.permission === 'MANAGE'}
              />
            </div>
          </div>
        </div>

        {$env.isVr && (
          <div className="pb-2 px-2">
            <WorkshopActiveUsers workshopId={props.id} />
          </div>
        )}
      </div>
    </li>
  );
});

interface WorkshopItemProps {
  userId: string;
  id: string;
  name: string;
  createdDate: number;
  settings: Record<string, any>;
  permission: WorkshopPermissions;
  index: number;
  key: Key;
}

function WorkshopItem(props: WorkshopItemProps) {
  const $session = Oasis.Session.useStore();
  const { showPanel } = useWorkshopDetails();

  const [isEditing, setIsEditing] = useState(false);

  const updateWorkshop = Mutations.Workshops.useUpdateWorkshop();
  const cannotView = props.permission === 'VIEW' || $session.license.type === 'FREE_VIEWER';

  return (
    <li className="rounded-md bg-white border border-charcoal-200">
      <div
        role="button"
        className={clsx('appearance-none w-full', cannotView && 'cursor-default')}
        onClick={e => {
          if (cannotView) return;
          if (tryOpenInNewTab(e, `${window.location.href}?workshopId=${props.id}`)) return;
          showPanel(props.id, 'details');
        }}
      >
        <div className="relative aspect-video border-b border-charcoal-200 rounded-t-[0.1875rem] overflow-hidden">
          <WorkshopThumbnail workshopId={props.id} workshopName={props.name} />
        </div>

        <div className="p-2">
          <div className="flex items-center min-h-[2.375rem]">
            <div className="flex-1 overflow-hidden pr-2">
              <InlineEditText
                initialValue={props.name}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                disabled={props.permission === 'VIEW' || $session.license.type === 'FREE_VIEWER'}
                onSubmit={name => {
                  updateWorkshop.mutate({
                    workshopId: props.id,
                    attrs: { name },
                  });
                }}
              />
            </div>

            <div className="ml-auto flex items-center">
              <WorkshopOverflowMenu
                id={props.id}
                name={props.name}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                canEdit={props.permission !== 'VIEW'}
                canManage={props.permission === 'MANAGE'}
              />
            </div>
          </div>

          <div className="mt-1">
            <WorkshopActiveUsers workshopId={props.id} />
          </div>
        </div>
      </div>
    </li>
  );
}
