import { z } from 'zod';
import { CommonSchemas } from '../../lib/common.schemas';

const workshopMember = z.object({
  autodeskId: z.string().nullable(),
  companyName: z.string().nullable(),
  email: z.string(),
  name: z.string(),
});

export const WorkshopsSchemas = {
  createWorkshop: z.object({
    id: z.string(),
    name: z.string(),
  }),

  listWorkshops: z.object({
    pagination: CommonSchemas.pagination,
    results: z
      .array(
        z.object({
          id: z.string(),
          name: z.string(),
          projectId: z.string(),
          userId: z.string(),
          createdDate: z.number(),
          settings: z.record(z.string(), z.any()),
          permission: CommonSchemas.workshopPermission,
          fluidState: CommonSchemas.fluidState,
          // settings: z.object({
          //   version: z.string(),
          //   logo: z.string(),
          //   seating: z.object({
          //     url: z.string(),
          //     count: z.number(),
          //   }),
          // }),
        })
      )
      .nullable(),
  }),

  findWorkshopById: z.object({
    id: z.string(),
    name: z.string(),
    userId: z.string(),
    createdDate: z.number(),
    projectId: z.string(),
    fluidState: CommonSchemas.fluidState,
    permission: CommonSchemas.workshopPermission.optional(),
    settings: z
      .object({
        version: z.string().optional(),
        voice: z
          .object({
            channelToken: z.string().optional(),
            userChannelId: z.union([z.string(), z.number()]).optional(),
            encryptionMode: z.string(),
            encryptionKey: z.string().optional(),
            encryptionSalt64BitEncoded: z.string().optional(),
          })
          .optional(),
      })
      .optional(),
  }),

  updateWorkshop: z.object({
    id: z.string(),
    name: z.string(),
  }),

  listACCUsers: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(
      z.object({
        id: z.string(),
        autodeskId: z.string().nullable(),
        accessLevels: z.object({
          accountAdmin: z.boolean(),
          projectAdmin: z.boolean(),
          executive: z.boolean(),
        }),
      })
    ),
  }),

  listWorkshopMembers: z.object({
    pagination: CommonSchemas.pagination,
    results: z.object({
      admins: z.array(
        workshopMember.extend({
          permission: z.unknown(),
        })
      ),
      members: z.array(
        workshopMember.extend({
          permission: z.union([z.literal('VIEW'), z.literal('EDIT'), z.literal('MANAGE')]),
        })
      ),
    }),
  }),

  listActiveUsers: z.object({
    pagination: CommonSchemas.pagination,
    results: z.array(
      z.object({
        id: z.string(),
        firstName: z.string().nullable(),
        lastName: z.string().nullable(),
        email: z.string(),
        images: z.record(z.string(), z.string()),
      })
    ),
  }),

  getWorkshopPermissions: z.object({
    VIEW: z.array(z.string()).optional(),
    EDIT: z.array(z.string()).optional(),
    MANAGE: z.array(z.string()).optional(),
  }),

  updateWorkshopPermissions: z.object({
    VIEW: z.array(z.string()).optional(),
    EDIT: z.array(z.string()).optional(),
    MANAGE: z.array(z.string()).optional(),
  }),

  getWorkshopSettings: z.record(z.string(), z.object({ value: z.unknown() })),

  getWorkshopThumbnail: z.object({
    signedUrl: z.string(),
  }),
};
