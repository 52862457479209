import Avatar from '@adsk/alloy-react-avatar';
import {
  TableCell,
  TableHeader,
  TableHeaderGroup,
  TableRow,
  TableSkeletonLoadingRow,
  useSkeletonLoadingRows,
  useTable,
} from '@adsk/alloy-react-table';
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { Queries } from '~/shared/hooks/queries';

interface Row {
  id: string;
  email: string;
  name: string;
  companyName: string | null;
  imageUrl: string | null;
  xrAccessLevel: string | null;
}

const columnHelper = createColumnHelper<Row>();

interface Props {
  projectId: string;
}

export function MembersTable({ projectId }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const containerRef = useRef<HTMLDivElement>(null);
  const centerTableRef = useRef<HTMLTableElement>(null);
  const [sorting, setSorting] = useState<SortingState>([]);

  const members = Queries.Projects.useListProjectUsers({ projectId });
  const adminUsers = Queries.Projects.useListAdminUsers({ projectId });

  const data: Row[] = useMemo(() => {
    if (members.data?.results) {
      const adminIds = new Set<string>();

      if (adminUsers.data?.results) {
        for (const admin of adminUsers.data.results) {
          adminIds.add(admin.id);
        }
      }

      return members.data.results.map(member => {
        return {
          ...member,
          name: member.name || 'Project Member',
          xrAccessLevel: adminIds.has(member.id) ? 'XR Admin' : 'XR Member',
        };
      });
    }

    return [];
  }, [members.data, adminUsers.data]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        size: 320,
        minSize: 200,
        header: 'Name',
        cell: info => {
          const name = info.getValue();

          return (
            <p className="text-label-md truncate">
              <Avatar size="small" name={name} imageUrl={info.row.original.imageUrl} className="mr-3" />
              {name}
            </p>
          );
        },
      }),
      columnHelper.accessor('email', {
        size: 320,
        minSize: 200,
        header: 'Email',
        cell: info => <p className="text-label-md truncate">{info.getValue()}</p>,
      }),
      columnHelper.accessor('companyName', {
        size: 320,
        minSize: 200,
        header: 'Company',
        cell: info => <p className="text-label-md truncate">{info.getValue()}</p>,
      }),
      columnHelper.accessor('xrAccessLevel', {
        size: 320,
        minSize: 200,
        header: 'XR Access Level',
        cell: info => <p className="text-label-md truncate">{info.getValue()}</p>,
      }),
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnPinning: {
        left: ['name'],
      },
    },
    onSortingChange: setSorting,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { getTableRowProps, getTableCellProps, getTableHeaderProps } = useTable({
    table,
    enableFullRowClick: true,
  });

  const { getSkeletonLoadingRows, getSkeletonLoadingRowProps } = useSkeletonLoadingRows({
    // @ts-expect-error
    table,
    numberOfRows: 8,
  });

  if (members.error) {
    return <OasisErrorState oasisError={members.error} />;
  }

  if (!members.isLoading && !members.data?.results.length) {
    return (
      <OasisErrorState
        illustration="folderPdfClosedFistGrey"
        title="No members"
        description="Project members and their permissions will show here."
      />
    );
  }

  return (
    <div ref={containerRef} className="flex items-start w-full h-full overflow-auto pr-5">
      <table ref={centerTableRef} className="min-w-full">
        <thead className="sticky top-0 z-20">
          <TableHeaderGroup>
            {table.getLeftHeaderGroups().map(headerGroup => {
              let totalPinnedWidth = 0;

              return headerGroup.headers.map(header => {
                const stickyLeftPos = totalPinnedWidth;
                totalPinnedWidth += header.column.getSize();

                return (
                  <TableHeader
                    key={header.id}
                    {...getTableHeaderProps(header)}
                    className="!sticky z-20 scroll-shadow scroll-shadow--right"
                    style={{ left: stickyLeftPos }}
                  />
                );
              });
            })}
            {table
              .getCenterHeaderGroups()
              .map(headerGroup =>
                headerGroup.headers.map(header => (
                  <TableHeader key={header.id} {...getTableHeaderProps(header)} />
                ))
              )}
          </TableHeaderGroup>
        </thead>
        <tbody>
          {members.isLoading &&
            getSkeletonLoadingRows().map(skeletonRow => (
              <TableSkeletonLoadingRow key={skeletonRow.id} {...getSkeletonLoadingRowProps(skeletonRow)} />
            ))}

          {table.getRowModel().rows.map(row => {
            let totalPinnedWidth = 0;

            return (
              <TableRow
                key={row.id}
                {...getTableRowProps(row)}
                onClick={() => {
                  searchParams.set('memberId', row.original.id);
                  setSearchParams(searchParams);
                }}
              >
                {row.getLeftVisibleCells().map(cell => {
                  const leftStickyPos = totalPinnedWidth;
                  totalPinnedWidth += +cell.column.getSize();

                  return (
                    <TableCell
                      key={cell.id}
                      {...getTableCellProps(cell)}
                      className="!sticky z-10 scroll-shadow scroll-shadow--right"
                      style={{ left: leftStickyPos }}
                    />
                  );
                })}

                {row.getCenterVisibleCells().map(cell => (
                  <TableCell key={cell.id} {...getTableCellProps(cell)} />
                ))}
              </TableRow>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
