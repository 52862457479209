import clsx from 'clsx';
import { ComponentProps, Ref, forwardRef } from 'react';

export interface TextareaInputProps extends ComponentProps<'textarea'> {
  error?: boolean;
  onTextChange?: (value: string) => void;
  ref?: Ref<HTMLTextAreaElement>;
}

export const TextareaInput = forwardRef<HTMLTextAreaElement, TextareaInputProps>(
  ({ onTextChange, ...props }, ref) => {
    return (
      <textarea
        {...props}
        onInput={e => {
          props.onInput?.(e);
          onTextChange?.(e.currentTarget.value);
        }}
        className={clsx(
          'w-full border rounded-sm placeholder:text-charcoal-500 p-2 outline-0',
          'focus:shadow-halo-sm',
          props.error
            ? 'border-red-500 focus:border-red-700 focus:shadow-red-100'
            : 'border-charcoal-400 focus:border-blue-500 focus:shadow-blue-100',
          props.className
        )}
        ref={ref}
      />
    );
  }
);

TextareaInput.displayName = 'TextareaInput';
