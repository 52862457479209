import Button from '@adsk/alloy-react-button';
import { XIcon } from '@adsk/alloy-react-icon';
import { OverflowTooltip } from '@adsk/alloy-react-tooltip';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { ExitIcon } from '~/shared/components/icons/exit-icon';
import { Queries } from '~/shared/hooks/queries';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import type { ViewerPageState } from '../page';

interface Props {
  projectId: string;
  viewerPageState: ViewerPageState;
  workshop: ReturnType<typeof Queries.Workshops.useFindWorkshopById>;
}

export function WorkshopViewerHeader({ workshop, ...props }: Props) {
  const branding = useTemporaryBranding();
  const activeViewable = Queries.Users.useGetActiveViewable(props.projectId);
  const viewableName = activeViewable.data?.name;
  const isReady = props.viewerPageState === 'READY';

  return (
    <header className="relative flex columns-3 items-center h-12 px-2 bg-white border-b border-charcoal-200">
      <div className="max-w-[40%] flex items-center">
        <img
          src={branding.productIconUrl}
          alt={branding.productName + ' logo'}
          className="block h-[2.125rem]"
        />

        {(viewableName || isReady) && (
          <>
            <div className="border-l border-charcoal-300 h-5 mx-3" />
            <div className="text-heading-3 max-w-80">
              <OverflowTooltip content={viewableName} className="max-w-80">
                <span className={clsx(!viewableName && 'text-charcoal-500')}>
                  {viewableName ?? 'No file shared'}
                </span>
              </OverflowTooltip>
            </div>
          </>
        )}
      </div>

      <div className="flex-1 text-center absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
        <p>{workshop.data?.name}</p>
      </div>

      <div className="ml-auto flex">
        {isReady ? (
          <>
            <Button
              as={Link}
              // @ts-ignore
              to={`/projects/${props.projectId}`}
              variant="primary"
              alert
              className="!bg-red-600 active:!bg-red-700"
            >
              <ExitIcon className="mr-2" />
              Leave
            </Button>
          </>
        ) : (
          <>
            {/* @ts-ignore */}
            <Link to={`/projects/${props.projectId}`}>
              <XIcon />
            </Link>
          </>
        )}
      </div>
    </header>
  );
}
