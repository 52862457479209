import { ArrayUtils } from '@oasis/utils';
import { Checkbox } from '~/shared/components/forms/checkbox';
import { Label } from '~/shared/components/forms/label';
import { MultiComboBox } from '~/shared/components/forms/multicombo';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { useUpdateIssueContext } from '../context';

export type ValuesOf<T extends any[]> = T[number];

interface Props {
  watchers: string[];
  onSubmit(value: string[]): void;
  required?: boolean;
}

export function UpdateIssueWatchersField(props: Props) {
  const { projectId } = useProjectContext();
  const { permittedAttributes } = useUpdateIssueContext();

  const projectUsersQuery = Queries.Projects.useListProjectUsers({ projectId });

  const canEdit = permittedAttributes.includes('watchers');
  const items = projectUsersQuery.data ? projectUsersQuery.data.results : [];
  const selectedItems = props.watchers
    .map(id => items.find(item => item.autodeskId === id))
    .filter(ArrayUtils.truthy);

  return (
    <div>
      <Label className="mb-2" required={props.required}>
        Watchers
      </Label>

      <MultiComboBox
        disabled={!canEdit}
        items={items}
        selectedItems={selectedItems}
        getFilteredItems={query => {
          return query
            ? items.filter(item => includesLower(item.name, query) || includesLower(item.companyName, query))
            : items;
        }}
        onChange={value => {
          const ids = value.map(item => item.autodeskId).filter(ArrayUtils.truthy);
          props.onSubmit(ids);
        }}
        renderEmptySelection={() => <p className="text-charcoal-500">Unspecified</p>}
        renderSelectedItem={({ item }) => <p className="p-1 font-label-sm">{item.name}</p>}
        renderItem={({ item, removeItem }) => {
          const isActive = selectedItems.findIndex(selectedItem => selectedItem.id === item.id) > -1;

          return (
            <div
              className="flex items-center"
              onClick={e => {
                if (isActive) {
                  e.stopPropagation();
                  removeItem();
                }
              }}
            >
              <div className="w-6 mr-2 flex items-center justify-center">
                <Checkbox checked={isActive} />
              </div>
              <div className="p-1 font-label-sm ">
                <p>{item.name}</p>
                {item.companyName && <p className="text-charcoal-500">{item.companyName}</p>}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}

function includesLower(value?: string | null, query = '') {
  return value && value.toLowerCase().includes(query.toLowerCase());
}
