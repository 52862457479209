import AgoraRTC, {
  type ConnectionState,
  type IAgoraRTCRemoteUser,
  type IMicrophoneAudioTrack,
  type UID,
} from 'agora-rtc-sdk-ng';
import { proxy, useSnapshot } from 'valtio';
import { proxyMap } from 'valtio/utils';
import { Err, Ok } from '../../lib/result';
import { Oasis } from '../../oasis';
import { Agora } from '../../providers/agora/agora.provider';

type Speaker = { deviceId: string; volumeLevel: number };

interface Store {
  active: boolean;
  status: ConnectionState;
  volume: number; // What the user has set their playback volume to
  volumeIndicator: number; // What the user's current input levels are at
  muted: boolean;
  workshop: { id: string; name: string } | undefined;
  mutedUsers: Map<UID, boolean>;
  activeSpeaker: Speaker | undefined;
  activeInputId: string;
  activeOutputId: string;
}

function _getDefaultState(): Store {
  return {
    active: false,
    status: 'DISCONNECTED',
    volume: 100,
    volumeIndicator: 0,
    muted: true,
    workshop: undefined,
    mutedUsers: proxyMap(),
    activeSpeaker: undefined,
    activeInputId: 'default',
    activeOutputId: 'default',
  };
}

export const Voice = {
  mic: undefined as IMicrophoneAudioTrack | undefined,
  users: new Map<UID, IAgoraRTCRemoteUser>(),
  store: proxy<Store>(_getDefaultState()),

  useStore: () => useSnapshot(Voice.store),

  init() {
    Agora.client.on('exception', event => {
      Oasis.Logger.error({ msg: '[Voice] Exception', event });
    });

    Agora.client.on('crypt-error', () => {
      Oasis.Logger.error({ msg: '[Voice] Crypt Error' });
    });

    Agora.client.on('connection-state-change', status => {
      Voice.store.status = status;
    });

    Agora.client.on('user-published', async (user, mediaType) => {
      if (mediaType === 'audio') {
        const track = await Agora.client.subscribe(user, 'audio');
        track.setVolume(Voice.store.volume);
        track.play();
        Voice.users.set(user.uid, user);
      }
    });

    Agora.client.on('user-unpublished', user => {
      user.audioTrack?.stop();
      Agora.client.unsubscribe(user);
    });

    Agora.client.on('user-info-updated', (userId, type) => {
      const user = Voice.users.get(userId);

      if (type === 'mute-audio') {
        user?.audioTrack?.setVolume(0);
        Voice.store.mutedUsers.set(userId, true);
      }

      if (type === 'unmute-audio') {
        user?.audioTrack?.setVolume(Voice.store.volume);
        Voice.store.mutedUsers.set(userId, false);
      }
    });

    Agora.client.on('volume-indicator', userVolumes => {
      for (const userVolume of userVolumes) {
        const id = userVolume.uid.toString();

        // Check if current user is speaking (level > 0)
        const clientId = Agora.client.uid ? Agora.client.uid.toString() : undefined;
        if (clientId && clientId === id) {
          Voice.store.volumeIndicator = userVolume.level;
        }
      }
    });
  },

  async requestMicTrack() {
    Voice.mic = await Agora.createMicrophoneAudioTrack();
    Voice.mic.setMuted(true);
    return Voice.mic;
  },

  async connectToWorkshop(params: { workshopId: string }) {
    const workshop = await Oasis.Workshops.findWorkshopById(params.workshopId);

    if (!workshop.ok) {
      Voice.store.active = false;
      return workshop;
    }

    if (!workshop.value.settings?.voice?.channelToken || !workshop.value.settings?.voice?.userChannelId) {
      Voice.store.active = false;
      Oasis.Logger.error('[Voice.connect] Voice `channelToken` or `userChannelId` is missing');
      return Err({ code: 'INVALID_CHANNEL_TOKEN' });
    }

    if (!Oasis.Session.store.user) {
      return Err({ code: 'UNAUTHORIZED' });
    }

    const connectResult = await Agora.connect({
      channel: workshop.value.id,
      channelToken: workshop.value.settings.voice.channelToken,
      uid: workshop.value.settings.voice.userChannelId,
      encryptionMode: workshop.value.settings.voice.encryptionMode,
      encryptionKey: workshop.value.settings.voice.encryptionKey,
      encryptionSalt64BitEncoded: workshop.value.settings.voice.encryptionSalt64BitEncoded,
    });

    if (!connectResult.ok) {
      Voice.store.status = 'DISCONNECTED';
      return connectResult;
    }

    if (!Voice.mic) {
      await Voice.requestMicTrack();
    }

    if (Voice.mic) {
      Agora.client.enableAudioVolumeIndicator();
      await Agora.client.publish(Voice.mic);
    }

    Voice.store.active = true;
    Voice.store.workshop = {
      id: workshop.value.id,
      name: workshop.value.name,
    };

    const refreshToken = async () => {
      const workshop = await Oasis.Workshops.findWorkshopById(params.workshopId);

      if (workshop.ok && workshop.value.settings?.voice?.channelToken) {
        await Agora.client.renewToken(workshop.value.settings.voice.channelToken);
      }
    };

    Agora.client.on('token-privilege-will-expire', refreshToken);

    return Ok({
      micTrack: Voice.mic,
      disconnectFromWorkshop: () => {
        Agora.client.off('token-privilege-will-expire', refreshToken);
        Voice.disconnect();
      },
    });
  },

  async getDevices() {
    return {
      inputs: (await AgoraRTC.getMicrophones()).sort(device => (device.deviceId === 'default' ? -1 : 0)),
      outputs: (await AgoraRTC.getPlaybackDevices()).sort(device => (device.deviceId === 'default' ? -1 : 0)),
    };
  },

  setVolume(volume: number) {
    Voice.store.volume = volume;
    for (const user of Voice.users.values()) {
      user.audioTrack?.setVolume(volume);
    }
  },

  setMuted(muted: boolean) {
    Voice.store.muted = muted;
    Voice.mic?.setMuted(muted);
  },

  toggleMuted() {
    const muted = !Voice.store.muted;
    Voice.store.muted = muted;
    Voice.mic?.setMuted(muted);
  },

  setVolumeIndicator(level: number) {
    Voice.store.volumeIndicator = level;
  },

  determineActiveSpeaker(speaker: Speaker) {
    const activeSpeakerLevel = Voice.store.activeSpeaker?.volumeLevel || 0;

    if (speaker.volumeLevel > activeSpeakerLevel) {
      Voice.store.activeSpeaker = speaker.volumeLevel > 50 ? speaker : undefined;
    }
  },

  resetStore() {
    const state = _getDefaultState();

    Voice.store.active = state.active;
    Voice.store.status = state.status;
    Voice.store.volume = state.volume;
    Voice.store.muted = state.muted;
    Voice.store.workshop = state.workshop;
    Voice.store.activeInputId = state.activeInputId;
    Voice.store.activeOutputId = state.activeOutputId;
    Voice.store.mutedUsers.clear();
  },

  autodeskIdToNumber(id: string) {
    let agoraId = 0;

    for (let i = 0; i < id.length; i++) {
      const charCode = id.charCodeAt(i);
      agoraId = (agoraId * 31 + charCode) | 0;
    }

    return Math.abs(agoraId);
  },

  async disconnect() {
    await Agora.disconnect();
    Voice.mic?.close();
    Voice.mic = undefined;
    Voice.users.clear();
    Voice.resetStore();
  },
};
