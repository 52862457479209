export const getConcatenatedUserId = (userId: string, deviceId: string) => `${userId}_${deviceId}`;
export const getName = (userName: string, userLastName: string) => `${userName} ${userLastName} (Web)`;

export function generateAnnouncePersonPayload(userColor: string = 'FFFFFF') {
  return {
    AS: {
      muted: 0,
      tid: 6,
    },
    AV: {
      color: userColor,
      manifest: 0,
      pose: [],
      tid: 3,
      type: 2,
      uid: '',
      volume: 0.0,
    },
    CV: {
      loc: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      rot: {
        w: 1.0,
        x: 0.0,
        y: -0.0,
        z: 0.0,
      },
      tid: 7,
      vis: 0,
    },
    HNDNS: {
      right: 1,
      tid: 8,
    },
    RPTRA: {
      bloc: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      cloc: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      crot: {
        w: 1.0,
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
      cscale: {
        x: 1.0,
        y: 1.0,
        z: 1.0,
      },
      status: 0,
      tid: 9,
    },
    TV: {
      tid: 11,
      vis: 0,
    },
    USRSTK: {
      stacked: [],
      tid: 10,
    },
  };
}
