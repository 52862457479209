import clsx from 'clsx';
import { useMemo } from 'react';
import { Combobox } from '~/shared/components/forms/combobox';
import { InlineEditable } from '~/shared/components/forms/inline-editable';
import { Label } from '~/shared/components/forms/label';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { usePermittedAssignees } from '../../../hooks/use-permitted-assignees';
import { useUpdateIssueContext } from '../context';

export type ValuesOf<T extends any[]> = T[number];

interface Props {
  assignedTo?: string | null;
  name: string;
  label: string;
  onSubmit(value: { type: 'user' | 'company' | 'role' | null; id: string | null }): void;
  required?: boolean;
}

export function UpdateIssueAssignedToField(props: Props) {
  const { projectId } = useProjectContext();
  const { permittedAttributes, permittedActions } = useUpdateIssueContext();
  const combobox = Combobox.useComboboxProps();

  const projectUsersQuery = Queries.Projects.useListProjectUsers({ projectId });
  const projectRolesQuery = Queries.Projects.useListRoles({ projectId });
  const projectCompaniesQuery = Queries.Projects.useListCompanies({ projectId });

  const value = useMemo(() => {
    const user = projectUsersQuery.data?.results.find(user => user.autodeskId === props.assignedTo);

    if (user) {
      return (
        <p>
          {user.name} {user.companyName && `(${user.companyName})`}
        </p>
      );
    }

    const role = projectRolesQuery.data?.results.find(role => role.memberGroupId === props.assignedTo);

    if (role) {
      return <p>{role.name}</p>;
    }

    const company = projectCompaniesQuery.data?.results.find(
      company => company.memberGroupId === props.assignedTo
    );

    if (company) {
      return <p>{company.name}</p>;
    }

    return <p className="text-charcoal-500">Unspecified</p>;
  }, [projectUsersQuery.data, projectRolesQuery.data, projectCompaniesQuery.data, props.assignedTo]);

  const canAssignAll = permittedActions && permittedActions.includes('assign_all');
  const canAssignSameCompany = permittedActions && permittedActions.includes('assign_same_company');
  const canEdit = permittedAttributes.includes(props.name);

  const sections = usePermittedAssignees(projectId, canAssignSameCompany);

  function handleClick(type: 'user' | 'company' | 'role' | null, id: string) {
    if (props.assignedTo === id) {
      props.onSubmit({ type: null, id: null });
    } else {
      props.onSubmit({ type, id });
    }
  }

  return (
    <div>
      <Label className="mb-2" required={props.required}>
        {props.label}
      </Label>

      <InlineEditable
        canEdit={canEdit && (canAssignAll || canAssignSameCompany)}
        onClickAway={() => combobox.setQuery('')}
      >
        {({ isEditing, setIsEditing }) => {
          return isEditing ? (
            <Combobox
              {...combobox}
              name="assigned-to"
              open={isEditing}
              setOpen={open => {
                combobox.setOpen(open);
                setIsEditing(open);
              }}
              value={props.assignedTo ?? ''}
            >
              <Combobox.TriggerInput />
              <Combobox.Content>
                {sections.map(
                  section =>
                    section.items.length !== 0 && (
                      <Combobox.Section key={section.title} title={section.title}>
                        {section.title === 'Member' &&
                          section.items.map(item => {
                            if (!item.autodeskId || combobox.filterItem(`${item.name} ${item.companyName}`)) {
                              return null;
                            }

                            return (
                              <Combobox.Item
                                key={item.id}
                                value={item.autodeskId}
                                onClick={() => {
                                  if (item.autodeskId) {
                                    handleClick('user', item.autodeskId);
                                  }
                                }}
                                disabled={!item.autodeskId}
                              >
                                <div>
                                  <p className={clsx(!item.name && 'italic')}>{item.name || 'Project User'}</p>
                                  {item.companyName && <p className="text-charcoal-500">{item.companyName}</p>}
                                </div>
                              </Combobox.Item>
                            );
                          })}

                        {section.title === 'Role' &&
                          section.items.map(item => {
                            if (combobox.filterItem(item.name)) {
                              return null;
                            }

                            return (
                              <Combobox.Item
                                key={item.id}
                                value={item.id}
                                onClick={() => handleClick('role', item.id)}
                              >
                                <p>{item.name}</p>
                              </Combobox.Item>
                            );
                          })}

                        {section.title === 'Company' &&
                          section.items.map(item => {
                            if (combobox.filterItem(item.name)) {
                              return null;
                            }

                            return (
                              <Combobox.Item
                                key={item.id}
                                value={item.id}
                                onClick={() => handleClick('company', item.id)}
                              >
                                <p>{item.name}</p>
                              </Combobox.Item>
                            );
                          })}
                      </Combobox.Section>
                    )
                )}
              </Combobox.Content>
            </Combobox>
          ) : (
            value
          );
        }}
      </InlineEditable>
    </div>
  );
}
