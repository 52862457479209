import Checkbox from '@adsk/alloy-react-checkbox';
import { ChevronDownIcon, PersonIcon, XIcon } from '@adsk/alloy-react-icon';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useClickAway, useDebounce } from 'react-use';
import { QueryHighlighter } from '~/shared/components/base/query-highlighter';
import { Queries } from '~/shared/hooks/queries';

interface SelectedUsers {
  id: string;
  autodeskId: string | null;
  name: string | null;
  email: string;
}

interface Props {
  projectId: string;
  workshopId: string;
  onChange: (selected: SelectedUsers[]) => void;
  omitAutodeskIds?: string[];
}

export function ProjectUserMultiSelect({ projectId, workshopId, onChange, omitAutodeskIds = [] }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<SelectedUsers[]>([]);
  const [query, _setQuery] = useState({ current: '', previous: '' });
  const [debouncedQuery, setDebouncedQuery] = useState('');

  useDebounce(() => setDebouncedQuery(query.current), 350, [query]);

  const projectMembers = Queries.Projects.useListProjectUsers({
    projectId,
    filter: {
      query: debouncedQuery,
    },
  });
  const workshopMembers = Queries.Workshops.useListWorkshopMembers(workshopId);

  function setQuery(value: string) {
    _setQuery({ current: value, previous: query.current });
  }

  useClickAway(containerRef, () => {
    if (isOpen) setIsOpen(false);
  });

  useEffect(() => {
    if (!isOpen) {
      _setQuery({ current: '', previous: '' });
    }
  }, [isOpen]);

  function addUser(user: SelectedUsers) {
    const nextSelected = [...selected, user];
    setSelected(nextSelected);
    onChange?.(nextSelected);
    setQuery('');
    searchRef.current?.focus();
  }

  function removeUser(user: SelectedUsers) {
    const nextSelected = selected.filter(selectedUser => selectedUser.id !== user.id);
    setSelected(nextSelected);
    onChange?.(nextSelected);
  }

  function toggleUser(user: SelectedUsers) {
    if (selected.find(selectedUser => selectedUser.id === user.id)) {
      removeUser(user);
    } else {
      addUser(user);
    }
  }

  const filteredProjectUsers = useMemo(() => {
    if (projectMembers.data && omitAutodeskIds.length) {
      return projectMembers.data.results.filter(
        member => member.autodeskId && !omitAutodeskIds.includes(member.autodeskId)
      );
    }

    return projectMembers.data ? projectMembers.data.results : [];
  }, [projectMembers.data, omitAutodeskIds]);

  const existingWorkshopMembers = useMemo(() => {
    if (!workshopMembers.data?.results.members.length || !debouncedQuery) {
      return [];
    }

    return workshopMembers.data.results.members.filter(member => {
      const lowerQuery = debouncedQuery.toLowerCase();
      return [member.email, member.name].join(' ').toLowerCase().includes(lowerQuery);
    });
  }, [workshopMembers.data, debouncedQuery]);

  return (
    <div
      ref={containerRef}
      className="relative"
      onKeyDown={e => {
        if (e.key === 'Escape') {
          searchRef.current?.blur();
          setIsOpen(false);
        }
      }}
    >
      <div
        className={clsx(
          'flex items-center px-1 pt-1 border cursor-text min-h-[2.375rem] max-h-[6.25rem] overflow-y-auto rounded-sm',
          isOpen
            ? 'shadow-halo-sm shadow-blue-100 border-blue-500'
            : 'border-charcoal-400 hover:border-charcoal-500'
        )}
        onClick={() => searchRef.current?.focus()}
      >
        <div className="flex flex-wrap items-center flex-1">
          {selected.length === 0 && !isOpen && (
            <p className="text-charcoal-500 pl-2">Search for name or email</p>
          )}

          {selected.map(user => {
            return (
              <button
                key={user.id}
                className="group inline-flex items-center mr-1 mb-1 h-7 px-2 bg-charcoal-100 rounded"
                onClick={e => {
                  e.stopPropagation();
                  removeUser(user);
                }}
              >
                <p className="text-label-sm flex items-center">
                  <span className="rounded-full bg-black bg-opacity-10 w-4 h-4 inline-flex items-center justify-center mr-2">
                    <PersonIcon size={12} />
                  </span>
                  {user.name || user.email}
                </p>
                <XIcon size={16} className="ml-1 opacity-50 group-hover:opacity-100" />
              </button>
            );
          })}

          <input
            ref={searchRef}
            className={clsx(
              'appearance-none bg-transparent ring-0 outline-none mb-1 h-7',
              isOpen ? 'min-w-20' : 'w-0',
              isOpen && selected.length === 0 && 'pl-2'
            )}
            style={{ width: `${query.current.length * 1.2}ch` }}
            onFocus={() => setIsOpen(true)}
            value={query.current}
            onChange={e => {
              setQuery(e.currentTarget.value);
            }}
            onKeyUp={e => {
              if (!query.current && !query.previous && ['Delete', 'Backspace'].includes(e.key)) {
                const lastSelected = selected[selected.length - 1];
                if (lastSelected) removeUser(lastSelected);
              }

              setQuery(e.currentTarget.value);
            }}
          />
        </div>
        <button
          className="pl-2 pr-1 opacity-50"
          onClick={e => {
            e.stopPropagation();
            setIsOpen(!isOpen);
            if (!isOpen) searchRef.current?.focus();
          }}
        >
          <ChevronDownIcon className={clsx('transition-transform', isOpen && 'rotate-180')} />
        </button>
      </div>

      {isOpen && (
        <div className="absolute z-10 pt-1 top-full left-[1px] right-[1px] flex">
          {projectMembers.isPending && (
            <div className="w-full bg-white border border-charcoal-200 shadow-high">
              <div className="flex items-center justify-center h-20">
                <ProgressRing size="small" />
              </div>
            </div>
          )}

          {!projectMembers.isPending && (
            <ul
              role="listbox"
              aria-multiselectable={true}
              aria-label="Options"
              className="w-full bg-white border border-charcoal-200 shadow-high max-h-60 overflow-auto"
            >
              {!filteredProjectUsers.length && !existingWorkshopMembers.length && (
                <li key={0} className="flex items-center justify-center">
                  <div className="text-center text-body-sm text-charcoal-700 py-4">
                    <p>User does not have access to this project.</p>
                    <p>Contact your ACC account admin to add them.</p>
                  </div>
                </li>
              )}

              {filteredProjectUsers.map(user => {
                const isSelected = selected.findIndex(selectedUser => selectedUser.id === user.id) > -1;

                return (
                  <li
                    tabIndex={0}
                    key={user.id}
                    role="option"
                    aria-selected={isSelected}
                    className="w-full flex items-center py-3 px-4 hover:bg-charcoal-50 cursor-pointer outline-0 focus-visible:bg-charcoal-50"
                    onClick={() => toggleUser(user)}
                    onKeyDown={e => {
                      e.preventDefault();
                      if ([' ', 'Enter'].includes(e.key)) {
                        toggleUser(user);
                      }
                    }}
                  >
                    <div className="flex">
                      <Checkbox checked={isSelected} className="mr-2 mt-0.5" />
                      <div>
                        {user.name ? (
                          <p>
                            <QueryHighlighter query={debouncedQuery}>{user.name}</QueryHighlighter>
                          </p>
                        ) : (
                          <p className="italic opacity-50">Unnamed</p>
                        )}
                        <p className="text-body-sm opacity-75">
                          <QueryHighlighter query={debouncedQuery}>{user.email}</QueryHighlighter>
                        </p>
                      </div>
                    </div>
                  </li>
                );
              })}

              {existingWorkshopMembers.length > 0 && (
                <li className="text-body-sm text-charcoal-900 bg-charcoal-50 py-2 px-4">Workshop members</li>
              )}

              {existingWorkshopMembers.map(user => {
                return (
                  <li
                    key={user.email}
                    role="option"
                    aria-selected
                    className="w-full flex items-center py-3 px-4 text-charcoal-500"
                  >
                    <div className="flex">
                      <Checkbox checked disabled className="mr-2 mt-0.5" />
                      <div>
                        {user.name ? <p>{user.name}</p> : <p className="italic opacity-50">Unnamed</p>}
                        <p className="text-body-sm opacity-75">{user.email}</p>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}
