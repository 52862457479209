import { FolderArrowRightIcon, PencilIcon, TrashCanIcon } from '@adsk/alloy-react-icon';
import { FolderContent, Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { Link, useSearchParams } from 'react-router-dom';
import { Confirmation } from '~/shared/components/base/confirmation';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { ShareToWorkshopButton } from '~/shared/components/base/share-to-workshop-button';
import { Doctype3dViewIcon } from '~/shared/components/icons/doctype-3d-view-icon';
import { ShareToWorkshopIcon } from '~/shared/components/icons/share-to-workshop-icon';
import { Mutations } from '~/shared/hooks/mutations';
import { Queries } from '~/shared/hooks/queries';
import { ResetModelPositionIcon } from './reset-model-position-icon';

interface Props {
  projectId: string;
  setIsEditing: (isEditing: boolean) => void;
  setMoveToFolder: (moveToFolder: boolean) => void;
  target: FolderContent;
  setContextItem: (contextItem: FolderContent | null) => void;
  isFileShared?: boolean;
  isFileProcessing?: boolean;
}

export function FilesContextMenu({
  projectId,
  setIsEditing,
  setMoveToFolder,
  target,
  setContextItem,
  isFileShared,
  isFileProcessing,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const $env = Oasis.Env.useStore();

  const isRevit = target?.fileType === 'rvt' || target?.name.endsWith('.rvt');

  // Preload file details panel if the file is a Revit file
  Queries.Files.useList3dViews({
    projectId,
    documentId: target.type === 'items' ? target.id : '',
    enabled: isRevit,
  });

  const deleteFiles = Mutations.Files.useDeleteFiles();
  const deleteFolders = Mutations.Files.useDeleteFolders();

  async function handleDelete() {
    if (target.type === 'items') {
      Confirmation.show({
        title: 'Delete File?',
        description:
          'The selected item will be deleted from the project. You can restore this file through ACC Docs by following the link to Deleted items.',
        confirmLabel: 'Delete',
        confirm() {
          deleteFiles.mutate({
            projectId,
            fileIds: [target.id],
          });
        },
      });
    }

    if (target.type === 'folders') {
      Confirmation.show({
        title: 'Delete Folder?',
        description:
          'The selected item will be deleted from the project. You can restore this file through ACC Docs by following the link to Deleted items.',
        confirmLabel: 'Delete',
        confirm() {
          deleteFolders.mutate({
            projectId,
            folderIds: [target.id],
          });
        },
      });
    }
  }

  async function handleMoveFolder() {
    if (target.type === 'items') {
      setMoveToFolder(true);
    }

    if (target.type === 'folders') {
      Confirmation.show({
        title: 'Move Folder?',
        description:
          "The folder will inherit permissions and subscribers from the destination folder. Subscribers to the current folder aren't retained.",
        confirmLabel: 'Continue',
        isNonDestructive: true,
        confirm() {
          setMoveToFolder(true);
        },
      });
    }
  }

  return (
    <aside
      className="bg-white shadow-high min-w-[220px] rounded-md overflow-hidden"
      onClick={e => e.stopPropagation()}
    >
      {(target.type === 'items' || isRevit) && (
        <div className="border-b border-charcoal-100">
          {$env.releaseChannel !== 'prod' && isFileShared && (
            <button
              className={clsx(
                'w-full flex items-center px-3 py-2 hover:bg-charcoal-50 cursor-pointer outline-0 focus-visible:bg-charcoal-50',
                'disabled:hover:bg-transparent disabled:opacity-40 disabled:cursor-default'
              )}
              onClick={async e => {
                e.preventDefault();
                e.stopPropagation();
                const res = await Oasis.NetworkCommands.emitResetModelTransform();

                if (!res.ok) {
                  NotificationManager.push({
                    status: 'error',
                    type: 'toast',
                    content: 'Failed to reset model transform. Try again later.',
                  });
                }

                setContextItem(null);
              }}
            >
              <div className="flex items-center justify-center w-8 h-6 mr-2">
                <ResetModelPositionIcon className="w-6 h-6" />
              </div>
              Reset position
            </button>
          )}

          {!isFileShared && (
            <ShareToWorkshopButton
              tooltipPlacement="left"
              fileType={target.fileType}
              fileVersionUrn={target.latestVersionId}
              onClick={() => setContextItem(null)}
              isFileProcessing={isFileProcessing}
            >
              <button
                className={clsx(
                  'w-full flex items-center px-3 py-2 hover:bg-charcoal-50 cursor-pointer outline-0 focus-visible:bg-charcoal-50',
                  'disabled:hover:bg-transparent disabled:opacity-40 disabled:cursor-default'
                )}
              >
                <div className="flex items-center justify-center w-8 h-6 mr-2">
                  <ShareToWorkshopIcon className="w-6 h-6" />
                </div>
                Share to Workshop
              </button>
            </ShareToWorkshopButton>
          )}

          {isRevit && (
            <Link
              to={`?fileDetailsUrn=${target.id}`}
              className="w-full flex items-center px-3 py-2 hover:bg-charcoal-50 cursor-pointer outline-0 focus-visible:bg-charcoal-50 disabled:cursor-default"
              onClick={e => {
                e.preventDefault();
                Oasis.Segment.track('"Show 3d Views" Clicked', { projectId, target });
                searchParams.set('fileDetailsUrn', target.id);
                setSearchParams(searchParams);
                setContextItem(null);
              }}
            >
              <div className="flex items-center justify-center w-8 h-6 mr-2">
                <Doctype3dViewIcon className="h-6" />
              </div>
              Show 3D Views
            </Link>
          )}
        </div>
      )}

      {[
        {
          label: 'Move',
          icon: <FolderArrowRightIcon className="w-8 h-6" />,
          onClick: handleMoveFolder,
        },
        {
          label: 'Rename',
          icon: <PencilIcon />,
          onClick: () => setIsEditing(true),
        },
        {
          label: 'Delete',
          icon: <TrashCanIcon className="w-8 h-6" />,
          onClick: handleDelete,
          isLoading: deleteFolders.isPending || deleteFiles.isPending,
        },
      ].map(button => (
        <button
          key={button.label}
          onClick={e => {
            e.stopPropagation();
            button.onClick();
          }}
          className="w-full flex items-center px-3 py-2 hover:bg-charcoal-50 cursor-pointer outline-0 focus-visible:bg-charcoal-50"
        >
          <div className="flex items-center justify-center w-8 h-6 mr-2">{button.icon}</div>
          {button.label}
        </button>
      ))}
    </aside>
  );
}
