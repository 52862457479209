import { CheckmarkIcon, ChevronDownIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { ComponentProps, ReactNode, createContext, useCallback, useContext, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { twvg } from 'twvg';
import { Checkbox } from './checkbox';
import { TextInput } from './text-input';

/**
 * <Combobox.Item /> needs to be wrapped in either <Combobox.Section /> or <Combobox.List />
 */

interface Props {
  name: string;
  value?: string;
  displayValue?: string;
  onChange?: (value: string) => void;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  query?: string;
  setQuery?: (query: string) => void;
  disabled?: boolean;
  placeholder?: string;
  children: ReactNode;
}

interface ComboboxTriggerButtonProps extends ComponentProps<'div'> {
  searchWhenOpen?: boolean;
}
interface ComboboxContentProps extends ComponentProps<'div'> {}
interface ComboboxSectionProps extends ComponentProps<'dl'> {
  title: string;
}
interface ComboboxListProps extends ComponentProps<'ul'> {}
interface ComboboxItemProps extends ComponentProps<'div'> {
  value: string;
  disabled?: boolean;
  isActive?: boolean;
}

const context = createContext<Omit<Props, 'children'>>({
  name: '',
});

export function Combobox({ children, ...props }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  useClickAway(containerRef, () => {
    props.setOpen?.(false);
    props.setQuery?.('');
  });

  return (
    <context.Provider value={{ ...props }}>
      <div
        ref={containerRef}
        className={clsx(props.open && 'relative z-10')}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            props.setOpen?.(false);
            props.setQuery?.('');
          }
        }}
      >
        {children}
      </div>
    </context.Provider>
  );
}

Combobox.useComboboxProps = function useComboboxProps(defaults?: { open?: boolean; query?: string }) {
  const [open, setOpen] = useState(defaults?.open ?? false);
  const [query, setQuery] = useState(defaults?.query ?? '');

  const filterItem = useCallback(
    (value: string) => {
      return query.length ? !value.toLowerCase().includes(query.toLowerCase()) : false;
    },
    [query]
  );

  return {
    open,
    setOpen,
    query,
    setQuery,
    filterItem,
  };
};

Combobox.TriggerInput = function CombobxTriggerInput() {
  const ctx = useContext(context);

  return (
    <div className="relative">
      <TextInput
        role="combobox"
        name={`${ctx.name}-query`}
        placeholder={ctx.open ? 'Search...' : ctx.displayValue || ctx.value || ctx.placeholder}
        value={ctx.open ? ctx.query : ctx.displayValue || ctx.value}
        onInput={e => ctx.setQuery?.(e.currentTarget.value)}
        onFocus={() => ctx.setOpen?.(true)}
        autoFocus={ctx.open}
        disabled={ctx.disabled}
      />

      {!ctx.disabled && (
        <div className="w-10 h-8 absolute top-1/2 -translate-y-1/2 right-0 flex items-center justify-center pointer-events-none">
          <ChevronDownIcon
            size={16}
            className={clsx('text-charcoal-700 opacity-50 hover:opacity-100', ctx.open && 'rotate-180')}
          />
        </div>
      )}
    </div>
  );
};

Combobox.TriggerButton = function CombobxTriggerButton({
  searchWhenOpen,
  children,
}: ComboboxTriggerButtonProps) {
  const ctx = useContext(context);

  if (ctx.open && searchWhenOpen) {
    return <Combobox.TriggerInput />;
  }

  return (
    <button
      type="button"
      className="relative flex pr-10 w-full rounded-sm border border-charcoal-400 focus:border-blue-500 focus:shadow-blue-100 p-2 outline-0"
      onClick={() => ctx.setOpen?.(!ctx.open)}
    >
      {children}

      {!ctx.disabled && (
        <div className="w-10 h-8 absolute top-1/2 -translate-y-1/2 right-0 flex items-center justify-center pointer-events-none">
          <ChevronDownIcon
            size={16}
            className={clsx('text-charcoal-700 opacity-50 hover:opacity-100', ctx.open && 'rotate-180')}
          />
        </div>
      )}
    </button>
  );
};

Combobox.Content = function ComboboxContent({ children }: ComboboxContentProps) {
  const ctx = useContext(context);

  return (
    <div
      role="listbox"
      aria-label="items"
      aria-expanded={ctx.open}
      className={clsx(
        'w-full max-h-56 overflow-auto shadow-high rounded absolute top-full left-0 mt-1 bg-white',
        !ctx.open && 'hidden'
      )}
    >
      {children}
    </div>
  );
};

Combobox.Section = function ComboboxSection({ title, children, ...props }: ComboboxSectionProps) {
  return (
    <dl {...props} className={clsx('list-none empty:hidden', props.className)}>
      <dt className="sticky top-0 bg-charcoal-50 px-3 py-2 text-label-sm">{title}</dt>
      {children}
    </dl>
  );
};

Combobox.List = function ComboboxList({ children, ...props }: ComboboxListProps) {
  return (
    <ul {...props} className={clsx('list-none', props.className)}>
      {children}
    </ul>
  );
};

Combobox.ItemBase = function ComboboxItem({ children, isActive, ...props }: ComboboxItemProps) {
  const ctx = useContext(context);
  const checked = isActive || ctx.value === props.value;

  return (
    <div
      {...props}
      onClick={e => {
        if (!props.disabled) {
          props.onClick?.(e);
          ctx.onChange?.(props.value);
        }
      }}
      className={clsx(
        'flex items-center py-2 px-3',
        checked ? 'bg-blue-50' : 'hover:bg-charcoal-50',
        twvg('focus', 'outline-none border-transparent shadow-transparent'),
        !checked && 'focus:bg-charcoal-50',
        props.disabled ? 'opacity-50' : 'cursor-pointer',
        props.className
      )}
      onKeyDown={e => {
        if (e.key === 'Enter') e.currentTarget.click();
      }}
      role="checkbox"
      aria-checked={checked}
      tabIndex={checked ? -1 : 0}
    >
      {children}
    </div>
  );
};

Combobox.Item = function ComboboxItem({ children, ...props }: ComboboxItemProps) {
  const ctx = useContext(context);
  const checked = ctx.value === props.value;

  return (
    <li>
      <Combobox.ItemBase {...props}>
        {children}

        {checked && (
          <div className="ml-auto">
            <CheckmarkIcon className="w-5 h-5 text-blue-500" />
          </div>
        )}
      </Combobox.ItemBase>
    </li>
  );
};

Combobox.CheckboxItem = function ComboboxCheckboxItem({ children, ...props }: ComboboxItemProps) {
  const ctx = useContext(context);
  const checked = ctx.value === props.value;

  return (
    <Combobox.Item {...props} role="checkbox" aria-checked={checked}>
      <div className="flex items-center justify-center w-10">
        <Checkbox checked={checked} readOnly />
      </div>

      {children}
    </Combobox.Item>
  );
};
