import { XIcon } from '@adsk/alloy-react-icon';
import { WorkshopPeopleList } from '~/features/workshops/components/workshop-people-list';
import { OasisErrorState } from '~/shared/components/base/oasis-error-state';
import { useWorkshopAudience } from '~/shared/hooks/use-workshop-audience';
import IssuesPanel from './issues-panel';
import { ViewerSettingsPanel } from './viewer-settings-panel';

interface Props {
  projectId: string;
  workshopId: string;
  activePanel?: string;
  onClose?: () => void;
  currentOpenedIssueId?: string;
}

export function ViewerPanels(props: Props) {
  switch (props.activePanel) {
    case 'users':
      return <UsersPanel {...props} />;

    case 'settings':
      return <ViewerSettingsPanel {...props} />;

    case 'issues':
      return <IssuesPanel {...props} />;
  }

  return null;
}

function UsersPanel(props: Props) {
  const $workshopAudience = useWorkshopAudience(props.workshopId);

  return (
    <aside className="flex flex-col shadow-high bg-white z-10 w-80 h-full">
      <header className="flex items-center justify-between h-12 px-4">
        <h3 className="text-heading-1">People</h3>
        <button onClick={props.onClose}>
          <XIcon />
        </button>
      </header>

      {$workshopAudience.allUsers.length < 2 ? (
        <div className="flex flex-1 items-center justify-center">
          <OasisErrorState
            code="NOT_FOUND"
            title="No workshop users"
            description="When other users are present you can view them and control their audio here."
          />
        </div>
      ) : (
        <WorkshopPeopleList workshopId={props.workshopId} displayMode="viewer-panel" />
      )}
    </aside>
  );
}
