import { AlertInformationIcon, PencilIcon } from '@adsk/alloy-react-icon';
import { Oasis } from '@oasis/sdk';
import { Link } from 'react-router-dom';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { Tooltip } from '~/shared/components/base/tooltip';
import { Label } from '~/shared/components/forms/label';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';

interface Props {
  issueId: string;
  linkedDocuments: Array<{ urn: string }>;
  onSubmit?: (documentId: string) => void;
  required?: boolean;
}

export function UpdateIssuePlacementField({ issueId, linkedDocuments, ...props }: Props) {
  const { projectId } = useProjectContext();
  const $env = Oasis.Env.useStore();

  const documentId = linkedDocuments[0]?.urn || '';
  const file = Queries.Files.useFindDocumentById({ projectId, documentId });

  async function handlePlaceIssue() {
    const res = await Oasis.NetworkCommands.placeIssueDot({ projectId, issueId });

    if (!res.ok) {
      NotificationManager.push({
        status: 'error',
        content: 'Failed to initialize issue placement tool. Try again later.',
      });
      return;
    }

    NotificationManager.push({
      status: 'info',
      content: 'Place the issue location in VR',
    });
  }

  let value = <p className="text-charcoal-500">Unspecified</p>;

  if (documentId && file.isLoading) {
    value = <p className="text-charcoal-500">Loading document...</p>;
  }

  if (documentId && file.data?.filename) {
    value = (
      <Link
        to={`/projects/${projectId}/files/${documentId}`}
        state={{ from: 'Issues', backUrl: window.location.href }}
        className="text-blue-700 hover:text-blue-500"
      >
        {file.data.filename}
      </Link>
    );
  }

  return (
    <div>
      <Label className="mb-2 flex items-center" required={props.required}>
        Placement
        <Tooltip content="Editing placement only available in VR.">
          <AlertInformationIcon size={16} className="ml-2" />
        </Tooltip>
      </Label>

      <div className="flex items-center p-2">
        {value}

        {$env.isVr && (
          <button type="button" onClick={handlePlaceIssue}>
            <PencilIcon size={16} className="ml-3 text-charcoal-700 opacity-50 group-hover:opacity-100" />
          </button>
        )}
      </div>
    </div>
  );
}

/**
 * One day we will support the ability to choose a file and place an issue dot inside
 * of the LMV, but until we have an LMV that can handle that we dont need all this.
 */

// export function UpdateIssuePlacementField({ projectId, linkedDocuments, onSubmit }: Props) {
//   // const { permittedAttributes } = useUpdateIssueContext();
//   const [query, setQuery] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   const [documentId, setDocumentId] = useState(linkedDocuments[0]?.urn || '');
//   const [selectedDocumentId, setSelectedDocumentId] = useState(linkedDocuments[0]?.urn || '');

//   const topFolders = Queries.Projects.useListTopFolders({projectId});
//   const file = Queries.Files.useFindDocumentById({ projectId, documentId });

//   const rootFolderUrn = topFolders.data?.rootFolderUrn;
//   // const canEdit = permittedAttributes.includes('linkedDocuments');

//   function closeModal() {
//     setShowModal(false);
//   }

//   function handleSubmit() {
//     setDocumentId(selectedDocumentId);
//     onSubmit(selectedDocumentId);
//     setShowModal(false);
//   }

//   return (
//     <div>
//       <Label className="mb-2">
//         Placement
//       </Label>

//       <InlineEditable
//         canEdit={false}
//         onEdit={() => setShowModal(true)}
//       >
//         {({ isEditing }) => {
//           let label = 'Unspecified';
//           if (documentId && file.data?.filename) label = file.data.filename;
//           if (documentId && file.isLoading) label = 'Loading document...';

//           return (
//             <p
//               className={clsx(
//                 'text-left truncate',
//                 isEditing && 'p-2 border border-transparent',
//                 !documentId && 'opacity-50',
//               )}
//             >
//               {label}
//             </p>
//           );
//         }}
//       </InlineEditable>

//       {showModal && rootFolderUrn && (
//         <Modal title="Select a file" className="max-w-2xl" close={closeModal}>
//           <Modal.Content>
//             <div>
//               <SearchField value={query} onChange={setQuery} />
//             </div>

//             <div className="border border-charcoal-400 mt-5 rounded h-96 max-h-full overflow-auto">
//               {!query && (
//                 <FolderTree
//                   mode="FILE_REFERENCE_RADIO"
//                   projectId={projectId}
//                   selectedFileUrns={selectedDocumentId ? [selectedDocumentId] : []}
//                   onFileSelect={file => setSelectedDocumentId(file.urn)}
//                 />
//               )}

//               {query && rootFolderUrn && (
//                 <FolderSearchList
//                   mode="FILE_REFERENCE_RADIO"
//                   projectId={projectId}
//                   folderUrns={[rootFolderUrn]}
//                   query={query}
//                   selectedFileUrns={selectedDocumentId ? [selectedDocumentId] : []}
//                   onFileSelect={file => setSelectedDocumentId(file.urn)}
//                 />
//               )}
//             </div>
//           </Modal.Content>

//           <Modal.Footer className="flex items-center">
//             <div className="space-x-2">
//               <Button variant="tertiary" onClick={closeModal}>Cancel</Button>
//               <Button onClick={handleSubmit}>Add</Button>
//             </div>
//           </Modal.Footer>
//         </Modal>
//       )}
//     </div>
//   );
// }
