import Button, { LinkButton } from '@adsk/alloy-react-button';
import Checkbox from '@adsk/alloy-react-checkbox';
import { AlertInformationIcon, MagnifyingGlassIcon, XIcon } from '@adsk/alloy-react-icon';
import { RadioButton, RadioGroup } from '@adsk/alloy-react-radio-button';
import { FileSearchFilters, Oasis } from '@oasis/sdk';
import { ArrayUtils } from '@oasis/utils';
import { Label } from '@radix-ui/react-context-menu';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from '~/shared/components/base/tooltip';
import { MultiComboBox } from '~/shared/components/forms/multicombo';
import { SearchInput } from '~/shared/components/forms/search-input';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';

interface Props {
  setQuery: (query: string) => void;
  showSearch: boolean;
  setShowSearch: (showSearch: boolean) => void;
  setFilters: (filters: FileSearchFilters) => void;
  setFilterSupported: (filterSupported: boolean) => void;
  filters: FileSearchFilters;
  query: string;
  folderId: string;
  filterSupported: boolean;
}

export function FileSearchFiltersPanel(props: Props) {
  const { projectId } = useProjectContext();
  const $env = Oasis.Env.useStore();

  const enableIfc = useFeatureFlags('241209-7730-ifc-support');
  const [querySearch, setQuerySearch] = useState(props.query);
  const [types, setTypes] = useState<{}[]>(props.filters.sourceIdType);
  const [fileTypes, setFileTypes] = useState<{}[]>(props.filters.fileType);
  const [foldersSetting, setFoldersSetting] = useState<string | undefined>(
    props.filters.includeTopFolders ? 'all' : 'current'
  );
  const [recursive, setRecursive] = useState(props.filters.recursive);
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [vrSupportedFiles, setVrSupportedFiles] = useState(props.filterSupported);
  const fileTypesData = Queries.Files.useListFileTypesForSearch({
    projectId,
    folderUrns: props.folderId ? [props.folderId] : [],
    recursive,
  });
  const searchInput = useRef<HTMLInputElement>(null);

  const hasUpdatedSettings =
    querySearch.length >= 2 ||
    querySearch !== props.query ||
    !compareObjectArrays(types, props.filters.sourceIdType) ||
    !compareObjectArrays(fileTypes, props.filters.fileType) ||
    (foldersSetting === 'all' ? true : false) !== props.filters.includeTopFolders ||
    recursive !== props.filters.recursive;

  const fileTypesItems = useMemo(() => {
    const data = fileTypesData.data?.results[0];

    if (data) {
      data.values.map(value => {
        return {
          value: value.value,
          label: value.value.toUpperCase(),
        };
      });
    }

    return [
      { value: 'nwc', label: 'NWC' },
      { value: 'nwd', label: 'NWD' },
      { value: 'rvt', label: 'RVT' },
      enableIfc && { value: 'ifc', label: 'IFC' },
    ].filter(ArrayUtils.truthy);
  }, [fileTypesData.data?.results, enableIfc]);

  const selectedFileTypes = fileTypes
    .map(fileType => fileTypesItems.find(item => item.value === fileType))
    .filter(ArrayUtils.truthy);

  const typesItems = [
    { value: 'fs.folder', label: 'Folders' },
    { value: 'dm.lineage', label: 'Items' },
  ];
  const selectedTypes = types
    .map(type => typesItems.find(item => item.value === type))
    .filter(ArrayUtils.truthy);

  useEffect(() => {
    if (props.showSearch) {
      setTimeout(() => setSearchFocused(true), 300);
    }
  }, [props.showSearch]);

  function clearSettings() {
    setQuerySearch('');
    setFoldersSetting('current');
    setRecursive(true);
    setTypes([]);
    setFileTypes([]);
    setVrSupportedFiles(false);
    props.setQuery('');
    props.setFilters({
      sourceIdType: [],
      fileType: [],
      includeTopFolders: false,
      recursive: true,
    });
    props.setFilterSupported($env.isVr);
    searchParams.delete('fileSearchQuery');
    searchParams.delete('sourceIdType');
    searchParams.delete('fileType');
    searchParams.delete('includeTopFolders');
    searchParams.delete('recursive');
    searchParams.delete('filterSupported');
    setSearchParams(searchParams);
  }

  function applySettings() {
    const filters = {
      sourceIdType: types,
      fileType: fileTypes,
      includeTopFolders: foldersSetting === 'all',
      recursive,
    };
    props.setQuery(querySearch);
    props.setFilters(filters);
    props.setFilterSupported(vrSupportedFiles);
    searchParams.set('fileSearchQuery', querySearch);
    searchParams.set('sourceIdType', String(filters.sourceIdType));
    searchParams.set('fileType', String(filters.fileType));
    searchParams.set('includeTopFolders', String(filters.includeTopFolders));
    searchParams.set('recursive', String(filters.recursive));
    searchParams.set('filterSupported', String(vrSupportedFiles));
    setSearchParams(searchParams);
  }

  // @TODO OASIS-5931: Integrate API call for fetching file types in Search Files.
  return (
    <section className="flex flex-col h-full">
      <header className="flex items-center border-b border-charcoal-200 pl-4 py-2">
        <h2 className="text-heading-2">Search</h2>
        <div className="ml-auto flex items-center">
          {hasUpdatedSettings && (
            <LinkButton onClick={clearSettings} className="pb-2">
              Clear all
            </LinkButton>
          )}
          <button
            className="pl-4 pr-1 appearance-none opacity-30"
            onClick={() => {
              props.setShowSearch(false);
            }}
          >
            <XIcon />
          </button>
        </div>
      </header>

      <div className="p-4 flex-auto">
        <SearchInput
          value={querySearch}
          onTextChange={setQuerySearch}
          placeholder="Search"
          focused={searchFocused}
          onBlur={() => setSearchFocused(false)}
          ref={searchInput}
          onKeyDown={event => event.key === 'Enter' && querySearch.length >= 2 && applySettings()}
        />

        <h3 className="text-heading-5 mt-6">Search and filter settings</h3>

        <div className="mt-4">
          <RadioGroup
            variant="horizontal"
            defaultValue="current"
            className="flex space-x-4"
            onChange={value => {
              setFoldersSetting(value?.toString());
              if (value === 'all') {
                setRecursive(true);
              }
            }}
          >
            <label className="flex items-center">
              <RadioButton value="current" />
              <span className="text-label-sm ml-2">Current Folder</span>
            </label>

            <label className="flex items-center">
              <RadioButton value="all" />
              <span className="text-label-sm ml-2">All Folders</span>
              <Tooltip placement="top" className="mb-2 ml-1" content="Search all folders you can access">
                <AlertInformationIcon size={16} className="ml-2 hover:text-blue-500" />
              </Tooltip>
            </label>
          </RadioGroup>
        </div>

        <div className="mt-4">
          <label className="flex items-center">
            <Checkbox
              id="subfolders"
              disabled={foldersSetting === 'all'}
              checked={recursive}
              onChange={checked => setRecursive(Boolean(checked))}
            />
            <span className="text-label-sm ml-2">Subfolders</span>
          </label>
        </div>

        <div className="mt-4">
          <label htmlFor="vrSupportedFiles" className="flex items-center">
            <Checkbox
              id="vrSupportedFiles"
              checked={vrSupportedFiles}
              onChange={checked => {
                setVrSupportedFiles(Boolean(checked));
                checked ? setFileTypes(['nwd', 'rvt']) : setFileTypes([]);
              }}
            />
            <span className="text-label-sm ml-2 mr-2">Only show VR supported files</span>
            <Tooltip
              placement="bottom"
              className="max-w-[14rem] mt-2"
              delay={0}
              content={
                <>
                  <p>These file types can be shared to the Workshop to be viewed in VR:</p>
                  <ul className="list-inside list-disc mt-1">
                    <li>Revit</li>
                    <li>Navisworks</li>
                  </ul>
                </>
              }
            >
              <AlertInformationIcon size={16} className="hover:text-blue-500" />
            </Tooltip>
          </label>
        </div>

        <div className="border-t border-charcoal-100 my-6" />

        <h3 className="text-heading-5">Filters</h3>

        <div className="mt-5">
          <Label className="text-label-sm text-charcoal-700 mb-2">Types</Label>
          <MultiComboBox
            items={typesItems}
            selectedItems={selectedTypes}
            getFilteredItems={query => {
              return query ? typesItems.filter(item => includesLower(item.label, query)) : typesItems;
            }}
            onChange={value => {
              const types = value.map(item => item.value);
              setTypes(types);
            }}
            renderEmptySelection={() => <p className="text-charcoal-500">Select...</p>}
            renderSelectedItem={({ item }) => <p className="p-1 font-label-sm">{item.label}</p>}
            renderItem={({ item, removeItem }) => {
              const isActive = selectedTypes.findIndex(selectedItem => selectedItem.value === item.value) > -1;

              return (
                <div
                  className="flex items-center"
                  onClick={e => {
                    if (isActive) {
                      e.stopPropagation();
                      removeItem();
                    }
                  }}
                >
                  <div className="w-6 mr-2 flex items-center justify-center">
                    <Checkbox checked={isActive} />
                  </div>
                  <div className="p-1 font-label-sm ">
                    <p>{item.label}</p>
                  </div>
                </div>
              );
            }}
          />
        </div>

        <div className="mt-5">
          <Label className="text-label-sm text-charcoal-700 mb-2">File types</Label>
          <MultiComboBox
            items={fileTypesItems}
            selectedItems={selectedFileTypes}
            getFilteredItems={query => {
              return query ? fileTypesItems.filter(item => includesLower(item.label, query)) : fileTypesItems;
            }}
            onChange={value => {
              const fileTypes = value.map(item => item.value);
              setFileTypes(fileTypes);
            }}
            renderEmptySelection={() => <p className="text-charcoal-500">Select...</p>}
            renderSelectedItem={({ item }) => <p className="p-1 font-label-sm">{item.label}</p>}
            renderItem={({ item, removeItem }) => {
              const isActive =
                selectedFileTypes.findIndex(selectedItem => selectedItem.value === item.value) > -1;

              return (
                <div
                  className="flex items-center"
                  onClick={e => {
                    if (isActive) {
                      e.stopPropagation();
                      removeItem();
                    }
                  }}
                >
                  <div className="w-6 mr-2 flex items-center justify-center">
                    <Checkbox checked={isActive} />
                  </div>
                  <div className="p-1 font-label-sm ">
                    <p>{item.label}</p>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>

      <div className="pr-4 pl-4 pb-1">
        <div className="border-t border-charcoal-200" />
      </div>

      <footer className="flex items-center pl-4 py-2 pr-2">
        <Button
          variant="primary"
          className="ml-auto mr-2"
          disabled={!hasUpdatedSettings}
          onClick={applySettings}
          renderIcon={() => <MagnifyingGlassIcon className="mr-2" />}
        >
          Search
        </Button>
      </footer>
    </section>
  );
}

function includesLower(value?: string | null, query = '') {
  return value && value.toLowerCase().includes(query.toLowerCase());
}

function compareObjectArrays(array1: {}[], array2: {}[]) {
  if (array1.length !== array2.length) {
    return false;
  }

  return array1.sort().toString() == array2.sort().toString();
}
