import trim from 'lodash/trim';

const SPREADSHEET_EXTENSIONS = ['dat', 'csv', 'tsv', 'sxc', 'dif'];

const IMAGE_EXTENSIONS = [
  '3dv',
  'ai',
  'amf',
  'art',
  'art',
  'ase',
  'awg',
  'blp',
  'bmp',
  'bw',
  'bw',
  'cd5',
  'cdr',
  'cgm',
  'cit',
  'cmx',
  'cpt',
  'cr2',
  'cur',
  'cut',
  'dds',
  'dib',
  'djvu',
  'dxf',
  'e2d',
  'ecw',
  'egt',
  'egt',
  'emf',
  'eps',
  'exif',
  'fs',
  'gbr',
  'gif',
  'gpl',
  'grf',
  'hdp',
  'heic',
  'heif',
  'icns',
  'ico',
  'iff',
  'iff',
  'int',
  'int',
  'inta',
  'jfif',
  'jng',
  'jp2',
  'jpeg',
  'jpg',
  'jps',
  'jxr',
  'lbm',
  'lbm',
  'liff',
  'max',
  'miff',
  'mng',
  'msp',
  'nef',
  'nitf',
  'nrrd',
  'odg',
  'ota',
  'pam',
  'pbm',
  'pc1',
  'pc2',
  'pc3',
  'pcf',
  'pct',
  'pcx',
  'pcx',
  'pdd',
  'pdn',
  'pgf',
  'pgm',
  'pi1',
  'pi2',
  'pi3',
  'pict',
  'png',
  'pnm',
  'pns',
  'ppm',
  'psb',
  'psd',
  'psp',
  'px',
  'pxm',
  'pxr',
  'qfx',
  'ras',
  'raw',
  'rgb',
  'rgb',
  'rgba',
  'rle',
  'sct',
  'sgi',
  'sgi',
  'sid',
  'stl',
  'sun',
  'svg',
  'sxd',
  'tga',
  'tga',
  'tif',
  'tiff',
  'v2d',
  'vnd',
  'vrml',
  'vtf',
  'wdp',
  'webp',
  'wmf',
  'x3d',
  'xar',
  'xbm',
  'xcf',
  'xpm',
];
export const METRIC_FILE_UNITS = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const BINARY_FILE_UNITS = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

export const VR_SUPPORTED_FILES = ['rvt', 'nwc', 'nwd'];

export const WEB_SUPPORTED_FILES = [
  'image', // all images get dropped into this bucket
  '3dm',
  '3ds',
  'a',
  'asm',
  'axm',
  'brd',
  'catpart',
  'catproduct',
  'cgr',
  'collaboration',
  'dae',
  'ddx',
  'ddz',
  'dgk',
  'dgn',
  'dlv3',
  'dmt',
  'dwf',
  'dwfx',
  'dwg',
  'dwt',
  'dxf',
  'emodel',
  'exp',
  'f3d',
  'fbrd',
  'fbx',
  'fsch',
  'g',
  'gbxml',
  'glb',
  'gltf',
  'iam',
  'idw',
  'ifc',
  'ige',
  'iges',
  'igs',
  'ipt',
  'iwm',
  'jt',
  'max',
  'model',
  'mpf',
  'msr',
  'neu',
  'nwc',
  'nwd',
  'obj',
  'osb',
  'par',
  'pdf',
  'pmlprj',
  'pmlprjz',
  'prt',
  'psm',
  'psmodel',
  'rvt',
  'sab',
  'sat',
  'sch',
  'session',
  'skp',
  'sldasm',
  'sldprt',
  'smb',
  'smt',
  'ste',
  'step',
  'stl',
  'stla',
  'stlb',
  'stp',
  'stpz',
  'vpb',
  'vue',
  'wire',
  'x_b',
  'x_t',
  'xas',
  'xpr',
];

export const FileUtils = {
  supportedInLMV(type = '', opts?: { enableAllFileTypes?: boolean }) {
    if (opts?.enableAllFileTypes) return true;
    return WEB_SUPPORTED_FILES.indexOf(type.toLowerCase()) > -1;
  },

  supportedInVR(type = '', opts?: { enableAllFileTypes?: boolean; enableIfc?: boolean }) {
    if (opts?.enableAllFileTypes) return true;
    return (
      (opts?.enableIfc ? VR_SUPPORTED_FILES.concat('ifc') : VR_SUPPORTED_FILES).indexOf(type.toLowerCase()) > -1
    );
  },

  getFileType(file: { type: 'folders' | 'items'; name: string; fileType?: string | null | undefined }) {
    let fileType = file.fileType;

    if (!fileType) {
      const split = file.name.split('.');
      fileType = split[split.length - 1];
    }

    if (fileType === 'folders') return 'folder';
    if (fileType === 'txt') return 'textfile';
    if (fileType && SPREADSHEET_EXTENSIONS.includes(fileType)) return 'xls';
    if (fileType && IMAGE_EXTENSIONS.includes(fileType)) return 'image';
    return fileType ? fileType.toLowerCase() : 'file';
  },

  toHumanFileSize(bytes: number, isMetric = true, decimalPlaces = 1) {
    const thresh = isMetric ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = isMetric ? METRIC_FILE_UNITS : BINARY_FILE_UNITS;
    let u = -1;
    const r = 10 ** decimalPlaces;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(decimalPlaces) + ' ' + units[u];
  },

  truncateFilenameFromMiddle(
    name: string,
    lengthLimit: number,
    endCharsLimit: number,
    middleString: string = '...'
  ) {
    if (name.length <= lengthLimit) {
      return name;
    }
    return (
      name.substring(0, lengthLimit - endCharsLimit) +
      middleString +
      name.substring(name.length - endCharsLimit)
    );
  },

  validateFilename(name: string) {
    let error = '';

    if (FileUtils.isStringTooLong(name)) {
      error = 'Filename is too long.';
    }
    if (FileUtils.hasSpecialCharacter(name)) {
      error = 'Filename contains special characters.';
    }
    if (FileUtils.isEndWithPeriod(name)) {
      error = 'Filename ends with a period.';
    }
    if (FileUtils.isReservedName(name)) {
      error = 'Filename uses reserved keywords.';
    }

    return { error };
  },

  isReservedName(name: string) {
    return trim(name).match(/^(CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])(\.[^.]*)?$/i);
  },

  isEndWithPeriod(str: string) {
    return trim(str).match(/([ .])$/);
  },

  hasSpecialCharacter(str: string) {
    return trim(str).match(/[<>:"/\\\\|?*`]/g);
  },

  isStringTooLong(str: string, length = 255) {
    return trim(str).length > length;
  },
};
