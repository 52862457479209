import ProgressRing from '@adsk/alloy-react-progress-ring';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { useRef, useEffect, useMemo } from 'react';
import { useProxy } from 'valtio/utils';
import {
  CollaborationExtensionState,
  useCollaborationExtension,
  type ExtensionLoadingState,
} from '~/shared/hooks/use-collaboration-extension';
import { FluidErrorState } from './components/fluid-error-state';
import { useCustomToolbarEffect } from './useCustomToolbarEffect';
import { useWorkshopXRViewer } from './useWorkshopXRViewer';

interface Props {
  versionId: string;
  isCollaborativeWebViewer?: boolean;
  workshopId?: string;
  onCollabExtStateChange?: (state: ExtensionLoadingState) => void;
}

export function LargeModelViewer(props: Props) {
  const $env = Oasis.Env.useStore();
  const $debug = Oasis.Debug.useStore();
  const $collab = useProxy(CollaborationExtensionState);
  const isCollaborativeWebViewer = props.isCollaborativeWebViewer || $debug.isWebViewer;
  const ref = useRef<HTMLDivElement>(null);

  const viewerOptions = useMemo(
    () => ({
      isCollaborativeWebViewer,
      versionId: props.versionId,
      ref,
    }),
    [isCollaborativeWebViewer, props.versionId, ref]
  );

  const collaborationOptions = useMemo(
    () => ({
      versionId: props.versionId,
      isCollaborativeWebViewer,
      onStateChange: props.onCollabExtStateChange,
    }),
    [props.versionId, isCollaborativeWebViewer, props.onCollabExtStateChange]
  );

  const { connectionStatus, shouldLoadExtension } = useCollaborationExtension(collaborationOptions);
  const { viewer, viewerCreated } = useWorkshopXRViewer(viewerOptions);

  useCustomToolbarEffect(viewerCreated, viewer.current, isCollaborativeWebViewer);

  // This only does something if useLMVForProperties is true - checking for the flag here would complicate the code unnecessarily
  const globalViewer = (window as any).LMVCPPBridge?.viewer;
  useEffect(() => {
    if (!globalViewer) {
      return;
    }
    if (ref.current && globalViewer.container) {
      ref.current.appendChild(globalViewer.container);
    }

    // Cleanup function to remove the element when the component unmounts
    return () => {
      globalViewer?.container?.parentNode.removeChild(globalViewer.container);
    };
  });

  return (
    <div
      ref={ref}
      className={`${clsx('relative flex flex-1 overflow-hidden -z-0', $collab.status === 'LOADING' || (connectionStatus === 'CONNECTING' && 'is-loading'))}`}
    >
      {shouldLoadExtension || $env.isVr ? (
        $collab.status === 'FAILED' || connectionStatus === 'ERROR' ? (
          <div className="absolute bg-white inset-0 z-10 flex items-center justify-center">
            <FluidErrorState />
          </div>
        ) : (
          <div
            className={clsx(
              'flex-1 items-center justify-center flex-col flex w-full z-50 absolute inset-0 bg-white',
              $collab.status !== 'LOADED' || connectionStatus !== 'CONNECTED' ? 'visible' : 'hidden'
            )}
          >
            <>
              <ProgressRing size="large" />
              <p className="text-body-lg mt-5">Syncing. This may take few seconds...</p>
              <div />
            </>
          </div>
        )
      ) : null}
    </div>
  );
}
