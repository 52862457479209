import Button from '@adsk/alloy-react-button/es/Button';
import Modal from '@adsk/alloy-react-modal/es/Modal';
import { Oasis } from '@oasis/sdk';
import { useEffect, useState } from 'react';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { Mutations } from '~/shared/hooks/mutations';
import { useModal } from '~/shared/hooks/use-modal';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import { desktopDeviceIlloUrl } from '~/shared/utils/const.asset-urls';
import { tryDeeplink } from '~/shared/utils/utils.network';

type UI = 'OPTIMISTIC' | 'FAILED' | 'UNSUPPORTED';

interface Props {
  projectId: string;
}

export function OpenWorkshopOnDesktopModal({ projectId }: Props) {
  const $env = Oasis.Env.useStore();
  const branding = useTemporaryBranding();
  const modal = useModal('open-workshop-desktop');

  const [ui, setUI] = useState<UI>('OPTIMISTIC');
  const openWorkshop = Mutations.Workshops.useOpenWorkshopOnDevice().mutate;

  const id = modal.params.workshopId;
  const name = modal.params.workshopName;

  useEffect(() => {
    if (!modal.isOpen) {
      setUI('OPTIMISTIC');
      return;
    }

    (async () => {
      if (!id) {
        return NotificationManager.push({
          status: 'warning',
          content: 'Unable to determine workshop destination.',
        });
      }

      if (Oasis.Env.store.isWeb) {
        // Attempts to open the app via deeplink. If it fails to (or if it times out)
        // we change the modal to prompt them to download the app. If it works after a delay
        // oh well, they can just close the modal and go about their day.
        await tryDeeplink({
          protocol: Oasis.Env.store.deeplinkProtocol,
          uri: `goto/projects/${projectId}/workshops/${id}?workshopName=${name}&launchViewer=true`,
          onError: () => setUI('FAILED'),
          onUnsupported: () => setUI('UNSUPPORTED'),
        });
      }

      openWorkshop({ id, device: 'desktop' });
    })();
  }, [modal.isOpen, projectId, id, name, openWorkshop]);

  return (
    // @ts-expect-error
    <Modal open={modal.isOpen} className="!h-auto" width={528}>
      {$env.isDesktop ? (
        <>
          <Modal.Header>
            <h2>Opening workshop</h2>
            <Modal.Close showEscapeLabel={false} onClick={() => modal.close()} />
          </Modal.Header>
          <Modal.Body className="!pt-4 !pb-10 text-body-sm space-y-3 text-center">
            <div className="flex flex-col items-center justify-center text-center">
              <img src={desktopDeviceIlloUrl} alt="Desktop device illustartion" className="ml-5" />
              <p className="text-charcoal-700 px-10">
                The workshop has been sent to the {branding.productName} Desktop app.
              </p>
            </div>
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Header>
            <h2 className="truncate">
              {ui !== 'OPTIMISTIC' ? `Download ${branding.productName}` : 'Open on desktop'}
            </h2>
            <Modal.Close showEscapeLabel={false} onClick={() => modal.close()} />
          </Modal.Header>
          <Modal.Body className="!pt-6 !pb-10">
            <_Body ui={ui} />
          </Modal.Body>
          <Modal.Footer className="flex gap-1">
            <_Footer ui={ui} />
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}

function _Body(props: { ui: UI }) {
  const branding = useTemporaryBranding();

  switch (props.ui) {
    case 'OPTIMISTIC':
      return <p>You may be prompted by your browser to allow access to open the&nbsp;application.</p>;

    case 'FAILED':
      return (
        <p>
          To enter this workshop on desktop, you&apos;ll need to download {branding.productName} for Windows.
        </p>
      );

    case 'UNSUPPORTED':
      return (
        <p>
          It appears your browser does not support deeplinks. You can download and navigate within the viewer.
        </p>
      );

    default:
      props.ui satisfies never;
  }
}

function _Footer(props: { ui: UI }) {
  const modal = useModal('open-workshop-desktop');

  async function download() {
    const releaseMeta = await Oasis.Releases.getReleaseMeta({
      version: 'latest',
      app: 'WorkshopXR_Launcher',
      platform: 'windows-x86_64',
      channel: Oasis.Env.store.releaseChannel,
    });

    if (!releaseMeta.ok || typeof releaseMeta.value === 'string') {
      return NotificationManager.push({
        status: 'error',
        content: 'Failed to determine download location.',
      });
    }

    window.location.href = releaseMeta.value.url;
  }

  switch (props.ui) {
    case 'OPTIMISTIC':
      return (
        <>
          <Button variant="tertiary" onClick={() => modal.close()}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => modal.close()}>
            Done
          </Button>
        </>
      );

    case 'FAILED':
      return (
        <>
          <Button variant="tertiary" onClick={() => modal.close()}>
            Cancel
          </Button>
          <Button variant="primary" onClick={download}>
            Download
          </Button>
        </>
      );

    case 'UNSUPPORTED':
      return (
        <>
          <Button variant="tertiary" onClick={() => modal.close()}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => modal.close()}>
            OK
          </Button>
        </>
      );

    default:
      props.ui satisfies never;
  }
}
