import { Oasis } from '@oasis/sdk';
import { useEffect } from 'react';

let timeout: NodeJS.Timeout;

export function useVrInputEvents() {
  const $env = Oasis.Env.useStore();

  useEffect(() => {
    if ($env.isVr) {
      document.body.addEventListener('focusin', _handleFocusIn);
      return () => document.body.removeEventListener('focusin', _handleFocusIn);
    }
  }, [$env.isVr]);
}

function _handleFocusIn(e: Event) {
  if (!e.target) return;

  if (timeout) {
    clearTimeout(timeout);
  }

  e.target.addEventListener('blur', _handleBlur);

  if (_isKeyboardInput(e)) {
    Oasis.Dcv.emitInputEvent('input', 'focus');
  }

  if (e.target instanceof HTMLTextAreaElement) {
    Oasis.Dcv.emitInputEvent('textarea', 'focus');
  }
}

function _handleBlur(e: Event) {
  if (!e.target) return;

  e.target.removeEventListener('blur', _handleBlur);

  timeout = setTimeout(() => {
    if (
      document.activeElement instanceof HTMLInputElement ||
      document.activeElement instanceof HTMLTextAreaElement
    ) {
      return;
    }

    if (_isKeyboardInput(e)) {
      Oasis.Dcv.emitInputEvent('input', 'blur');
    }

    if (e.target instanceof HTMLTextAreaElement) {
      Oasis.Dcv.emitInputEvent('textarea', 'blur');
    }
  }, 100);
}

const KEYBOARD_INPUT_TYPES = [
  'date',
  'datetime-local',
  'email',
  'month',
  'number',
  'password',
  'search',
  'tel',
  'text',
  'time',
  'url',
  'week',
];

function _isKeyboardInput(e: Event) {
  return e.target instanceof HTMLInputElement && KEYBOARD_INPUT_TYPES.includes(e.target.type);
}
