// This button lives outside of the LMV, but has identical look and functionality.

import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { MicIcon } from '~/shared/components/icons/mic';
import { MicOffIcon } from '~/shared/components/icons/mic-off';

export function ExternalMuteButton() {
  const $voice = Oasis.Voice.useStore();

  return (
    <button
      className={clsx(
        'group absolute bottom-4 left-1/2 -translate-x-1/2',
        'flex bg-charcoal-900 rounded-[5px] w-[50px] h-[50px] shadow-high'
      )}
      onClick={() => Oasis.Voice.toggleMuted()}
    >
      <label
        className={clsx(
          'absolute bottom-full left-1/2 -translate-x-1/2 mb-1  p-1 rounded-[3px]',
          'bg-charcoal-900 text-white text-[11px] leading-none',
          'opacity-0 group-hover:opacity-100 pointer-events-none shadow-high'
        )}
      >
        {$voice.muted ? 'Unmute' : 'Mute'}
      </label>

      <div
        className={clsx(
          'flex items-center justify-center w-[42px] h-[42px] m-auto',
          'border border-transparent hover:border-[#00bfff] rounded-[4px]'
        )}
      >
        {$voice.muted ? (
          <MicOffIcon className="w-6 m-auto text-red-500" />
        ) : (
          <MicIcon className="w-6 m-auto text-white" />
        )}
      </div>
    </button>
  );
}
