import { useParams } from 'react-router-dom';
import { WorkshopPeopleList } from '../../components/workshop-people-list';

export function PeopleMenuVrPage() {
  const { workshopId } = useParams<{ workshopId: string }>();

  if (!workshopId) {
    throw new Error('Missing workshopId in route params');
  }

  return (
    <section className="flex flex-col flex-1 overflow-hidden">
      <header className="p-4 border-b border-charcoal-200">
        <h1 className="text-heading-2">People Menu</h1>
      </header>

      <WorkshopPeopleList workshopId={workshopId} displayMode="vr-people-menu" />
    </section>
  );
}
