import { uniqueId } from 'lodash';
import { Extension, Overlays } from '../globals';
import { Cursor, CursorProperties } from './cursor';
export class CursorsManager {
  readonly cursors: Map<string, Cursor>;
  readonly id: string;
  readonly overlays: Overlays;

  constructor(private readonly extension: Extension) {
    this.id = uniqueId();
    this.overlays = extension.viewer.overlays;
    this.overlays.addScene(this.id);
    this.cursors = new Map();
  }

  add(id: string, properties: CursorProperties, sheetId: string): void {
    if (!this.cursors.has(id)) {
      const cursor = new Cursor(id, properties, this.id, this.overlays, this.extension, sheetId);
      this.cursors.set(id, cursor);
    }
  }

  remove(id: string): void {
    const cursor = this.get(id);
    if (cursor) {
      cursor.dispose();
      this.cursors.delete(id);
    }
  }

  private get overlayScene() {
    return this.extension.viewer.impl.overlayScenes[this.id].scene;
  }

  hide(): void {
    this.overlayScene.visible = false;
    this.overlayScene.updateMatrix();
  }

  show(): void {
    this.overlayScene.visible = true;
    this.overlayScene.updateMatrix();
  }

  get(cursorId: string): Cursor | undefined {
    return this.cursors.get(cursorId);
  }

  showLaserPointers(show = true): void {
    this.cursors.forEach(cursor => cursor.showLaserPointer(show));
  }

  update(cursorId: string, data: CursorProperties, camera: THREE.Camera): boolean {
    const cursor = this.cursors.get(cursorId);
    if (cursor) {
      cursor.update(data, camera);
      return true;
    }
    console.warn(`The cursor ${cursorId} doesn't exist`);
    return false;
  }
}
