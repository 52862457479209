import { BrowserUtils } from '@oasis/utils';
import { cloneDeep } from 'lodash';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { Storage } from '../../providers/storage/storage.provider';
import { OasisSchemas, Timeout, type Device, type EnvContext } from '../../types';
import { Logger } from '../logger/logger.service';
import { ReleaseChannel } from '../releases/releases.types';

interface EnvStore {
  releaseChannel: ReleaseChannel;
  context: EnvContext; // modifies the UI based on the app/device we render it
  vrTargetDevice: Device; // when interacting with the console, which device we should send events to
  isDevMode: boolean;
  isWeb: boolean;
  isDesktop: boolean;
  isVr: boolean;
  isVrWorkshop: boolean;
  isVrHomespace: boolean;
  online: boolean;
  mqttUrl: string;
  apiUrl: string;
  lmvEnv: string;
  fluidEnv: string;
  acmNamespace: string;
  useFluid: boolean;
  gitSha: string;
  oauthUrl: string;
  metaStoreUrl: string;
  deeplinkProtocol: string;
}

let shouldTestConnection = true;
const context = (Storage.get('context') as EnvContext) ?? 'web';
const vrTargetDevice = (Storage.get('vrTargetDevice') as Device) ?? 'vr';
const isDevMode =
  Storage.get('isDevMode') === 'true' ||
  location.host.includes('local') ||
  location.host.includes('dev.oasis') ||
  location.host.includes('devstg.oasis');

const DEEPLINK_PROTOCOLS = {
  dev: 'adskwxrdev',
  devstg: 'adskwxrdevstg',
  develop: 'adskwxrdev',
  localhost: 'adskwxrdev',
  alpha: 'adskwxralpha',
  // beta: 'adskwxrbeta',
  beta: 'adskwxrpilot',
  prod: 'adskwxr',
  main: 'adskwxr',
} satisfies Record<ReleaseChannel, string>;

const INITIAL_STATE = {
  releaseChannel: 'develop',
  isDevMode,
  context,
  vrTargetDevice,
  isWeb: context === 'web',
  isDesktop: context === 'desktop',
  isVr: context.includes('vr'),
  isVrWorkshop: context === 'vr' || context === 'vr-workshop',
  isVrHomespace: context === 'vr-homespace',
  online: true,
  mqttUrl: '',
  apiUrl: '',
  lmvEnv: '',
  fluidEnv: '',
  useFluid: false,
  gitSha: 'local',
  oauthUrl: `${window.location.origin}/oauth`,
  metaStoreUrl: 'https://www.meta.com/experiences/quest/6311586865616787',
  acmNamespace: '',
  deeplinkProtocol: DEEPLINK_PROTOCOLS.develop,
} satisfies EnvStore;

export const Env = {
  store: proxy<EnvStore>(cloneDeep(INITIAL_STATE)),

  useStore() {
    return useProxy(Env.store);
  },

  resetStore() {
    Env.store = proxy<EnvStore>(cloneDeep(INITIAL_STATE));
  },

  async init(params: {
    context: EnvContext;
    apiUrl: string;
    mqttUrl: string;
    lmvEnv: string;
    fluidEnv: string;
    gitSha: string;
    acmNamespace: string;
    releaseChannel?: ReleaseChannel;
  }) {
    await Env.initContext(params.context);

    Env.store.mqttUrl = params.mqttUrl;
    Env.store.apiUrl = params.apiUrl;
    Env.store.lmvEnv = params.lmvEnv;
    Env.store.fluidEnv = params.fluidEnv;
    Env.store.gitSha = params.gitSha;

    Env.setReleaseChannel(params.releaseChannel || 'develop');
    Env.store.deeplinkProtocol = DEEPLINK_PROTOCOLS[Env.store.releaseChannel];

    window.addEventListener('online', () => (Env.store.online = true));
    window.addEventListener('offline', () => (Env.store.online = false));
    Env.store.acmNamespace = params.acmNamespace;
  },

  initContext(defaultContext: EnvContext) {
    let hasSetContext = false;
    const searchParams = new URLSearchParams(window.location.search);

    // Get the target device for VR interactions from the url.
    const vrTargetDevice = OasisSchemas.Device.safeParse(searchParams.get('vr-target'));

    if (vrTargetDevice.success) {
      Env.setVrTargetDevice(vrTargetDevice.data);
    }

    // We use 'context' now, but before it was referred to as 'sdk' and until we
    // are 100% sure the `oasis-client` doesnt use 'sdk' anymore we need to keep it.
    for (const contextKey of ['sdk', 'context']) {
      const context = OasisSchemas.EnvContext.safeParse(searchParams.get(contextKey));

      if (context.success) {
        hasSetContext = true;
        return Env.setContext(context.data === 'vr' ? 'vr-workshop' : context.data);
      }
    }

    // If we haven't set the context via the url, we will try to set it via the storage.
    if (!hasSetContext) {
      const context = OasisSchemas.EnvContext.safeParse(Storage.get('context'));

      if (context.success) {
        hasSetContext = true;
        return Env.setContext(context.data);
      }
    }

    // If we havent set it via url or persisted storage, we will set it to the default context.
    if (!hasSetContext) {
      Env.setContext(defaultContext);
    }
  },

  setReleaseChannel(channel?: ReleaseChannel) {
    const releaseChannel = channel || 'develop';

    Env.store.releaseChannel = releaseChannel;

    switch (channel) {
      case 'develop':
        Env.store.metaStoreUrl = 'https://www.meta.com/experiences/quest/5267422943337738';
        break;
      case 'alpha':
        Env.store.metaStoreUrl = 'https://www.oculus.com/experiences/quest/7765227673518756';
        break;
      case 'devstg':
        Env.store.metaStoreUrl = 'https://www.oculus.com/experiences/quest/8683224065057126';
        break;
      case 'beta':
        Env.store.metaStoreUrl = 'https://www.oculus.com/experiences/quest/6056317154383735';
        break;
      default:
        Env.store.metaStoreUrl = 'https://www.meta.com/experiences/quest/6311586865616787';
    }
  },

  setContext(context: EnvContext) {
    Env.store.context = context;
    Env.store.isWeb = context === 'web';
    Env.store.isDesktop = context === 'desktop';
    Env.store.isVr = context.includes('vr');
    Env.store.isVrWorkshop = context === 'vr' || context === 'vr-workshop';
    Env.store.isVrHomespace = context === 'vr-homespace';
    Storage.set('context', context);

    _unregisterVrClickHandler();

    if (Env.store.isVr) {
      _registerVrClickHandler();
    }
  },

  setVrTargetDevice(device: Device) {
    Env.store.vrTargetDevice = device;
    return Storage.set('vrTargetDevice', device);
  },

  setDebugContext(context: EnvContext) {
    Env.setContext(context);
    return Storage.set('context', context === 'vr' ? 'vr-workshop' : context);
  },

  setDevMode(isDevMode: boolean) {
    Env.store.isDevMode = isDevMode;
    return Storage.set('isDevMode', isDevMode);
  },

  setUseFluid(flag: boolean) {
    Env.store.useFluid = flag;
    return Storage.set('useFluid', flag);
  },

  async pollForInternetConnection() {
    if (shouldTestConnection && !Env.store.online) {
      shouldTestConnection = false;
      Logger.debug('[Env.testInternetConnection] Testing...');

      let timeout: Timeout | undefined = undefined;

      try {
        clearTimeout(timeout);
        await fetch(`${Env.store.apiUrl}/404`);
        Env.store.online = true;
        Logger.debug('[Env.testInternetConnection] Online');
      } catch (error) {
        Env.store.online = false;
        timeout = setTimeout(Env.pollForInternetConnection, 2000);
        Logger.debug('[Env.testInternetConnection] Offline');
      } finally {
        shouldTestConnection = true;
      }
    }
  },

  getLoggableStore() {
    return {
      context: Env.store.context,
      releaseChannel: Env.store.releaseChannel,
      gitSha: Env.store.gitSha,
      isDevMode: Env.store.isDevMode,
      lmvEnv: Env.store.lmvEnv,
      online: Env.store.online,
    };
  },
};

let clickedElement: HTMLElement | null = null;

function _onMouseDown(e: MouseEvent) {
  clickedElement = e.target as HTMLElement;
}

function _onMouseUp(e: MouseEvent) {
  if (clickedElement && clickedElement === e.target) {
    BrowserUtils.simulateClick(clickedElement);
  }
  clickedElement = null;
}

function _registerVrClickHandler() {
  document.addEventListener('mousedown', _onMouseDown);
  document.addEventListener('mouseup', _onMouseUp);
}

function _unregisterVrClickHandler() {
  document.removeEventListener('mousedown', _onMouseDown);
  document.removeEventListener('mouseup', _onMouseUp);
}
