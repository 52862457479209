import { Oasis } from '@oasis/sdk';
import { useState, useEffect, useRef } from 'react';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';
import { useCustomToolbarEffect } from './useCustomToolbarEffect';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { getRegion } from '@adsk/acs-sc-web-platform-api';
import LMVCPPBridge, { LMVCPPBridgeEvent } from './lmv-cpp-bridge';

declare global {
  interface Window {
    LMVCPPBridge: LMVCPPBridge;
    ue: any; // @todo define type
  }
}

export function shutdownViewer(viewer: Autodesk.Viewing.GuiViewer3D) {
  Oasis.Logger.info('<LargeModelViewer /> Shutting down viewer.');
  VIEWER_STATE.viewerCreated = false;
  VIEWER_STATE.modelLoaded = false;
  viewer.finish();
  Autodesk.Viewing.shutdown();
}


window.LMVCPPBridge = window.LMVCPPBridge || new LMVCPPBridge();

const Viewer = {
  viewerInstance: null as Autodesk.Viewing.GuiViewer3D | null,
  getViewerInstance() {
    return this.viewerInstance;
  },
};

const getViewerApi = () => {
  const region = getRegion();
  let regionSuffix = '';
  switch (region) {
    case 'EMEA':
      regionSuffix = '_EU';
      break;
    case 'APAC':
      regionSuffix = '_APAC';
      break;
    case 'AUS':
      regionSuffix = '_AUS';
      break;
  }

  return 'streamingV2' + regionSuffix;
};

const VIEWER_STATE = proxy<{
  viewerCreated: boolean;
  modelLoaded: boolean;
  getViewerInstance: () => Autodesk.Viewing.GuiViewer3D | null | undefined;
}>({
  viewerCreated: false,
  modelLoaded: false,
  getViewerInstance: () => Viewer.getViewerInstance(),
});

export const WorkshopXRViewerState = {
  useStore: () => useProxy(VIEWER_STATE),
};

function _useLoadResources(params: { noGeometryLoading: boolean; isCollaborativeWebViewer?: boolean }) {
  const [styleLoaded, setStyleLoaded] = useState(Boolean(window.Autodesk));
  const [scriptLoaded, setScriptLoaded] = useState(Boolean(window.Autodesk));
  const $env = Oasis.Env.useStore();

  useEffect(
    () => {
      if (!window.Autodesk) {
        const resourceBase = Oasis.ApsHttp.url('modelderivative/v2', 'viewers/');
        const style = document.createElement('link');
        if ($env.isVrWorkshop || $env.isVrHomespace) {
          if (params.noGeometryLoading) {
            style.href = '/css/viewerstyle.min.NOGEOMETRY.css';
          } else {
            if (!params.isCollaborativeWebViewer) {
              style.href = '/css/viewerstyle.min.webviewer.css';
            }
          }
        } else {
          style.href = `${resourceBase}/style.min.css`;
        }
        style.rel = 'stylesheet';
        style.type = 'text/css';
        style.onload = () => setStyleLoaded(true);

        const script = document.createElement('script');
        script.src = `${resourceBase}/viewer3D.min.js`;
        script.async = true;
        script.onload = () => {
          if (!$env.isVr) {
            // @ts-ignore
            Autodesk.Viewing.FeatureFlags.set('LARGE_MODEL_EXPERIENCE', true);
          }
          setScriptLoaded(true);
        };

        document.body.appendChild(style);
        document.body.appendChild(script);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return styleLoaded && scriptLoaded;
}
export const useWorkshopXRViewer = ({
  isCollaborativeWebViewer,
  versionId,
  ref,
}: {
  isCollaborativeWebViewer?: boolean;
  versionId: string;
  ref: React.RefObject<HTMLDivElement>;
}) => {
  const { modelLoaded, viewerCreated } = WorkshopXRViewerState.useStore();
  const $env = Oasis.Env.useStore();
  const $debug = Oasis.Debug.useStore();
  const [isCameraSync] = useFeatureFlags(['241015-7221-unreal-lmv-cameras-sync']);
  const useLMVForProperties = useFeatureFlags('250222-8163-use-lmv-for-properties') && $env.isVr;
  const noGeometryLoading = $env.isVr && !isCameraSync;
  const _isCollaborativeWebViewer = isCollaborativeWebViewer || $debug.isWebViewer;
  const viewer = useRef<Autodesk.Viewing.GuiViewer3D>();
  const listenerAdded = useRef(false);

  const resourcesLoaded = _useLoadResources({
    noGeometryLoading: !!noGeometryLoading,
    isCollaborativeWebViewer: _isCollaborativeWebViewer,
  });

  useCustomToolbarEffect(viewerCreated, viewer.current, _isCollaborativeWebViewer);

  useEffect(() => {
    if (!resourcesLoaded || modelLoaded) {
      return;
    }

    if (useLMVForProperties) {
      const bridge = window.LMVCPPBridge;
      if (
        viewer.current &&
        bridge.versionId &&
        bridge.versionId !== versionId
      ) {
        shutdownViewer(viewer.current);
      } else {
        viewer.current = viewer.current || bridge.viewer;
        Viewer.viewerInstance = viewer.current || null;
        VIEWER_STATE.viewerCreated = true;
        VIEWER_STATE.modelLoaded = true;
      }
    }

    async function onInitialized() {
      class WorkshopXRViewer extends Autodesk.Viewing.AggregatedView {
        constructor(container: HTMLDivElement, options: Autodesk.Viewing.AggregatedViewInitOptions) {
          super();

          this.init(container, options);
        }

        init(container: HTMLDivElement, options: any): Promise<any> {
          const viewerConfig = options.viewerConfig;
          if (!viewerConfig.featureFlags) {
            viewerConfig.featureFlags = {};
          }

          if (viewerConfig.extensionsWhiteList) {
            viewerConfig.disabledExtensions = viewerConfig.disabledExtensions || {};
          }

          return super.init(container, options);
        }
      } // @ts-ignore
      const aecProfileSettings = Object.assign({}, Autodesk.Viewing.ProfileSettings.AEC.settings);

      const getPersistentSettings = () => {
        const avp = Autodesk.Viewing.Private;
        const customPersistentSettings = [avp.Prefs3D.AMBIENT_SHADOWS];
        // @ts-ignore
        const defaultPersistentSettings = Autodesk.Viewing.ProfileSettings.AEC.persistent;
        const allPersistentSettings = customPersistentSettings.concat(defaultPersistentSettings);
        return allPersistentSettings;
      };
      // Removing Forge Viewer Loader and Logo
      // @ts-ignore
      Autodesk.Viewing.Private.DISABLE_FORGE_LOGO = true;

      // Don't present the ugly LMV alert box.
      // @ts-ignore
      Autodesk.Viewing.Private.AlertBox.displayError = () => { };
      const profileSettings = {
        name: 'AEC',
        settings: Object.assign(aecProfileSettings, {
          // ACC Viewer settings
          [Autodesk.Viewing.Private.Prefs2D.FORCE_PDF_CALIBRATION]: true,
          [Autodesk.Viewing.Private.Prefs2D.DISABLE_PDF_HIGHLIGHT]: true,
          [Autodesk.Viewing.Private.Prefs.RESTORE_SESSION_MEASUREMENTS]: false,
          [Autodesk.Viewing.Private.Prefs.WHEEL_SETS_PIVOT]: true,

          // WorkshopXR viewer settings
          [Autodesk.Viewing.Private.Prefs3D.LINE_RENDERING]: false,
          [Autodesk.Viewing.Private.Prefs3D.EDGE_RENDERING]: false,
          [Autodesk.Viewing.Private.Prefs3D.GHOSTING]: false,
          [Autodesk.Viewing.Private.Prefs.POINT_RENDERING]: false,
        }),
        persistent: getPersistentSettings(),
      };

      const workshopViewer = new WorkshopXRViewer(ref.current!, {
        viewerConfig: {
          profileSettings,
        },
      });

      viewer.current = workshopViewer.viewer as Autodesk.Viewing.GuiViewer3D;
      Viewer.viewerInstance = workshopViewer.viewer as Autodesk.Viewing.GuiViewer3D;

      const bridge = window.LMVCPPBridge;
      bridge.versionId = versionId;
      bridge.viewer = viewer.current;
      bridge.dispatchEvent(LMVCPPBridgeEvent.ViewerCreated, {});
      bridge.moveEventListenersToViewer();

      if (!viewer.current) {
        Oasis.Logger.error({
          msg: '<LargeModelViewer /> LMV failed to initialize - viewer is not yet available.',
        });
        return;
      }
      viewer.current.start();
      VIEWER_STATE.viewerCreated = true;
      window.ue?.lmvbridgesubsystem?.notifyviewercreated();

      viewer.current.setQualityLevel(false, false);

      // @ts-ignore
      const profile = new Autodesk.Viewing.Profile(profileSettings);
      // @ts-ignore
      viewer.current.setProfile(profile);

      viewer.current.loadExtension('Autodesk.ModelStructure', {}).then(ext => {
        if (!viewer.current) {
          return;
        }
        const newViewerModelStructurePanel = new Autodesk.Viewing.Extensions.ViewerModelStructurePanel(
          viewer.current,
          // @ts-ignore
          // @TODO Viewer provide inconsistent types for the model structure panel.
          'ModelBrowser',
          {
            // This will prevent hiding search bar on console.
            hideSearch: false,
            // This will disable isolation when clicking on a node.
            // These caused inconsistency between the the model structure for current user and collaborators.
            // For now we toggle visibility when clicking on a node.
            docStructureConfig: { click: { onObject: ['selectOnly'] } },
          }
        );
        const activate = () => {
          viewer.current?.setModelStructurePanel(newViewerModelStructurePanel);
          if (!isCollaborativeWebViewer) {
            newViewerModelStructurePanel.setVisible(true);

            ext.activate('');
          }
        };
        // if geometry is already loaded, activate the panel
        if (viewer.current.model) {
          console.log('Geometry already loaded: activate model structure panel');
          activate();
        } else {
          viewer.current.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, () => {
            console.log('OBJECT_TREE_CREATED_EVENT: activate model structure panel');
            activate();
          });
        }
      });

      viewer.current.setTheme(isCollaborativeWebViewer ? 'dark-theme' : 'light-theme');

      function waitForPropertyDb() {
        if (!viewer.current) {
          throw new Error('Viewer object undefined');
        }
        if ((viewer.current.model.getPropertyDb() as any).isLoadDone()) {
          Oasis.Logger.info('Property DB Load completed.');
          VIEWER_STATE.modelLoaded = true;
          if (listenerAdded.current) {
            viewer.current.removeEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, waitForPropertyDb);
            listenerAdded.current = false;
          }
        } else {
          if (!listenerAdded.current) {
            Oasis.Logger.info('Waiting for property DB to load.');
            viewer.current.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, waitForPropertyDb);
            listenerAdded.current = true;
          }
        }
      }

      let urn = `urn:${btoa(versionId)}`;
      urn = urn.replace('/', '_');

      Autodesk.Viewing.Document.load(
        urn,
        async doc => {
          if (!viewer.current) {
            Oasis.Logger.error({ doc, urn, msg: '<LargeModelViewer /> LMV failed to initialize.' });
            return null;
          }

          const root = doc.getRoot();
          const model = root.getDefaultGeometry();

          if (noGeometryLoading) {
            viewer.current.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, e => {
              const toolbar = e.target.toolbar;
              // Hide the toolbar entirely
              toolbar.setVisible(false);
            });

            // @ts-ignore
            viewer.current.addEventListener(Autodesk.Viewing.VIEW_CUBE_CREATED_EVENT, () => {
              // @ts-ignore
              const viewCubeWrapper = document.getElementsByClassName('viewcubeWrapper');
              if (
                viewCubeWrapper.length > 0 &&
                viewCubeWrapper[0] &&
                (viewCubeWrapper[0] as HTMLElement).style
              ) {
                (viewCubeWrapper[0] as HTMLElement).style.display = 'none';
              }
            });
          }

          try {
            await viewer.current.loadDocumentNode(doc, model, { skipMeshLoad: noGeometryLoading });
            waitForPropertyDb();
            Oasis.Logger.info({ msg: 'Successfully loaded' });
          } catch (error) {
            Oasis.Logger.error({
              msg: '<LargeModelViewer /> Failed to load document.',
              error,
            });
          }
        },
        errorCode => {
          Oasis.Logger.error({ msg: '<LargeModelViewer /> Error loading document: ', errorCode });
        },
        null
      );
    }
    const resourceBase = Oasis.ApsHttp.url('modelderivative/v2', 'viewers/');

    Autodesk.Viewing.Initializer(
      {
        env: Oasis.Env.store.lmvEnv, // 'AutodeskStating2' || 'AutodeskProduction2',
        api: getViewerApi(),
        language: 'en',
        productId: 'ACCViewer',
        theme: 'acs-theme',
        useADP: false,
        isAEC: true,
        enableTextSearch: false,
        disableBimWalkInfoIcon: true,
        enableHyperlinks: false, // To prevent Vector PDF loader from loading hyperlinks
        disableLayersUi: true,
        disabledExtensions: {
          // We'll control when these are loaded
          explode: true,
          propertiesPanel: true,
          modelBrowser: true,
          boxSelection: true,
        },
        navToolsConfig: { isAECCameraControls: true },
        getAccessToken: async cb => {
          const result = await Oasis.TokenManager.getAccessToken();

          if (result.ok) {
            cb?.(result.value, Oasis.TokenManager.getExpiration());
          } else {
            NotificationManager.push({
              status: 'error',
              content: 'Failed to verify session.',
            });
          }
        },
        refreshToken: async cb => {
          const result = await Oasis.TokenManager.refresh();

          if (result.ok) {
            cb?.(result.value.oxygenToken, Oasis.TokenManager.getExpiration());
          } else {
            NotificationManager.push({
              status: 'error',
              content: 'Failed to refresh session.',
            });
          }
        },
        optOutTrackingByDefault: true,
        lmvResourceRoot: resourceBase,
      },
      onInitialized
    );
  }, [
    useLMVForProperties,
    resourcesLoaded,
    modelLoaded,
    versionId,
    isCollaborativeWebViewer,
    noGeometryLoading,
    ref,
    $env.isVrWorkshop,
  ]);

  useEffect(() => {
    if (!useLMVForProperties) {
      return () => {
        if (viewer.current) {
          Oasis.Logger.info('<LargeModelViewer /> Shutting down viewer.');
          VIEWER_STATE.viewerCreated = false;
          VIEWER_STATE.modelLoaded = false;
          viewer.current.finish();
          viewer.current = undefined;
          Autodesk.Viewing.shutdown();
        }
      };
    }
  }, [useLMVForProperties]);

  return { viewer: viewer, modelLoaded, viewerCreated };
};
