import { FindIssueByIdData, UpdateIssueParams } from '@oasis/sdk';
import { DateUtils } from '@oasis/utils';
import { useMemo } from 'react';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { QueryResultItem } from '~/types';
import { IssueFieldMapper } from '../issue-field-map';
import { UpdateIssueAssignedToField } from './components/assigned-to-field';
import { UpdateIssueDateField } from './components/date-field';
import { UpdateIssueLocationField } from './components/locations-field';
import { UpdateIssuePlacementField } from './components/placement-field';
import { UpdateIssueRootCauseField } from './components/root-cause-field';
import { UpdateIssueStatusField } from './components/status-field';
import { UpdateIssueTextField } from './components/text-field';
import { UpdateIssueThumbnailField } from './components/thumbnail-field';
import { UpdateIssueTypeField } from './components/type-field';
import { UpdateIssueWatchersField } from './components/watchers-field';
import { UpdateIssueContext, UpdateIssueContextValue } from './context';
import { UpdateIssueListField } from './list-field';

/**
 * This form is a beast. I originally had `react-hook-form` setup and had a configuration
 * object for each possible issue value. That was scrapped and replace with a much more
 * verbose, but hopefully maintanable set of self-updating components.
 */

interface Props {
  issue: FindIssueByIdData;
  updateIssue(attrs: Partial<UpdateIssueParams['attrs']>): void;
}

type AttributeResultItem = QueryResultItem<typeof Queries.Issues.useListIssueAttributeDefinitions>;

export function UpdateIssueForm({ issue, updateIssue }: Props) {
  const { projectId, platform } = useProjectContext();
  const attributeDefinitions = Queries.Issues.useListIssueAttributeDefinitions({ projectId, platform });

  const attributesLookup = useMemo(() => {
    const lookup = new Map<string, AttributeResultItem>();

    if (attributeDefinitions.data?.results) {
      for (const customAttribute of attributeDefinitions.data.results) {
        lookup.set(customAttribute.id, customAttribute);
      }
    }

    return lookup;
  }, [attributeDefinitions.data]);

  const contextValue = useMemo<UpdateIssueContextValue>(
    () => ({
      permittedActions: issue.permittedActions,
      permittedAttributes: issue.permittedAttributes,
      permittedStatuses: issue.permittedStatuses,
    }),
    [issue.permittedActions, issue.permittedAttributes, issue.permittedStatuses]
  );

  function updateCustomAttribute(attributeValue: {
    attributeDefinitionId: string;
    value: string | number | null;
  }) {
    updateIssue({
      customAttributes: [attributeValue],
    });
  }

  return (
    <UpdateIssueContext.Provider value={contextValue}>
      <form className="px-4 space-y-4" key={issue.id}>
        <UpdateIssueThumbnailField
          displayId={issue.displayId}
          title={issue.title}
          snapshotUrn={issue.snapshotUrn}
        />

        <UpdateIssueTextField
          name="title"
          label="Title"
          defaultValue={issue.title}
          onSubmit={title => updateIssue({ title })}
          required
        />

        <UpdateIssueStatusField status={issue.status} onSubmit={status => updateIssue({ status })} />

        <UpdateIssueTypeField
          issueTypeId={issue.issueTypeId}
          issueSubtypeId={issue.issueSubtypeId}
          status={issue.status}
          onSubmit={ids => updateIssue(ids)}
        />

        <IssueFieldMapper
          issueSubtypeId={issue.issueSubtypeId}
          renderField={field => {
            switch (field.id) {
              case 'description':
                return (
                  <UpdateIssueTextField
                    name="description"
                    label="Description"
                    defaultValue={issue.description}
                    onSubmit={description => updateIssue({ description })}
                    textarea
                    required={field.required}
                  />
                );

              case 'assignedTo':
                return (
                  <UpdateIssueAssignedToField
                    name="assignedTo"
                    label="Assigned to"
                    assignedTo={issue.assignedTo}
                    onSubmit={value => {
                      const watchers =
                        value.type === 'user' && value.id !== null
                          ? [...issue.watchers, value.id]
                          : issue.watchers;
                      updateIssue({
                        assignedToType: value.type,
                        assignedTo: value.id,
                        ...(value.type !== null && value.id !== null && { watchers }),
                      });
                    }}
                    required={field.required}
                  />
                );

              case 'watchers':
                if (platform === 'bim360') break;

                return (
                  <UpdateIssueWatchersField
                    watchers={issue.watchers || []}
                    onSubmit={watchers => updateIssue({ watchers })}
                    required={field.required}
                  />
                );

              case 'locationDetails':
                return (
                  <UpdateIssueTextField
                    name="locationDetails"
                    label="Location Details"
                    defaultValue={issue.locationDetails}
                    onSubmit={locationDetails => updateIssue({ locationDetails })}
                    required={field.required}
                  />
                );

              case 'locationId':
                // TODO: bim locations are throwing CORS exceptions
                if (platform === 'bim360') break;

                return (
                  <UpdateIssueLocationField
                    locationId={issue.locationId}
                    onSubmit={locationId => updateIssue({ locationId })}
                    required={field.required}
                  />
                );

              case 'linkedDocuments':
                return (
                  <UpdateIssuePlacementField
                    issueId={issue.id}
                    linkedDocuments={issue.linkedDocuments}
                    onSubmit={value => value}
                    required={field.required}
                  />
                );

              case 'rootCauseId':
                return (
                  <UpdateIssueRootCauseField
                    rootCauseId={issue.rootCauseId}
                    onSubmit={rootCauseId => updateIssue({ rootCauseId })}
                    required={field.required}
                  />
                );

              case 'startDate':
                if (platform === 'bim360') break;

                return (
                  <UpdateIssueDateField
                    name="startDate"
                    label="Start date"
                    value={issue.startDate}
                    onSubmit={value =>
                      updateIssue({ startDate: value ? DateUtils.getDateAsValue(value) : null })
                    }
                    required={field.required}
                  />
                );

              case 'dueDate':
                return (
                  <UpdateIssueDateField
                    name="dueDate"
                    label="Due date"
                    value={issue.dueDate}
                    onSubmit={value => updateIssue({ dueDate: value ? DateUtils.getDateAsValue(value) : null })}
                    required={field.required}
                  />
                );

              default:
                return null;
            }
          }}
        />

        {attributesLookup.size > 0 &&
          issue.customAttributes.map(attribute => {
            const attributeDefinition = attributesLookup.get(attribute.attributeDefinitionId);

            if (!attributeDefinition) {
              return null;
            }

            return (
              <div key={attribute.attributeDefinitionId}>
                {(attribute.type === 'text' ||
                  attribute.type === 'numeric' ||
                  attribute.type === 'paragraph') && (
                  <UpdateIssueTextField
                    type={attribute.type !== 'paragraph' ? attribute.type : undefined}
                    name={attributeDefinition.title}
                    label={attributeDefinition.title}
                    defaultValue={attribute.value ? String(attribute.value) : null}
                    onSubmit={value =>
                      updateCustomAttribute({ attributeDefinitionId: attribute.attributeDefinitionId, value })
                    }
                    textarea={attribute.type === 'paragraph'}
                    customAttribute
                  />
                )}

                {attribute.type === 'list' && attributeDefinition.metadata.list && (
                  <UpdateIssueListField
                    value={attribute.value}
                    options={attributeDefinition.metadata.list.options}
                    name={attributeDefinition.title}
                    label={attributeDefinition.title}
                    onSubmit={value =>
                      updateCustomAttribute({ attributeDefinitionId: attribute.attributeDefinitionId, value })
                    }
                    customAttribute
                  />
                )}
              </div>
            );
          })}
      </form>
    </UpdateIssueContext.Provider>
  );
}
