function createHash(str: string) {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash >>> 0;
}

export function randomUUID(str = ''): string {
  return str
    ? ((Number(str) ^ (Math.random() * 16)) >> (Number(str) / 4)).toString(16)
    : `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, randomUUID);
}

export function randomString(length = 8): string {
  return randomUUID().replace('-', '').slice(0, length);
}

/**
 * compute color from user's ID hash used as hue ([0, 360)) in HLS.
 * lightness: 0.35 ([0, 1])
 * saturation: 0.95 ([0, 1])
 * based on https://en.wikipedia.org/wiki/HSL_and_HSV#HSL_to_RGB
 */
export function getUserColor(userId: string, asInt: true): number;
export function getUserColor(userId: string, asInt?: false): string;
export function getUserColor(userId: string, asInt = false): number | string {
  const h = createHash(userId);
  const H = (h % 359) / 60;
  const C = 174;
  const m = 4;
  const X = Math.round(170 * (1 - Math.abs((H % 2) - 1))) + 4;
  const i = Math.round(H) > 5 ? 5 : Math.round(H);
  const rgb = [
    [C, X, m],
    [X, C, m],
    [m, C, X],
    [m, X, C],
    [X, m, C],
    [C, m, X],
  ][i]!;
  const rgbHex = rgb.reduce((r, c) => `${r}${c.toString(16).padStart(2, '0')}`, '');
  return asInt ? parseInt(rgbHex, 16) : rgbHex;
}
