import { useEffect, useRef, useState, type ComponentProps } from 'react';
import { useWorkshopAudience } from '~/shared/hooks/use-workshop-audience';
import { WorkshopPeopleListItem } from './workshop-people-list-item';

interface Props {
  workshopId: string;
  displayMode: ComponentProps<typeof WorkshopPeopleListItem>['displayMode'];
}

export function WorkshopPeopleList({ workshopId, displayMode }: Props) {
  const $audience = useWorkshopAudience(workshopId);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const container = scrollContainer.current;

    if (!container) {
      return;
    }

    const handleScroll = () => {
      if (container.scrollTop > 12) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [scrollContainer]);

  return (
    <div ref={scrollContainer} className="flex-1 overflow-auto">
      <ul>
        {/* Current user */}
        {$audience.currentUser && (
          <WorkshopPeopleListItem
            displayMode={displayMode}
            user={$audience.currentUser}
            isCurrentUser
            showShadow={hasScrolled}
          />
        )}

        {/* Host */}
        {$audience.hostUser && <WorkshopPeopleListItem displayMode={displayMode} user={$audience.hostUser} />}

        {/* Viewers */}
        {$audience.viewingUsers.map(user => (
          <WorkshopPeopleListItem displayMode={displayMode} key={user.clientId} user={user} />
        ))}
      </ul>
    </div>
  );
}
