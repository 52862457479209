import { WorkshopXRUser } from '@oasis/fluid-interop/connection/connectionManager';
import { Oasis } from '@oasis/sdk';
import { ArrayUtils } from '@oasis/utils';
import { useCallback, useEffect } from 'react';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { useWorkshopState } from './use-workshop-state';

type AudienceProxy = {
  allUsersNonSelf: WorkshopXRUser[];
  allUsers: WorkshopXRUser[];
  viewingUsers: WorkshopXRUser[];
  currentUser: WorkshopXRUser | undefined;
  hostUser: WorkshopXRUser | undefined;
};

export const audienceProxy = proxy<AudienceProxy>({
  allUsers: [],
  viewingUsers: [],
  currentUser: undefined,
  hostUser: undefined,
  allUsersNonSelf: [],
});

const HOST_DEVICES = ['VR', 'DESKTOP'];

export const isHostUser = (user: WorkshopXRUser | undefined, hostUserId: string | undefined) => {
  const device = user?.additionalDetails.device;
  if (!device || !hostUserId) return false;

  return user?.userId === hostUserId && HOST_DEVICES.includes(device);
};

export const isWebUser = (user: WorkshopXRUser | undefined) => {
  return user?.additionalDetails.device === 'WEB';
};

export function useWorkshopAudience(workshopId?: string, filterSelf = true) {
  const $session = Oasis.Session.useStore();
  const { workshopConnection, hostUserId } = useWorkshopState({ workshopId });

  const updateUsers = useCallback(() => {
    const sessionUsers = workshopConnection?.getSessionUsers() || [];

    const nextUsers = sessionUsers.reduce(
      (acc, user) => {
        if (!user.additionalDetails.device) return acc;

        const { userId } = user;
        const { id: sessionId } = $session.user || {};
        if (userId === sessionId && isWebUser(user)) {
          acc.currentUser = user;
        } else if (isHostUser(user, hostUserId)) {
          acc.hostUser = user;
        } else {
          acc.viewingUsers.push(user);
        }
        return acc;
      },
      { viewingUsers: [], currentUser: undefined, hostUser: undefined } as {
        viewingUsers: WorkshopXRUser[];
        currentUser: WorkshopXRUser | undefined;
        hostUser: WorkshopXRUser | undefined;
      }
    );

    const allUsers = [nextUsers.currentUser, nextUsers.hostUser, ...nextUsers.viewingUsers].filter(
      ArrayUtils.truthy
    );
    audienceProxy.allUsers = allUsers;
    audienceProxy.allUsersNonSelf = allUsers.filter(user => user.deviceId !== nextUsers.currentUser?.deviceId);
    audienceProxy.viewingUsers = nextUsers.viewingUsers;
    audienceProxy.currentUser = nextUsers.currentUser;
    audienceProxy.hostUser = nextUsers.hostUser;
  }, [$session.user, hostUserId, workshopConnection]);

  useEffect(() => {
    if (!workshopConnection || workshopConnection.isClosed) {
      audienceProxy.allUsers = [];
      audienceProxy.viewingUsers = [];
      audienceProxy.currentUser = undefined;
      audienceProxy.hostUser = undefined;
      audienceProxy.allUsersNonSelf = [];
      return;
    }

    const audience = workshopConnection.audience;

    audience.on('memberAdded', updateUsers);
    audience.on('memberRemoved', updateUsers);
    audience.on('membersChanged', updateUsers);

    updateUsers();

    return () => {
      audience.off('memberAdded', updateUsers);
      audience.off('memberRemoved', updateUsers);
      audience.off('membersChanged', updateUsers);
    };
  }, [workshopConnection, filterSelf, hostUserId, $session.user?.id, updateUsers]);

  return useProxy(audienceProxy);
}
