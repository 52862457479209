import { DataBinding } from '@fluid-experimental/property-binder';
import { PROPERTY_NAMES } from './Constants';

function planesEquals(planes1 = [], planes2 = []) {
  return (
    planes1.length === planes2.length &&
    planes1.every((plane, i) => plane.clone().sub(planes2[i]).length() < 0.00001)
  );
}
export class ToolsBinding extends DataBinding {
  onExplodeChange(val) {
    const { viewer } = this.getUserData();
    this.getDataBinder().requestChangesetPostProcessing(() => {
      viewer.explode(val);
    });
  }

  onCutplanesChange(context) {
    const { viewer, store, attach, detach } = this.getUserData();
    if (context._nestedChangeSet !== undefined) {
      this.getDataBinder().requestChangesetPostProcessing(() => {
        const storePlanes = store.getCutPlanes();
        if (!planesEquals(storePlanes, viewer.getCutPlanes())) {
          detach();
          viewer.setCutPlanes(storePlanes);
          attach();
        }
      });
    }
  }

  static initialize() {
    DataBinding.registerOnValues(PROPERTY_NAMES.EXPLODE, ['insert', 'modify'], this.prototype.onExplodeChange);
    DataBinding.registerOnPath(
      PROPERTY_NAMES.CUTPLANES,
      ['insert', 'modify'],
      this.prototype.onCutplanesChange
    );
  }
}

ToolsBinding.initialize();
