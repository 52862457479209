export const ListIssuesConst = {
  StringFilterKeys: ['search'],

  NonStringFilterKeys: ['published'],

  ArrayFilterKeys: [
    'assignedTo',
    'createdBy',
    'issueTypeId',
    'issueSubtypeId',
    'locationId',
    'rootCauseId',
    'status',
  ],
} as const;
