import { IssuesSchemas, Oasis } from '@oasis/sdk';
import { Platforms } from '@oasis/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { NotificationManager } from '~/shared/components/base/notification-manager';

export const issuesMutations = {
  useCreateIssue() {
    const queryClient = useQueryClient();
    const [searchParams, setSearchParams] = useSearchParams();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Issues.createIssue>[0]) {
        const res = await Oasis.Issues.createIssue(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to create issue. Please try again.',
              errorMessages: {
                FORBIDDEN: "You don't have permission to create this issue.",
                BAD_REQUEST:
                  'Failed to update the issue. You may be missing required fields or have invalid values. Please try again or contact support for if the problem continues.',
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(data, params) {
        if (typeof data === 'object' && 'id' in data) {
          queryClient.invalidateQueries({
            queryKey: ['projects', params.projectId, 'issues'],
          });
          searchParams.set('issueId', data.id);
          setSearchParams(searchParams);

          Oasis.NetworkCommands.emitWorkshopIssueEvent({
            event: 'add',
            projectId: params.projectId,
            issueId: data.id,
          });
        }
      },
    });
  },

  useUpdateIssue() {
    const queryClient = useQueryClient();
    const [searchParams, setSearchParams] = useSearchParams();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Issues.updateIssue>[0]) {
        const res = await Oasis.Issues.updateIssue(params);

        if (!res.ok) {
          if (res.error.code === 'ERR_MESSAGE') {
            throw NotificationManager.push({
              status: 'error',
              content: res.error.message,
            });
          }

          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to update issue. Please try again.',
              errorMessages: {
                FORBIDDEN: "You don't have permission to update this issue.",
              },
            },
          });
        }

        return res.value;
      },
      onMutate(params) {
        try {
          const cached = IssuesSchemas.updateIssue.safeParse(
            queryClient.getQueryData(['projects', params.projectId, 'issues', params.issueId])
          );

          if (!cached.success) {
            return;
          }

          const optimisticValue = {
            ...cached.data,
            value: {
              ...cached.data,
              ...params.attrs,
              customAttributes: cached.data.customAttributes.map(customAttribute => {
                const changingAttribute = params.attrs.customAttributes?.find(changing => {
                  return changing.attributeDefinitionId === customAttribute.attributeDefinitionId;
                });

                if (changingAttribute) {
                  customAttribute.value = changingAttribute.value;
                }

                return customAttribute;
              }),
            },
          };

          queryClient.setQueryData(['projects', params.projectId, 'issues', params.issueId], optimisticValue);

          return { previousData: cached.data };
        } catch (error) {
          Oasis.Logger.error({ error, msg: 'Mutations.Issues.useUpdateIssue' });
        }
      },
      onSuccess(data, params) {
        queryClient.invalidateQueries({
          queryKey: ['projects', params.projectId, 'issues'],
        });

        if (params.attrs.published === false && searchParams.has('issueId')) {
          searchParams.delete('issueId');
          setSearchParams(searchParams);
        }

        Oasis.NetworkCommands.emitWorkshopIssueEvent({
          event: 'update',
          projectId: params.projectId,
          issueId: params.issueId,
        });
      },
      onSettled(_data, _context, params) {
        queryClient.invalidateQueries({
          queryKey: ['projects', params.projectId, 'issues'],
        });
      },
      onError(_data, params, context) {
        if (context?.previousData) {
          queryClient.setQueryData(
            ['projects', params.projectId, 'issues', params.issueId],
            context.previousData
          );
        }
      },
    });
  },

  useTogglePublishedIssue() {
    const queryClient = useQueryClient();
    const [searchParams, setSearchParams] = useSearchParams();

    return useMutation({
      async mutationFn(params: {
        projectId: string;
        platform: Platforms;
        issueId: string;
        published: boolean;
      }) {
        const res = await Oasis.Issues.updateIssue({
          ...params,
          attrs: { published: params.published },
        });

        if (!res.ok) {
          const verb = params.published ? 'published' : 'unpublished';

          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: `Failed to ${verb} issue.`,
              errorMessages: {
                FORBIDDEN: `You don't have permission to ${verb} this issue.`,
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(data, params) {
        queryClient.invalidateQueries({
          queryKey: ['projects', params.projectId, 'issues'],
        });

        Oasis.NetworkCommands.emitWorkshopIssueEvent({
          event: 'update',
          projectId: params.projectId,
          issueId: data.id,
        });

        // If the current user is unpublishing close the issue panel
        if (
          data.published === false &&
          searchParams.has('issueId') &&
          data.createdBy !== Oasis.Session.store.user?.id
        ) {
          searchParams.delete('issueId');
          setSearchParams(searchParams);
        }

        NotificationManager.push({
          status: 'success',
          content: `Successfully ${params.published ? 'published' : 'unpublished'} issue.`,
        });
      },
    });
  },

  useDeleteIssue() {
    const queryClient = useQueryClient();
    const [searchParams, setSearchParams] = useSearchParams();

    return useMutation({
      async mutationFn(params: Parameters<typeof Oasis.Issues.deleteIssue>[0]) {
        const res = await Oasis.Issues.deleteIssue(params);

        if (!res.ok) {
          throw NotificationManager.push({
            status: 'error',
            content: {
              errorCode: res.error.code,
              defaultMessage: 'Failed to delete issue. Please try again.',
              errorMessages: {
                FORBIDDEN: "You don't have permission to delete this issue.",
              },
            },
          });
        }

        return res.value;
      },
      onSuccess(_data, params) {
        queryClient.invalidateQueries({
          queryKey: ['projects', params.projectId, 'issues'],
        });

        if (searchParams.has('issueId')) {
          searchParams.delete('issueId');
          setSearchParams(searchParams);
        }

        NotificationManager.push({
          status: 'success',
          content: 'Successfully deleted issue.',
        });

        Oasis.NetworkCommands.emitWorkshopIssueEvent({
          event: 'del',
          projectId: params.projectId,
          issueId: params.issueId,
        });
      },
    });
  },
};
