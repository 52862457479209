import Avatar from '@adsk/alloy-react-avatar';
import { TriangleDownIcon } from '@adsk/alloy-react-icon';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { useState } from 'react';
import { Button } from '~/shared/components/base/button';
import { Popover } from '~/shared/components/base/popover';
import { ExternalLinkIcon } from '~/shared/components/icons/external-link-icon';

export function UserProfileMenu() {
  const $env = Oasis.Env.useStore();
  const $session = Oasis.Session.useStore();

  const [open, setOpen] = useState(false);

  const name = `${$session.user?.firstName} ${$session.user?.lastName}`;
  const actuallyOpen = !$env.isVrWorkshop && open;

  const profileURL =
    $env.releaseChannel === 'devstg' || $env.releaseChannel === 'alpha'
      ? 'https://profile-stg.autodesk.com/personalinfo'
      : 'https://profile.autodesk.com/personalinfo';

  return (
    <Popover open={actuallyOpen} onOpenChange={setOpen}>
      <Popover.Trigger className="h-12 flex items-center whitespace-nowrap group">
        <Avatar
          name={name}
          imageUrl={$session.user?.images?.sizeX120}
          size="small"
          className="!cursor-pointer"
        />

        {/* {name} */}

        {!$env.isVrWorkshop && (
          <div className="ml-1">
            <TriangleDownIcon
              className={clsx('w-5 h-5 opacity-75 group-hover:opacity-100', actuallyOpen && 'rotate-180')}
            />
          </div>
        )}
      </Popover.Trigger>

      <Popover.Content side="bottom" align="end" className="w-72 p-4">
        <p className="text-heading-4">{name}</p>
        <p className="text-charcoal-900">{$session.user?.email}</p>

        {!$env.isVr && (
          <p className="mt-6">
            <a
              href={profileURL}
              target="_blank"
              rel="noreferrer"
              className="flex items-center text-blue-700 hover:text-blue-500"
            >
              Autodesk profile
              <ExternalLinkIcon className="ml-2" />
            </a>
          </p>
        )}

        {!$env.isVrWorkshop && (
          <div className="flex justify-end border-t border-charcoal-200 pt-6 space-x-2 mt-6">
            <Button variant="secondary" onClick={() => Oasis.Session.logout()}>
              Sign out
            </Button>
          </div>
        )}
      </Popover.Content>
    </Popover>
  );
}
