import { Oasis } from '@oasis/sdk';
import { useEffect } from 'react';
import { MicIcon } from '~/shared/components/icons/mic';
import { MicOffIcon } from '~/shared/components/icons/mic-off';
import { addCustomControl } from '../viewer-toolbar';

function MuteButtonIcon() {
  const $voice = Oasis.Voice.useStore();

  useEffect(() => {
    const el = document.getElementById('voice-button-tooltip');

    if (el) {
      el.innerText = $voice.muted ? 'Unmute' : 'Mute';
    }
  }, [$voice.muted]);

  return $voice.muted ? <MicOffIcon className="w-6 m-auto text-red-500" /> : <MicIcon className="w-6 m-auto" />;
}
export const useCustomToolbarEffect = (
  viewerCreated: boolean,
  current: Autodesk.Viewing.GuiViewer3D | undefined,
  isCollaborativeWebViewer: boolean
) => {
  useEffect(() => {
    if (!viewerCreated || !current || !isCollaborativeWebViewer) return;

    const customToolbarCallback = () => {
      if (current) {
        addCustomControl(current, 'voice-button', 'voice', <MuteButtonIcon />, Oasis.Voice.toggleMuted);
      }
    };

    current.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, () => {
      customToolbarCallback();
    });

    if (current.toolbar) {
      customToolbarCallback();
    }
  }, [viewerCreated, current, isCollaborativeWebViewer]);
};
