import { FileUtils, Platforms, ProjectUtils } from '@oasis/utils';
import { proxy } from 'valtio';
import { proxyMap, useProxy } from 'valtio/utils';
import { Oasis } from '../..';
import { Ok } from '../../lib/result';
import { HttpUtils } from '../../lib/utils.http';
import { ApsHttp } from '../../providers/http/aps-http.provider';
import { OasisHttp } from '../../providers/http/oasis-http.provider';
import { Storage } from '../../providers/storage/storage.provider';
import { FolderContent } from '../../types';
import { FeatureFlags } from '../feature-flags/feature-flags.service';
import { ProjectsSchemas } from './projects.schemas';

const _parse = HttpUtils.createScopedParser('Projects', ProjectsSchemas);

export const Projects = {
  store: proxy({
    metaCache: proxyMap<string, { hubId: string; platform: Platforms; region: string }>(),
  }),

  useStore() {
    return useProxy(Projects.store);
  },

  persistCurrentProjectId(id: string) {
    return Storage.set('currentProjectId', id);
  },

  getCurrentProjectId() {
    return Storage.get('currentProjectId');
  },

  forgetCurrentProjectId() {
    return Storage.remove('currentProjectId');
  },

  /**
   * @name listProjects
   * Get a list of all projects for the current user.
   */
  async listProjects(params?: {
    region?: string;
    pagination?: { limit?: number | undefined; offset?: number };
  }) {
    try {
      const searchParams = new URLSearchParams();
      searchParams.set('limit', String(params?.pagination?.limit ?? 200));
      searchParams.set('offset', String(params?.pagination?.offset ?? 0));

      const headers = new Headers();
      const region = params?.region ?? Storage.get('region') ?? 'US';
      headers.set('Region', region);

      const res = await OasisHttp.get('v1/hubs/projects', { searchParams, headers }).json();

      if (typeof res !== 'object') {
        Oasis.Logger.error({ msg: '[Projects.listProjects] Unexpected response', res });
        return Ok({ results: [], pagination: { limit: 0, offset: 0, totalResults: 0 }, region });
      }

      const data = _parse('listProjects', { ...res, region });

      for (const project of data.results) {
        Projects.store.metaCache.set(project.id, {
          region,
          hubId: project.accountId,
          platform: project.platform,
        });
      }

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Projects.listProjects]');
    }
  },

  /**
   * @name listTopFolders
   * @link https://aps.autodesk.com/en/docs/data/v2/reference/http/hubs-hub_id-projects-project_id-topFolders-GET/
   */
  async listTopFolders(params: { hubId: string; projectId: string }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId, { prefix: true });
      const url = ApsHttp.path('acc', 'project/v1', `hubs/${params.hubId}/projects/${projectId}/topFolders`);

      const res = await ApsHttp.client.get(url, { searchParams: { excludeDeleted: true } }).json();
      const { data } = _parse('listTopFolders', res);

      const folders = data.map(folder => ({
        urn: folder.id,
        name: folder.attributes.name,
      }));

      const projectFilesUrn = data.find(folder => folder.attributes.name === 'Project Files')?.id;

      return Ok({
        folders,
        projectFilesUrn,
        rootFolderUrn: projectFilesUrn || folders[0]?.urn,
      });
    } catch (error) {
      return HttpUtils.handleError(error, '[Projects.listTopFolders]');
    }
  },

  /**
   * @name listFolders
   * Find a users viewable folders for a given project.
   */
  async listFolders(projectId: string) {
    try {
      projectId = ProjectUtils.formatId(projectId);
      const url = ApsHttp.path('acc', 'dm/v1', 'projects', projectId, 'folders');
      const res = await ApsHttp.client.get(url).json();
      const data = _parse('listFolders', res);

      return Ok(
        data.folders.map(folder => ({
          hasChildren: folder.has_subfolders,
          parentUrn: folder.parent_urn,
          path: folder.path,
          urn: folder.urn,
          name: folder.title,
          parents: folder.parents
            ? folder.parents.map(parent => ({
                isRoot: parent.is_root,
                name: parent.title,
                urn: parent.urn,
              }))
            : [],
        }))
      );
    } catch (error) {
      return HttpUtils.handleError(error, '[Projects.listFolders]');
    }
  },

  async findFolderById(params: { projectId: string; folderId: string }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const url = ApsHttp.path('acc', 'dm/v1', 'projects', projectId, 'folders', params.folderId);
      const res = await ApsHttp.client.get(url).json();
      const data = _parse('findFolderById', res);

      return Ok({
        id: data.attributes.urn,
        name: data.attributes.title,
        hasChildren: data.attributes.has_subfolders,
        path: data.attributes.path,
        children: data.folders.map(child => ({
          id: child.urn,
          name: child.title,
          hasChildren: child.has_subfolders,
        })),
        permissionActions: data.attributes.permission_actions,
      });
    } catch (error) {
      return HttpUtils.handleError(error, '[Projects.findFolderById]');
    }
  },

  /**
   * @name listAdminUsers
   * List a project's admin users.
   */
  async listAdminUsers(params: { projectId: string }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const res = await OasisHttp.get(`v1/hubs/projects/${projectId}/admins`).json();
      const data = _parse('listAdminUsers', res);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Projects.listAdminUsers]');
    }
  },

  /**
   * @name grantAdminAccess
   * Attempt to grant admin access.
   */
  async grantAdminAccess(params: { projectId: string; adminId: string }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const adminId = params?.adminId;
      await OasisHttp.post(`v1/hubs/projects/${projectId}/admins/${adminId}`);

      return Ok({ projectId, adminId });
    } catch (error) {
      return HttpUtils.handleError(error, '[Projects.grantAdminAccess]');
    }
  },

  /**
   * @name revokeAdminAccess
   * Attempt to revoke admin access.
   */
  async revokeAdminAccess(params: { projectId: string; adminId: string | undefined }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);
      const adminId = params?.adminId;
      await OasisHttp.delete(`v1/hubs/projects/${projectId}/admins/${adminId}`).json();

      return Ok({ projectId, adminId });
    } catch (error) {
      return HttpUtils.handleError(error, '[Projects.revokeAdminAccess]');
    }
  },

  /**
   * @name listFolderContents
   * @link https://aps.autodesk.com/en/docs/data/v2/reference/http/projects-project_id-folders-folder_id-contents-GET/
   */
  async listFolderContents(params: {
    projectId: string;
    platform: Platforms;
    folderId: string;
    filter?: {
      type?: ('folders' | 'items')[];
    };
    page?: number;
  }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId, { prefix: true });
      const searchParams = new URLSearchParams();
      searchParams.set('page', String(params.page || 0));
      searchParams.set('filter[type]', params.filter?.type ? params.filter.type.join(',') : 'folders,items');

      const res = _parse(
        'listFolderContents',
        await ApsHttp.client
          .get(`data/v1/projects/${projectId}/folders/${params.folderId}/contents`, {
            searchParams,
          })
          .json()
      );

      // Create a Map of version statuses so we aren't looping each time we want to
      // determine the `isProcessing` status as we're mapping over the folder content.
      const isProcessingLookup = new Map<string, boolean>();
      const thumbnailIdCache = new Map<string, string | undefined>();

      if (res.included) {
        for (const version of res.included) {
          isProcessingLookup.set(
            version.id,
            version.attributes.extension.data.processState !== 'PROCESSING_COMPLETE'
          );
          thumbnailIdCache.set(version.id, version.relationships.thumbnails?.data.id);
        }
      }

      const [enableAllFileTypes, enableIfc] = await Promise.all([
        FeatureFlags.getFlagValueAsync<boolean>('231128-4667-enable-all-file-types'),
        FeatureFlags.getFlagValueAsync<boolean>('241209-7730-ifc-support'),
      ]);

      // Map over the response to make our folder content a little easier to work with.
      const contents: FolderContent[] = res.data.map(content => {
        const name = content.attributes.displayName;
        const storageSize = res.included?.find(item => {
          return Boolean(
            content.type === 'items' &&
              content.id.match(/.*:(.*)/)?.[1] === item.id.match(/vf\.(.*?)\?version/)?.[1]
          );
        })?.attributes.storageSize;

        const type = content.type;

        let fileType: string | null = 'folders';
        let latestVersionId = '';

        if (content.type === 'items') {
          fileType = content.attributes.fileType;
          latestVersionId = content.relationships.tip.data.id;
        }

        fileType = FileUtils.getFileType({ name, type, fileType });

        return {
          id: content.id,
          latestVersionId,
          name,
          type,
          fileType,
          thumbnailId: thumbnailIdCache.get(latestVersionId),
          updatedAt: content.attributes.lastModifiedTime,
          updatedBy: content.attributes.lastModifiedUserName,
          updatedById: content.attributes.lastModifiedUserId,
          isProcessing: isProcessingLookup.get(latestVersionId) || false,
          supportedInLMV:
            content.type === 'folders' ||
            (content.type === 'items' && FileUtils.supportedInLMV(fileType, { enableAllFileTypes })),
          supportedInVR:
            content.type === 'folders' ||
            (content.type === 'items' && FileUtils.supportedInVR(fileType, { enableAllFileTypes, enableIfc })),
          storageSize,
        };
      });

      return Ok(contents);
    } catch (error) {
      return HttpUtils.handleError(error, '[Projects.listFolderContents]');
    }
  },

  /**
   * @name listProjectUsers
   * List the users that belong to a project.
   */
  async listProjectUsers(params: {
    projectId: string;
    filter?: {
      query?: string;
      limit?: number;
      offset?: number;
      userIds?: string[];
    };
  }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const searchParams = new URLSearchParams();
      searchParams.set('sort', 'name asc');
      searchParams.set('limit', String(params.filter?.limit || 9999));
      searchParams.set('offset', String(params.filter?.offset || 0));

      if (params.filter?.query) {
        searchParams.set('orFilters', 'name,email');
        searchParams.set('filter[name]', params.filter.query);
        searchParams.set('filter[email]', params.filter.query);
      }

      if (params.filter?.userIds && params.filter.userIds.length) {
        searchParams.set('filter[autodeskId]', params.filter.userIds.join(','));
      }

      const res = await ApsHttp.client
        .get(`construction/admin/v1/projects/${projectId}/users`, { searchParams })
        .json();
      const data = _parse('listProjectUsers', res);

      return Ok({
        pagination: data.pagination,
        results: data.results.filter(user => Boolean(user.autodeskId)),
      });
    } catch (error) {
      return HttpUtils.handleError(error, '[Projects.listProjectUsers]');
    }
  },

  /**
   * @name listLocations
   * List a project's locations. Currently projects can only have a `default` treeId.
   * @link https://aps.autodesk.com/en/docs/acc/v1/reference/http/locations-nodes-GET/
   */
  async listLocations(params: {
    projectId: string;
    platform: Platforms;
    pagination?: {
      limit?: number;
      offset?: number;
    };
  }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const searchParams = new URLSearchParams();
      searchParams.set('limit', String(params.pagination?.limit || 10_000));
      searchParams.set('offset', String(params.pagination?.limit || 0));

      const url =
        params.platform === 'acc'
          ? `construction/locations/v2/projects/${projectId}/trees/default/nodes`
          : `bim360/locations/v2/containers/${projectId}/trees/default/nodes`;

      const res = await ApsHttp.client.get(url, { searchParams }).json();
      const data = _parse('listLocations', res);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Projects.listLocations]');
    }
  },

  async listRoles(params: {
    projectId: string;
    pagination?: {
      limit?: number;
      offset?: number;
    };
  }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const searchParams = new URLSearchParams();
      searchParams.set('limit', String(params.pagination?.limit || 200));
      searchParams.set('offset', String(params.pagination?.limit || 0));
      searchParams.set('sort', 'name');

      const res = await ApsHttp.client
        .get(`bim360/admin/v1/projects/${projectId}/roles`, { searchParams })
        .json();
      const data = _parse('listRoles', res);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Projects.listRoles]');
    }
  },

  async listCompanies(params: {
    projectId: string;
    pagination?: {
      limit?: number;
      offset?: number;
    };
  }) {
    try {
      const projectId = ProjectUtils.formatId(params.projectId);

      const searchParams = new URLSearchParams();
      searchParams.set('limit', String(params.pagination?.limit || 200));
      searchParams.set('offset', String(params.pagination?.limit || 0));

      const res = await ApsHttp.client
        .get(`bim360/admin/v1/projects/${projectId}/companies`, { searchParams })
        .json();
      const data = _parse('listRoles', res);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Projects.listRoles]');
    }
  },
};
