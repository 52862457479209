export function getVersionId(data: any) {
  // TODO: Cleanup legacy `murnxfrm` when VR merges distinct keys.
  const versionId =
    typeof data?.murn?.value === 'string'
      ? data.murn.value
      : typeof data?.murnxfrm?.value === 'string'
        ? data.murnxfrm?.value.split(/_(.*?)(;|$)/)[1] || ''
        : '';
  return versionId;
}
