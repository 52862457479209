import { z } from 'zod';

const PairedDevice = z.object({
  type: z.literal('PairedDevice'),
});

const AnnounceYou = z.object({
  type: z.literal('announceyou'),
});

const OpenIssue = z.object({
  type: z.literal('OpenIssue'),
  args: z.object({
    projectId: z.string(),
    id: z.string(),
  }),
});

const Navigate = z.object({
  type: z.literal('Navigate'),
  args: z.object({
    path: z.string(),
  }),
});

const JoinedWorkshop = z.object({
  type: z.literal('JoinedWorkshop'),
  args: z.object({
    id: z.string(),
  }),
});

const LeftWorkshop = z.object({
  type: z.literal('LeftWorkshop'),
  args: z.object({
    id: z.string(),
  }),
});

const ForwardToPendo = z.object({
  type: z.literal('ForwardToPendo'),
  args: z.object({
    label: z.string(),
    data: z.record(z.string(), z.any()),
  }),
});

const ForwardToSegment = z.object({
  type: z.literal('ForwardToSegment'),
  args: z.object({
    label: z.string(),
    data: z.record(z.string(), z.any()),
  }),
});

const IssueEvent = z.object({
  projectId: z.string(),
  id: z.string(),
});

const SetActiveTool = z.object({
  type: z.literal('SetActiveTool'),
  args: z.object({
    name: z.union([
      z.literal('none'),
      z.literal('measurement'),
      z.literal('create-issue'),
      z.literal('properties'),
      z.literal('annotation'),
    ]),
  }),
});

const WorkshopModelChanged = z.object({
  type: z.literal('WorkshopModelChanged'),
  args: z.object({
    id: z.string(),
    newModelURN: z.string(),
  }),
});

export const MqttIncomingPayloadUnion = z.union([
  AnnounceYou,
  PairedDevice,
  OpenIssue,
  Navigate,
  JoinedWorkshop,
  LeftWorkshop,
  IssueEvent,
  ForwardToPendo,
  ForwardToSegment,
  SetActiveTool,
  WorkshopModelChanged,
]);

export const WorkshopEvents = z.object({
  device_id: z.string(),
  AV: z.object({ volume: z.number() }).optional().nullable(),
});
