import { IssuesListItems } from '@adsk/acc-issues/es/acs-issues-ui/IssuesList/IssuesListItems';
import { IssueProvider } from '@adsk/acc-issues/es/exports/IssueProvider';
import { AlloyProvider } from '@adsk/alloy-provider';
import { XIcon } from '@adsk/alloy-react-icon';
import React, { useEffect } from 'react';
import { IssuesDetailPanel } from '~/features/issues/components/issue-panel';
import { Queries } from '~/shared/hooks/queries';
import { useCurrentIssuesData } from './useCurrentIssuesData';
import { Hooks } from '@adsk/acc-viewer-ui/es';
import { PushPinExtension } from '@adsk/acc-issues/es/acs-issues-ui/state/pushPinExtension/pushPinExtensionContext';
import { WorkshopXRViewerState } from '~/features/files/components/large-model-viewer/useWorkshopXRViewer';
import { Oasis } from '@oasis/sdk';

interface IssuesPanelProps {
  workshopId: string;
  onClose?: () => void;
  currentOpenedIssueId?: string;
  onlyRightPanel?: boolean;
}

const { useExtension } = Hooks;
const pushpinExtensionName = 'Autodesk.BIM360.Extension.PushPin';

export default function IssuesPanel({
  workshopId,
  onClose,
  currentOpenedIssueId,
  onlyRightPanel = false,
}: IssuesPanelProps) {
  const [selectedIssue, setSelectedIssue] = React.useState<string | null>(currentOpenedIssueId ?? null);
  const $viewerState = WorkshopXRViewerState.useStore();

  const activeModelVersionId = Queries.Workshops.useGetActiveModelVersionId(workshopId);
  const versionId = activeModelVersionId.data;
  const { issues, projectId } = useCurrentIssuesData({ versionId });
  const pushpinExtension = useExtension(
    $viewerState.getViewerInstance(),
    pushpinExtensionName
  ) as PushPinExtension;

  useEffect(() => {
    if (currentOpenedIssueId) {
      setSelectedIssue(currentOpenedIssueId);
      pushpinExtension?.selectOne(currentOpenedIssueId, true);
    } else {
      setSelectedIssue(null);
      pushpinExtension?.selectNone();
    }

    return () => {
      if (pushpinExtension) {
        pushpinExtension.selectNone();
      }
    };
  }, [currentOpenedIssueId, pushpinExtension]);

  /**
   * @TODO why do we have projects ids starting with 'b.'?
   * This is breaking many integrations with acc service, because it expects it without the prefix.
   */
  const accProjectId = projectId.split('.')[1];

  if (!accProjectId || !versionId) {
    return null;
  }

  return (
    <AlloyProvider projectId={accProjectId} locale="en">
      <IssueProvider projectId={accProjectId}>
        <aside
          className={'flex flex-col shadow-high bg-white z-10  w-80 h-full' + (onlyRightPanel ? ' hidden' : '')}
        >
          <header className="flex items-center justify-between h-12 px-4">
            <h3 className="text-heading-1">Issues</h3>
            <button onClick={onClose}>
              <XIcon />
            </button>
          </header>
          <div className="flex flex-1 overflow-auto ">
            <IssuesListItems
              issues={issues as any}
              selectedIssueId={selectedIssue ?? undefined}
              onSelectIssue={issue => {
                if (!issue || issue.id === undefined) {
                  Oasis.Logger.error('<IssuesPanel> onSelectIssue: issue or issue.id is missing');
                  return;
                }
                const issueId = issue.id;
                setSelectedIssue(issueId);
                if (pushpinExtension) {
                  if (issueId) {
                    pushpinExtension.selectOne(issueId, true);
                  } else {
                    pushpinExtension.selectNone();
                  }
                }
              }}
              currentViewableDetails={[
                {
                  viewableId: versionId.replace('b.', ''),
                  guid: '',
                  name: '',
                },
              ]}
            />
          </div>
        </aside>

        {selectedIssue ? (
          <>
            {selectedIssue ? (
              <aside className="fixed right-0 flex flex-col shadow-high bg-white z-10  w-80 h-full">
                <IssuesDetailPanel issueId={selectedIssue} onClose={() => setSelectedIssue(null)} />
              </aside>
            ) : null}
          </>
        ) : null}
      </IssueProvider>
    </AlloyProvider>
  );
}
