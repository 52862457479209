import { getFollowUserTool } from './followUserTool';
import { CursorsManager } from './rendering/cursorManager';

export default function () {
  // eslint-disable-next-line no-undef
  const av = Autodesk.Viewing;

  /**
   * Extension description
   *
   * The extension id is: 'Autodesk.ConcurrentCollaboration'
   *
   * @example
   *   viewer.loadExtension('Autodesk.ConcurrentCollaboration', options)
   *
   * @memberof Autodesk.Viewing.Extensions
   * @alias Autodesk.Viewing.Extensions.ConcurrentCollaboration
   * @see {@link Autodesk.Viewing.Extension} for common inherited methods.
   * @class
   */
  class ConcurrentCollaborationExtension extends av.Extension {
    /**
     * Autodesk.ConcurrentCollaborationExtension constructor
     * @constructor
     * @param {Autodesk.Viewing.Viewer3D} viewer - Viewer instance.
     * @param {Object} [options] Options for the ConcurrentCollaborationExtension
     * @param {boolean} [options.someOption=false] This is how options should be documented
     */
    constructor(viewer, options) {
      super(viewer, options);
      this.name = 'ConcurrentCollaboration';

      this._sessionID = undefined;
      this._sessionStartedCallbacks = new Set();
      this._sessionEndedCallbacks = [];
      this._updateUsersCallbacks = new Set();
      this._showLaserPointers = true;
      this._users = new Map();
      this._followedId = undefined;

      this.connectionManager = undefined;
      this.cursorsManager = new CursorsManager(this);
      // this._geometries = options.getManifest().geometries;
    }

    // Pre-defined functions ===========================================================================================

    /**
     * Called by the viewer once for the lifetime of the extension (unload() ends it)
     * Perform all initializations here.
     * The viewer sends `EXTENSION_LOADED_EVENT` after the load succeeds.
     * @returns {boolean} True if the load was successful. Optionally, the function can return a Promise to indicate success (resolve) or failure (reject).
     * @memberof Autodesk.Viewing.Extensions.ConcurrentCollaboration
     * @alias Autodesk.Viewing.Extensions.ConcurrentCollaboration#deactivate
     * @private
     */
    load() {
      super.load();

      this.followUserTool = getFollowUserTool();
      this.viewer.toolController.registerTool(this.followUserTool);

      // This function should not create any UI. That should be done inside of the onToolbarCreated method.
      return true;
    }

    /**
     * Override the unload method to perform some cleanup of operations that were done in load.
     * This function will be invoked when viewer.unloadExtension("Autodesk.ConcurrentCollaboration") is called.
     * @returns {boolean} True if the load was successful. Optionally, the function can return a Promise to indicate success (resolve) or failure (reject).
     * @memberof Autodesk.Viewing.Extensions.ConcurrentCollaboration
     * @alias Autodesk.Viewing.Extensions.ConcurrentCollaboration#deactivate
     * @private
     */
    unload() {
      super.unload();
      this.viewer.toolController.deregisterTool(this.followUserTool);
      return true;
    }

    /**
     * Override the deactivate method to disable the functionality of the extension.
     * @returns {boolean} True if the extension deactivation was successful.
     * @alias Autodesk.Viewing.Extensions.ConcurrentCollaboration#deactivate
     */
    deactivate() {
      super.deactivate();
      return true;
    }

    get myself() {
      return this.connectionManager?.getMyself()?.userId;
    }

    getSessionId() {
      return this._sessionID;
    }

    updateUsers() {
      this._updateUsersCallbacks.forEach(cb => cb(this.users));
    }

    addUser(user, update = true) {
      // We shouldn't rely on this data to exist to determine the user's color
      // Collaborators in theory could join from a different app, which doesn't store this data in the same way and then the app will crash.
      // @TODO either we move this data to the shared state or we use signals to update the color.
      if (user.additionalDetails && user.additionalDetails.hexColor) {
        this._users.set(user.userId, Object.assign(user, { rgbColor: user.additionalDetails.hexColor }));
        if (update) this.updateUsers();
      }
    }

    removeUser(user) {
      if (this._users.delete(user.userId)) {
        this.updateUsers();
        user.connections.forEach(connection => this.cursorsManager.remove(connection.id));
        this.viewer.impl.invalidate(/* needsClear */ true, /* needsRender */ false, /* overlayDirty */ true);
      }
    }

    get users() {
      return Array.from(this._users.values());
    }

    set showLaserPointers(show) {
      this._showLaserPointers = show;
      if (this.cursorsManager) {
        this.cursorsManager.showLaserPointers(show);
      }
    }

    get showLaserPointers() {
      return this._showLaserPointers;
    }

    addUpdateUsersCallback(callback) {
      this._updateUsersCallbacks.add(callback);
    }

    removeUpdateUsersCallback(callback) {
      this._updateUsersCallbacks.delete(callback);
    }

    addSessionStartedCallback(callback) {
      this._sessionStartedCallbacks.add(callback);
    }

    /*
     * @param {string} id - Some id to identify the user that  should be followed
     * @returns {Promise<void>} - A promise that resolves when the user is followed
     */
    async startFollow(id) {
      if (id && this._followedId !== id) {
        this._followedId = id;
        this.viewer.setActiveNavigationTool('followUser');
      } else {
        delete this._followedId;
        this.viewer.setActiveNavigationTool();
      }
    }

    isFollowing() {
      return !!this._followedId;
    }

    removeSessionStartedCallback(callback) {
      this._sessionStartedCallbacks.delete(callback);
    }

    get currentSheetId() {
      // const loadedGeometries = this._geometries.filter(g => g.isLoaded());
      // // TODO: this won't work with models like https://git.autodesk.com/A360/Viewer/tree/main/cypress/fixtures/test-data/MultipleFiles
      // if (loadedGeometries.length !== 1) throw new Error('unexpected number of loaded sheets');
      // return loadedGeometries[0].guid;
      return 'default';
    }
  }

  av.theExtensionManager.registerExtension(
    'Autodesk.ConcurrentCollaboration',
    ConcurrentCollaborationExtension
  );
}
