import type { Issue } from '@adsk/acc-issues/es/sdk/types';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { Subscriptions } from '~/shared/hooks/subscriptions';

export const useCurrentIssuesData = ({ versionId }: { versionId?: string | null }) => {
  const { projectId, platform } = useProjectContext();

  const fileReference = Queries.Files.useFindDocumentVersionById({
    projectId,
    documentVersionId: versionId,
  });

  const issuesQuery = projectId
    ? Queries.Issues.useListIssues({
        projectId,
        platform,
        filter: {
          linkedModelUrn: fileReference.data?.data.relationships.item.data.id || '',
        },
      })
    : null;

  Subscriptions.useIssueEvents(projectId);

  /**
   * @TODO
   * There are discrepancies between the types of the issues returned by the query (or the schema we have) and the type of the issues used by the SDK.
   * This could be due to relying on different APIs from ACC. I'ven't identified any issues with the current implementation, but it's worth investigating.
   *
   * The new issues API https://aps.autodesk.com/en/docs/acc/v1/reference/http/issues-issues-GET/
   */
  const issues = (issuesQuery?.data?.pages.flatMap(page => page.results) || []) as unknown as Issue[];

  return { issues, projectId };
};
