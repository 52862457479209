import { Oasis } from '@oasis/sdk';
import { ProjectUtils } from '@oasis/utils';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from '~/shared/components/base/button';
import { ExternalLinkIcon } from '~/shared/components/icons/external-link-icon';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { SidePanelLayout } from '~/shared/layouts/side-panel';
import { MemberDetailsPanel } from '../../components/member-details-panel';
import { MembersTable } from '../../components/members-table';

export default function MembersPage() {
  const $session = Oasis.Session.useStore();
  const $env = Oasis.Env.useStore();
  const [searchParams] = useSearchParams();
  const { projectId, platform } = useProjectContext();

  const accUsers = Queries.Workshops.useListACCUsers(projectId);
  const adminUsers = Queries.Projects.useListAdminUsers({ projectId });

  const isAccAdmin = useMemo(() => {
    return accUsers.data?.results.some(user => {
      return user.autodeskId === $session.user?.id && user.accessLevels.projectAdmin;
    });
  }, [$session.user?.id, accUsers.data?.results]);

  const isAdminUser = useMemo(() => {
    return adminUsers.data?.results?.some(user => user.autodeskId === $session.user?.id);
  }, [$session.user?.id, adminUsers.data?.results]);

  const membersUrl = useMemo(() => {
    const urlId = ProjectUtils.formatId(projectId);

    if (platform === 'acc') {
      const subdomain =
        $env.releaseChannel === 'alpha' || $env.releaseChannel === 'devstg' ? 'acc-staging' : 'acc';
      return `https://${subdomain}.autodesk.com/docs/members/projects/${urlId}`;
    }

    return '';
  }, [projectId, platform, $env.releaseChannel]);

  const memberId = searchParams.get('memberId');

  return (
    <SidePanelLayout>
      <section className="flex flex-col flex-1 overflow-hidden">
        <div className="pt-12 pb-10 short:py-6 px-5 flex items-center">
          <h1 className="text-title">Members</h1>
        </div>

        <div className="flex items-center pb-3 mb-6 mx-5 border-b border-charcoal-100 text-label-md">
          {!$env.isVr && isAccAdmin && membersUrl && (
            <p className="ml-auto">
              <Button variant="link" asChild>
                <a href={membersUrl} target="_blank" className="flex items-center" rel="noreferrer">
                  Manage members
                  <ExternalLinkIcon className="ml-2" />
                </a>
              </Button>
            </p>
          )}
        </div>

        <div className="flex-1 overflow-hidden pl-5">
          <MembersTable projectId={projectId} />
        </div>
      </section>

      <SidePanelLayout.Panel isOpen={Boolean(isAdminUser && memberId)}>
        {memberId && <MemberDetailsPanel projectId={projectId} memberId={memberId} />}
      </SidePanelLayout.Panel>
    </SidePanelLayout>
  );
}
