import {
  fetchRegisteredTemplates,
  handlePropertyDataCreation,
  IDataCreationOptions,
  IInspectorRow,
  IInspectorTableProps,
  InspectorTable,
  ModalManager,
  ModalRoot,
} from '@fluid-experimental/property-inspector-table';
import { NodeProperty } from '@fluid-experimental/property-properties';
import { PropertyProxy } from '@fluid-experimental/property-proxy';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ConnectionManager } from '../connection/connectionManager';

const handleDataCreationOptionGeneration = (
  rowData: IInspectorRow,
  nameOnly: boolean
): IDataCreationOptions => {
  if (nameOnly) {
    return { name: 'property' };
  }
  const templates = fetchRegisteredTemplates();
  return { name: 'property', options: templates };
};

const tableProps: Partial<IInspectorTableProps> = {
  columns: ['name', 'value', 'type'],
  dataCreationHandler: handlePropertyDataCreation,
  dataCreationOptionGenerationHandler: handleDataCreationOptionGeneration,
  expandColumnKey: 'name',
  width: 1000,
  height: 600,
};

export function OasisInspectorTable(props: { connection: ConnectionManager }) {
  const root = props.connection.propertyTree.root as NodeProperty;
  const proxifiedDDS = PropertyProxy.proxify(root);
  return (
    <ModalManager>
      <ModalRoot />
      <AutoSizer>
        {({ width, height }) => (
          <InspectorTable {...tableProps} width={width} height={height} data={proxifiedDDS} />
        )}
      </AutoSizer>
    </ModalManager>
  );
}
