import { ConnectionManager } from '@oasis/fluid-interop';
import { Oasis } from '@oasis/sdk';
import { useCallback, useEffect, useState } from 'react';
import { proxy } from 'valtio';
import { deepClone, useProxy } from 'valtio/utils';
import { Queries } from './queries';
import { Jsonable } from '@fluidframework/datastore-definitions';
import { z } from 'zod';

export type ConnectionStatus = 'IDLE' | 'ERROR' | 'CONNECTING' | 'CONNECTED';

const initialValues = {
  status: 'IDLE' as ConnectionStatus,
  workshopId: undefined as string | undefined,
  workshopDocumentId: undefined as string | undefined,
  connectionErrors: [] as string[],
};

export const WorkshopConnection = {
  store: proxy<{
    status: ConnectionStatus;
    workshopId: string | undefined;
    workshopDocumentId: string | undefined;
    connectionErrors: string[];
  }>(deepClone(initialValues)),

  useStore: () => useProxy(WorkshopConnection.store),

  currentConnection: undefined as ConnectionManager | undefined,

  resetStore() {
    const resetObj = deepClone(initialValues);
    Object.entries(resetObj).forEach(([key, value]) => {
      WorkshopConnection.store[key as keyof typeof initialValues] = value as any;
    });
    this.currentConnection?.dispose();
    this.currentConnection = undefined;
  },

  /**
   * Connects to a workshop using the provided workshop ID and workshop document ID.
   *
   * This method updates the connection status and manages the connection lifecycle.
   * It sets the status to 'CONNECTING' initially, and then to 'CONNECTED' once the connection is established.
   * If the connection is lost, it automatically attempts to reconnect and updates the status accordingly.
   *
   * @param workshopId - The unique identifier of the workshop to connect to.
   * @param workshopDocumentId - The unique identifier of the workshop document to connect to. If not provided,
   * it will create new one and update the workshop data model with the new document ID.
   * @throws Will throw an error if the connection attempt fails, and updates the store with the error message.
   */
  async connect(workshopId: string, workshopDocumentId?: string) {
    if (this.store.status === 'CONNECTING') {
      return;
    }

    this.store.status = 'CONNECTING';
    this.store.connectionErrors = [];
    this.store.workshopId = workshopId;
    this.store.workshopDocumentId = workshopDocumentId;
    let connectionManager = this.currentConnection;
    if (!connectionManager) {
      try {
        connectionManager = await Oasis.Fluid.connectToWorkshop(workshopId, workshopDocumentId);
        this.currentConnection = connectionManager;

        connectionManager.on('connected', () => {
          this.store.status = 'CONNECTED';
          this.store.connectionErrors = [];
        });

        // The Fluid client handles reconnection logic automatically.
        // When the connection is lost, update the status to 'CONNECTING'.
        // The client will attempt to reconnect automatically.
        connectionManager.on('disconnected', () => {
          if (connectionManager?.isClosed) {
            this.store.status = 'IDLE';
            this.store.connectionErrors = ['Connection to the workshop was closed.'];
            return;
          }
          this.store.status = 'CONNECTING';
        });
      } catch (error: any) {
        this.store.status = 'ERROR';
        this.store.connectionErrors = [error.message];
        throw error;
      }
    }

    this.store.status = 'CONNECTED';
  },
};

const WorkshopSignalsPayloadSchema = {
  'workshop:issueOpened': z.string(),
  /**
   * Unsupported signal type.
   */
  'workshop:UNSUPPORTED_TYPE': z.number(),
};

type Payloads = typeof WorkshopSignalsPayloadSchema;
type Topics = keyof Payloads;
type TopicPayload<T extends Topics> = z.infer<Payloads[T]>;

export const useWorkshopSignal = <T extends Topics = Topics>(
  topic: T,
  options: { typeValidation?: boolean; listen?: boolean; selfListen?: boolean; callback?: () => void } = {
    listen: true,
    selfListen: false,
    typeValidation: true,
  }
) => {
  type SignalPayload = TopicPayload<T>;
  const { workshopConnection } = useWorkshopState();
  const [payload, setPayload] = useState<{
    signalPayload: SignalPayload | null;
    user: any | null;
  }>({
    signalPayload: null,
    user: null,
  });
  const [pendingSignals, setPendingSignals] = useState<SignalPayload[]>([]);

  const submitSignal = useCallback(
    (payload: SignalPayload) => {
      if (!workshopConnection) {
        setPendingSignals(prev => [...prev, payload]);
        return;
      }

      workshopConnection.submitSignal(topic, payload as Jsonable);
    },
    [workshopConnection, topic]
  );

  const resetState = useCallback(() => {
    setPayload({
      signalPayload: null,
      user: null,
    });
    setPendingSignals([]);
  }, []);

  useEffect(() => {
    if (!workshopConnection || pendingSignals.length === 0) return;

    pendingSignals.forEach(signal => {
      workshopConnection.submitSignal(topic, signal as Jsonable);
    });

    setPendingSignals([]);
  }, [workshopConnection, pendingSignals, topic]);

  useEffect(() => {
    if (!workshopConnection || !options.listen) return;

    const listener = (clientId: string, local: boolean, payload: SignalPayload) => {
      if (!options?.selfListen && local) return;
      const user = workshopConnection.getSessionUser(clientId);

      if (options.typeValidation) {
        try {
          WorkshopSignalsPayloadSchema[topic].parse(payload);
        } catch (error) {
          Oasis.Logger.warn({ error, msg: 'Invalid signal payload received' });
          return;
        }
      }

      options.callback?.();
      setPayload(prev => {
        if (prev.signalPayload === payload && prev.user === user) {
          return prev;
        }
        return {
          signalPayload: payload,
          user,
        };
      });
    };

    workshopConnection.onSignal(topic, listener);

    return () => {
      setPayload(prev => {
        if (prev.signalPayload === null && prev.user === null) {
          return prev;
        }
        return { signalPayload: null, user: null };
      });
      workshopConnection.offSignal(topic, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workshopConnection, topic, options.listen, options.selfListen, options.typeValidation, options.callback]);

  return { payload, submitSignal, resetState };
};

export function useWorkshopState({
  connectFluidBasedOnContext,
  workshopId,
}: { connectFluidBasedOnContext?: boolean; workshopId?: string } = {}) {
  const $session = Oasis.Session.useStore();
  const $env = Oasis.Env.useStore();
  const $workshopConnectionState = WorkshopConnection.useStore();

  const isInWorkshop = Boolean(workshopId || $session.activeWorkshop);
  const activeWorkshopId = workshopId || $session.activeWorkshop?.id;

  const workshop = Queries.Workshops.useFindWorkshopById({ workshopId: activeWorkshopId });

  useEffect(() => {
    if ($workshopConnectionState.workshopId && activeWorkshopId !== $workshopConnectionState.workshopId) {
      WorkshopConnection.resetStore();
    }
  }, [activeWorkshopId, $workshopConnectionState.workshopId]);

  useEffect(() => {
    if (connectFluidBasedOnContext && !$env.isVr) {
      return;
    }

    if (activeWorkshopId && workshop.status === 'success' && $env.online) {
      WorkshopConnection.connect(activeWorkshopId, workshop.data.fluidState?.id);
    }
  }, [
    workshop.data,
    activeWorkshopId,
    isInWorkshop,
    workshop.status,
    connectFluidBasedOnContext,
    $env.isVr,
    $env.online,
  ]);

  return {
    workshopConnection: WorkshopConnection.currentConnection,
    activeWorkshopId,
    connectionErrors: $workshopConnectionState.connectionErrors,
    status: $workshopConnectionState.status,
    hostUserId: workshop.data?.userId,
  };
}
