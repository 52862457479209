import _ from 'lodash';
import { Viewer } from '../globals';
import { Store } from './Store';

// Can't resolve the correct types "show" | "hide" | "isolate" from Global events variable
type VisibilityChangedType = string;

/*
  A helper class to manage share and react to the visibility changes of the model nodes in the viewer.
*/
export class VisibilityManager {
  oldIsolateNone: any;
  private isAttached = false;
  constructor(
    private readonly viewer: Viewer,
    private readonly store: Store
  ) {
    this.onIsolatedChanged = this.onIsolatedChanged.bind(this);
    this.onVisibilityChanged = this.onVisibilityChanged.bind(this);
    this.onShowAll = this.onShowAll.bind(this);
    this.onHideAll = this.onHideAll.bind(this);
    this.onCameraChanged = this.onCameraChanged.bind(this);
    this.onCutPlanesChanged = this.onCutPlanesChanged.bind(this);
  }

  private attach() {
    if (this.isAttached) {
      return;
    }
    this.viewer.impl.api.addEventListener(Autodesk.Viewing.SHOW_EVENT, this.onVisibilityChanged);
    this.viewer.impl.api.addEventListener(Autodesk.Viewing.HIDE_EVENT, this.onVisibilityChanged);
    // this.viewer.impl.api.addEventListener(Autodesk.Viewing.ISOLATE_EVENT, this.onIsolatedChanged);
    this.viewer.impl.api.addEventListener(Autodesk.Viewing.SHOW_ALL_EVENT, this.onShowAll);
    // this.viewer.impl.api.addEventListener(Autodesk.Viewing.HIDE_ALL_EVENT, this.onHideAll);
    // this.viewer.impl.api.addEventListener(Autodesk.Viewing.CAMERA_TRANSITION_COMPLETED, this.onCameraChanged);
    // this.viewer.impl.api.addEventListener(Autodesk.Viewing.CUTPLANES_CHANGE_EVENT, this.onCutPlanesChanged);

    this.isAttached = true;
  }

  private detach() {
    if (!this.isAttached) {
      return;
    }
    this.viewer.impl.api.removeEventListener(Autodesk.Viewing.SHOW_EVENT, this.onVisibilityChanged);
    this.viewer.impl.api.removeEventListener(Autodesk.Viewing.HIDE_EVENT, this.onVisibilityChanged);
    // this.viewer.impl.api.removeEventListener(Autodesk.Viewing.ISOLATE_EVENT, this.onIsolatedChanged);
    this.viewer.impl.api.removeEventListener(Autodesk.Viewing.SHOW_ALL_EVENT, this.onShowAll);
    // this.viewer.impl.api.removeEventListener(Autodesk.Viewing.HIDE_ALL_EVENT, this.onHideAll);
    // this.viewer.impl.api.removeEventListener(Autodesk.Viewing.CAMERA_TRANSITION_COMPLETED, this.onCameraChanged);
    // this.viewer.impl.api.removeEventListener(Autodesk.Viewing.CUTPLANES_CHANGE_EVENT, this.onCutPlanesChanged);

    this.isAttached = false;
  }

  private onCameraChanged() {
    const { position, target, up, fov, isPerspective, aspect, orthoScale } = this.viewer.getCamera();
    this.store.signal('acc_camera', {
      position,
      target,
      up,
      fov,
      aspect,
      orthoScale,
      isPerspective,
    });
  }

  private onCutPlanesChanged() {
    const planes = this.viewer.getCutPlanes();
    const planesUnrealArray = planes.map((plane: { x: any; y: any; z: any; w: any }) => {
      return {
        X: plane.x,
        Y: plane.y,
        Z: plane.z,
        W: plane.w,
      };
    });
    console.log('Cut planes changed', planesUnrealArray);
    this.store.signal('acc_cutplanes', planesUnrealArray);
  }

  private onIsolatedChanged({ nodeIdArray }: { nodeIdArray: number[] }): void {
    this.detach();
    nodeIdArray.forEach(node => {
      this.store.isolate(node);
    });
    this.attach();
  }

  private onShowAll(): void {
    this.detach();
    const instanceTree = this.viewer.model.getInstanceTree();

    const rootNode = instanceTree.getRootId();
    this.store.pushNotificationDelayScope();
    this.store.reset();
    this.store.setNodeVisibility(rootNode, true);

    this.store.popNotificationDelayScope();
    this.store.commit();

    this.attach();
  }

  private onHideAll(): void {
    this.detach();
    this.store.pushNotificationDelayScope();
    this.store.hideAll();
    this.store.popNotificationDelayScope();
    this.store.commit();
    this.attach();
  }

  private onVisibilityChanged({
    type,
    nodeIdArray,
  }: {
    type: VisibilityChangedType;
    nodeIdArray: number[];
  }): void {
    this.detach();
    const visibility = type === Autodesk.Viewing.SHOW_EVENT;

    this.store.pushNotificationDelayScope();

    nodeIdArray.forEach(node => {
      this.store.setNodeVisibility(node, visibility);
    });
    this.store.commit();

    this.store.popNotificationDelayScope();

    this.attach();
  }

  public initialize(): void {
    const { viewer, store } = this;

    store.defineAndActivateVisibilityManagerBinding(
      (node: number, isHidden = false) => {
        const localIsHidden = !viewer.model.visibilityManager.isNodeVisible(node);
        // Toggle visibility dispatch an event which re-render the UI correctly, so we toggle the visibility only when it should be toggled.
        if (localIsHidden !== isHidden) {
          this.detach();
          viewer.model.visibilityManager.toggleVisibility(node);
          this.attach();
        }
      },
      () => {
        if ((viewer as any).impl?.layers !== undefined) {
          this.detach();
          console.info('No active workshop: Showing all layers');
          viewer.showAll();
          this.attach();
        } else {
          console.warn('Viewer object already deiniitialized.');
        }
      }
    );

    this.attach();
  }

  public destroy(): void {
    this.detach();
  }
}
