import clsx from 'clsx';
import { ComponentProps } from 'react';

export interface LabelProps extends ComponentProps<'label'> {
  required?: boolean;
}

export function Label({ required, children, ...props }: LabelProps) {
  return (
    <label
      {...props}
      className={clsx('inline-block text-label-sm font-medium text-charcoal-700', props.className)}
    >
      {children}
      {required && <span className="text-label-md text-red-500 ml-1">*</span>}
    </label>
  );
}
