export function HeadsetTracking() {
  return (
    <div className="max-w-2xl">
      <p>
        When you use a headset with Workshop XR, Autodesk collects and processes personal data, including via
        device sensors that capture your body movements, hand movements, and voice, for the purpose of allowing
        you to interact with your collaborators in the Workshop XR application. We may also use this data to
        provide you technical support and to improve our product. This personal data may also be shared with the
        headset manufacturer for these purposes. Please see the terms applicable to your headset below to
        understand how the manufacturer of your headset processes this data. For more information, please refer
        to the{' '}
        <a
          href="https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement"
          target="_blank"
          rel="noreferrer"
        >
          Autodesk Privacy Statement
        </a>
        .
      </p>

      <p>
        <a
          href="https://www.meta.com/legal/privacy-policy/?utm_source=www.meta.com&utm_medium=organicsearch"
          target="_blank"
          rel="noreferrer"
        >
          <span className="font-medium">Meta Quest</span>: Supplemental Meta Platforms Technologies Privacy
          Policy
        </a>{' '}
        and{' '}
        <a href="https://www.meta.com/legal/supplemental-terms-of-service/" target="_blank" rel="noreferrer">
          Terms of Service
        </a>
        ;{' '}
        <a href="https://wit.ai/terms" target="_blank" rel="noreferrer">
          Wit.AI Terms of Service
        </a>{' '}
        (to enable voice-to-text transcription with Meta headsets)
      </p>
    </div>
  );
}
