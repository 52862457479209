import { Signaler, SignalListener } from '@fluid-experimental/data-objects';
import { ConnectionState } from '@fluidframework/container-loader';
import { assert } from '@fluidframework/core-utils';
import { Jsonable } from '@fluidframework/datastore-definitions';
import { ContainerSchema, IFluidContainer, IMember, IServiceAudience } from '@fluidframework/fluid-static';

import {
  AWSClient,
  ContainerServices,
  DefaultTokenProvider,
  IRouterliciousAudience,
  ITokenProvider,
  PermissionContext,
} from '@concurrent-experience/routerlicious-client';

import { SharedPropertyTree } from '@fluid-experimental/property-dds';
import { ITelemetryBaseLogger } from '@fluidframework/core-interfaces';
import { EventEmitter } from 'events';
import { getUserColor, randomString, randomUUID } from '../utils/userColor';
import { getEnvironmentEndpoints } from './env';

const DEVICE_ID = randomString();
interface WorkshopXRInternalUser extends IMember {
  id: string;
  userId: string;
  deviceId: string;
  additionalDetails: {
    hexColor?: string;
    color?: number;
    userImage?: string;
    device?: 'VR' | 'WEB' | 'WEB-VR' | 'DESKTOP';
    userLastName?: string;
    userName: string;
  };
  clientId: string;
}

export type WorkshopXRUserInput = Omit<
  WorkshopXRInternalUser,
  'id' | 'connections' | 'clientId' | 'deviceId'
> & { deviceId?: string };
export type WorkshopXRUser = Omit<WorkshopXRInternalUser, 'connections'>;

type IWorkshopXRAudience = IRouterliciousAudience & IServiceAudience<WorkshopXRInternalUser>;
type IWorkshopXRServices = ContainerServices & { audience: IWorkshopXRAudience };
type IWorkshopXRContainerResponse = {
  container: IFluidContainer;
  services: IWorkshopXRServices;
};
type ContainerLoadReturnType = [IFluidContainer, string, IWorkshopXRServices];

const MAX_RECONNECT_ATTEMPTS = 5;
/**
 * The `ConnectionManager` class serves as a wrapper around the Fluid Framework client, facilitating the management of connections to Fluid containers.
 * It offers a simplified API for connecting to a Fluid container, handling connection states, and interacting with the Fluid container.
 *
 * The `ConnectionManager` extends `EventEmitter` and emits the following events:
 * - 'connected': Emitted when a connection to the Fluid container is successfully established.
 * - 'disconnected': Emitted when the connection to the Fluid container is lost.
 */
export class ConnectionManager extends EventEmitter {
  readonly ID: string = 'fluid-connection-manager';

  readonly containerSchema: ContainerSchema = {
    initialObjects: {
      propertyTree: SharedPropertyTree,
      signaler: Signaler,
    },
  };

  isConnected = false;

  public documentId: string = '';

  private _audience?: IWorkshopXRAudience;

  private _signaler?: Signaler;

  private _propertyTree?: SharedPropertyTree;

  private tokenProvider: DefaultTokenProvider | ITokenProvider;

  private _user?: WorkshopXRUserInput;

  public client: AWSClient;

  private container: IFluidContainer | undefined = undefined;

  private _isClosed: boolean = false;

  services: IWorkshopXRServices | undefined;

  public get isClosed(): boolean {
    return this._isClosed;
  }

  constructor(
    private readonly getAccessToken: () => Promise<string>,
    private readonly getUser: () => WorkshopXRUserInput | undefined = () => undefined,
    public readonly env: 'staging' | 'production' = 'staging',
    private readonly fluidLogger: ITelemetryBaseLogger = {
      send: event => {
        if (event.category === 'error') {
          console.error(`[Fluid] Error eventName: ${event.eventName}`, event);
        }
      },
    },
    private readonly providedTokenProvider?: ITokenProvider,
    private readonly providedFluidEndpoints: {
      orderer: string;
      storage: string;
    } = getEnvironmentEndpoints(env),
    private readonly tenantId: string = 'fluid',
    private readonly enableDiscovery: boolean = true
  ) {
    super();
    const fluidEndpoints = this.providedFluidEndpoints;
    this.tokenProvider =
      this.providedTokenProvider ||
      new DefaultTokenProvider({
        threeLeggedTokenCallback: getAccessToken,
        env,
        userData: this.user,
      });

    this.client = new AWSClient({
      connection: {
        tenantId: this.tenantId,
        orderer: fluidEndpoints.orderer,
        storage: fluidEndpoints.storage,
        tokenProvider: this.tokenProvider,
      },
      logger: this.fluidLogger,
      enableDiscovery: this.enableDiscovery,
    });
  }

  /*
   * Clone the current connection manager, optionally disposing the current connection.
   * @param disposeCurrent - If true, the current connection will be disposed before cloning.
   * @returns A new `ConnectionManager` instance.
   */
  public clone(disposeCurrent = true): ConnectionManager {
    if (disposeCurrent) {
      this.dispose();
    }
    return new ConnectionManager(
      this.getAccessToken,
      this.getUser,
      this.env,
      this.fluidLogger,
      this.tokenProvider,
      this.providedFluidEndpoints,
      this.tenantId,
      this.enableDiscovery
    );
  }

  get user(): WorkshopXRUserInput {
    if (!this._user) {
      const user = this.getUser();
      if (user) {
        this._user = {
          ...user,
          deviceId: user.deviceId || DEVICE_ID,
          additionalDetails: {
            ...user.additionalDetails,
            userName: user.additionalDetails.userName || 'anonymous',
          },
        };
      } else {
        this._user = {
          userId: 'anonymous',
          deviceId: randomUUID(),
          additionalDetails: {
            userName: 'anonymous',
          },
        };
      }

      const hexColor = getUserColor(this._user.userId);
      this._user.additionalDetails['hexColor'] = `#${hexColor}`;
      this._user.additionalDetails['color'] = parseInt(hexColor, 16);
    }
    return this._user;
  }

  private async createNewContainer(): Promise<ContainerLoadReturnType> {
    const { container, services } = (await this.client.createContainer(
      this.containerSchema
    )) as IWorkshopXRContainerResponse;
    this.services = services;
    this._isClosed = false;
    const id = await container.attach();
    return [container, id, services];
  }

  private async loadExistingContainer(id: string): Promise<ContainerLoadReturnType> {
    const { container, services } = (await this.client.getContainer(
      id,
      this.containerSchema
    )) as IWorkshopXRContainerResponse;
    this._isClosed = false;
    this.services = services;
    return [container, id, services];
  }

  public getMyself(): WorkshopXRUser | undefined {
    const members = this.getMembers();
    const current = this.audience.getMyself()?.currentConnection ?? '';
    return members.get(current);
  }

  private getMembers(): Map<string, WorkshopXRInternalUser> {
    const internalUsers = (this.audience as any).audience.getMembers() as Map<
      //  key is clientId unique to the connection
      string,
      {
        user: WorkshopXRInternalUser;
      }
    >;

    return new Map(
      Array.from(internalUsers.keys()).map(key => {
        return [
          key,
          {
            ...internalUsers.get(key)!.user!,
            clientId: key,
          },
        ];
      })
    );
  }

  public getSessionUsers(filterSelf = false): WorkshopXRUser[] {
    const members = this.getMembers();
    const currentClientId = this.getMyself()?.clientId;

    // We filter all users joining from console for now, because we consider them
    // as same VR user - in the future maybe we can consolidate this connection in
    // unreal side.
    return Array.from(members.values()).filter(
      user =>
        user.additionalDetails.device !== 'WEB-VR' && (filterSelf ? user.clientId !== currentClientId : true)
    );
  }

  public getSessionUser(id: string): WorkshopXRUser | undefined {
    return this.getMembers().get(id);
  }

  public async connect(documentId: string): Promise<string> {
    if (documentId) {
      this.documentId = documentId;
    } else {
      throw new Error('[Fluid.connect] documentId is required');
    }
    if (!this.isConnected) {
      const result = await this.loadExistingContainer(this.documentId);
      return await this.postConnect(result);
    } else {
      return this.documentId || documentId;
    }
  }

  public async create(permissionContext: PermissionContext): Promise<string> {
    if (this.tokenProvider instanceof DefaultTokenProvider) {
      this.tokenProvider.permissionContext = permissionContext;
    }
    const result = await this.createNewContainer();

    if (!permissionContext) {
      // This should never happen, but just in case.
      throw new Error('[Fluid], Permission context is required');
    }

    return await this.postConnect(result);
  }

  private async postConnect(result: ContainerLoadReturnType): Promise<string> {
    const [container, ID, services] = result;

    container.on('connected', () => this.onConnected());
    container.on('disconnected', () => this.onDisconnected());

    this.container = container;
    this._audience = services.audience;

    this._signaler = container.initialObjects['signaler'] as Signaler;
    this._propertyTree = container.initialObjects['propertyTree'] as SharedPropertyTree;
    (globalThis as any).NOP_PTREE = this._propertyTree as SharedPropertyTree;
    this.documentId = ID;
    this.isConnected = true;
    return this.documentId;
  }

  dispose() {
    // We should set the connection as closed to avoid reconnection attempts
    this._isClosed = true;

    // Dispose the container
    this.container?.dispose();

    // Reset the connection state
    this.isConnected = false;
  }

  private async retryReconnection(attempts: number): Promise<void> {
    if (this.isConnected || this.isClosed) {
      return;
    }
    let retries = 0;
    const maxRetries = attempts;
    const retryInterval = 1000; // Start with 1 second

    const attemptReconnect = async () => {
      const container = this.container;
      if (retries >= maxRetries) {
        return;
      }

      if (container && container.connectionState === ConnectionState.Disconnected) {
        if (container.disposed) {
          // Just to be sure, we should dispose the container before trying to reconnect
          container?.dispose();
          // If the container is disposed, we should create a new one
          await this.connect(this.documentId);
        } else if (container.connectionState === ConnectionState.Disconnected) {
          // If the container is not disposed, we should only retry to connect
          container.connect();

          this.fluidLogger.send({
            category: 'error',
            eventName: 'reconnectionAttempt',
            message: `Reconnection attempt ${retries + 1}`,
          });
        }

        retries += 1;
        // Exponential backoff
        await new Promise(resolve => setTimeout(resolve, retryInterval * Math.pow(2, retries)));
        await attemptReconnect();
      }
    };

    await attemptReconnect();
  }
  private onDisconnected() {
    console.info(`[Fluid] Disconnected from ${this.documentId}!`);
    this.isConnected = false;
    setTimeout(() => {
      if (!this.isConnected) {
        this.emit('disconnected');
        if (!this.isClosed) {
          // If the connection is closed we should not attempt to reconnect
          // as this was closed intentionally by the user.
          this.retryReconnection(MAX_RECONNECT_ATTEMPTS);
        }
      }
    }, 1000);
  }

  public disconnect() {
    this.container?.disconnect();
  }

  private onConnected() {
    console.info(`[Fluid] Connected to ${this.documentId}!`);
    this.isConnected = true;
    this.emit('connected');
  }

  get audience(): IWorkshopXRAudience {
    assert(this._audience !== undefined, 'expected audience');
    return this._audience;
  }

  private get signaler(): Signaler {
    assert(this._signaler !== undefined, 'expected signaler');
    return this._signaler;
  }

  get propertyTree(): SharedPropertyTree {
    assert(this._propertyTree !== undefined, 'expected PropertyTree');
    return this._propertyTree;
  }

  public submitSignal<T>(signalName: string, payload?: Jsonable<T>): void {
    this.signaler.submitSignal(signalName, payload);
  }

  public onSignal(signalName: string, listener: SignalListener): void {
    this.signaler.onSignal(signalName, listener);
  }

  public offSignal(signalName: string, listener: SignalListener): void {
    this.signaler.offSignal(signalName, listener);
  }
}
