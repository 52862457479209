import { Oasis } from '@oasis/sdk';
import { OasisError } from '~/shared/utils/oasis-error';
import { getVersionId } from '~/shared/utils/version-id';
import { AppHistory } from '~/shared/utils/app-history';

export async function navigateToWorkshopModelTab(
  workshopId: string,
  projectId: string,
  modelUrn: string | undefined = undefined
) {
  if (!modelUrn) {
    const result = await Oasis.Workshops.getWorkshopSettings(workshopId);
    if (!result.ok) throw new OasisError(result.error, '[Workshops.getWorkshopSettings]');
    const workshopVersionId = getVersionId(result.value);
    modelUrn = workshopVersionId;
  }
  if (projectId && modelUrn) {
    const lineageId = modelUrn.split('vf.')[1];
    const lineageVersionId = `urn:adsk.wipprod:dm.lineage:${lineageId}`;
    const url = `/projects/${projectId}/files/${lineageVersionId}`;
    console.log('Redirecting to Model Tab route: ', url);
    AppHistory.navigate(url);
  }
}
