import ProgressRing from '@adsk/alloy-react-progress-ring/es/ProgressRing';
import { RadioButton } from '@adsk/alloy-react-radio-button';
import Tooltip from '@adsk/alloy-react-tooltip';
import { FileUtils } from '@oasis/utils';
import { useIsFetching } from '@tanstack/react-query';
import clsx from 'clsx';
import type { Key } from 'react';
import { Checkbox } from '~/shared/components/forms/checkbox';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';
import { useMonitorDocProcessingState } from '~/shared/hooks/use-monitor-doc-processing-state';
import { FileTypeIcon } from '../../file-type-icon';
import { useFolderTreeContext } from '../context';
import { FileReference3dViews } from './file-reference-3d-views';

interface Props {
  key: Key;
  id: string;
  documentVersionId: string;
  name: string;
  depth: number;
  fileType?: string;
  isProcessing?: boolean;
}

export function FileReference({ fileType, ...props }: Props) {
  const context = useFolderTreeContext();
  const [enableAllFileTypes, enableIfc] = useFeatureFlags([
    '231128-4667-enable-all-file-types',
    '241209-7730-ifc-support',
  ]);

  const viewsLoading = useIsFetching({
    queryKey: ['projects', context.projectId, 'documents', props.id, 'manifest', '3d-views'],
    exact: true,
  });
  const monitoredProccessState = useMonitorDocProcessingState({
    documentVersionId: props.documentVersionId,
    isProcessingDefault: props.isProcessing,
  });
  const isSupported = context.shareableOnly
    ? FileUtils.supportedInVR(fileType, { enableAllFileTypes, enableIfc })
    : FileUtils.supportedInLMV(fileType, { enableAllFileTypes });
  const isReferencedModel = context.selection.some(selected => selected.urn === props.id);
  const isChecked = context.selection.some(selected => selected.type === 'model' && isReferencedModel);

  return (
    <li
      {...props}
      className={clsx(
        'relative inline-flex flex-col min-w-full appearance-none py-1.5 hover:bg-gray-100 group ml-[2px]',
        !isSupported && 'opacity-40'
      )}
      style={{ paddingLeft: `${props.depth * 1.75}rem` }}
      data-folder-tree-file-status={isReferencedModel ? 'active' : 'inactive'}
      aria-expanded={isReferencedModel}
    >
      <button
        type="button"
        className="min-w-full inline-flex items-center pl-8 pr-2"
        onClick={() => context.onFileSelect?.({ type: 'model', name: props.name, urn: props.id })}
        disabled={!isSupported || monitoredProccessState.isProcessing}
      >
        <div className="inline-flex items-center justify-center w-7 h-7">
          {monitoredProccessState.isProcessing ? (
            <Tooltip
              content={
                monitoredProccessState.isProcessing && `File processing: ${monitoredProccessState.progress}`
              }
              placement="left"
            >
              <ProgressRing size="xsmall" />
            </Tooltip>
          ) : (
            <>
              {context.mode === 'FILE_REFERENCE_RADIO' && <RadioButton checked={isChecked} />}
              {context.mode === 'FILE_REFERENCE_CHECKBOX' && <Checkbox checked={isChecked} />}
            </>
          )}
        </div>

        <FileTypeIcon contentType="items" fileType={fileType} className="mr-2" />

        <div className="flex-1 flex items-center">
          <p className={clsx('mr-3', monitoredProccessState.isProcessing && 'opacity-50')}>{props.name}</p>
          <div className="ml-auto w-6 h-6 flex items-center justify-center">
            {viewsLoading > 0 && <ProgressRing size="small" />}
          </div>
        </div>
      </button>

      {isReferencedModel && <FileReference3dViews documentId={props.id} />}
    </li>
  );
}
