import { LinkButton } from '@adsk/alloy-react-button';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import { Oasis } from '@oasis/sdk';
import { noop } from 'lodash';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function OauthPairingRedirectPage() {
  const $env = Oasis.Env.useStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const from = searchParams.get('from');

  useEffect(() => {
    let unlisten = noop;

    (async () => {
      const me = await Oasis.Session.me();

      if (me.ok) {
        const pairingCode = await Oasis.Session.getPairingCode();

        if (pairingCode.ok) {
          const [pathname, search] = from ? decodeURI(from).split('?') : ['/projects', ''];

          return navigate(
            {
              pathname,
              search: `?${search}&dialog=pair-headset&code=${pairingCode.value}`,
            },
            { replace: true }
          );
        }
      }

      const start = await Oasis.Bridge?.startOauthServer();
      let redirectUri: string | undefined = undefined;

      if (start && start.ok) {
        redirectUri = `http://127.0.0.1:${start.value.port}`;
      }

      const oauthUnlisten = await Oasis.Bridge?.listenForOauthRedirect(tokens => {
        if (tokens) {
          navigate(`/oauth/login?access_token=${tokens.accessToken}&refresh_token=${tokens.refreshToken}`);
        }
      });

      if (oauthUnlisten) {
        unlisten = oauthUnlisten;
      }

      await Oasis.Session.loginWithRedirect({
        redirectUri,
        destination: 'pairing-code',
        successPath: from ? decodeURIComponent(from) : undefined,
      });
    })();

    return () => unlisten?.();
  }, [navigate, from]);

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <ProgressRing size="large" />

        {$env.isDesktop ? (
          <p className="text-body-lg mt-5">Login with your browser...</p>
        ) : (
          <p className="text-body-lg mt-5">Redirecting...</p>
        )}

        <p className="text-body-sm mt-3">
          <LinkButton
            onClick={() => navigate('/login', { replace: true })}
            className="text-blue-500 font-medium"
          >
            Back
          </LinkButton>
        </p>
      </div>
    </div>
  );
}
