import { CheckmarkIcon, ChevronRightIcon, PencilIcon } from '@adsk/alloy-react-icon';
import clsx from 'clsx';
import { Key, ReactNode, useCallback, useMemo, useState } from 'react';
import { Tooltip } from '~/shared/components/base/tooltip';
import { Dropdown } from '~/shared/components/forms/dropdown';
import { InlineEditable } from '~/shared/components/forms/inline-editable';
import { Label } from '~/shared/components/forms/label';
import { useProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { useUpdateIssueContext } from '../context';

interface LocationItem {
  id: string;
  name: string;
  sublocations: LocationItem[];
}

interface Props {
  locationId: string | null;
  onSubmit(value: string): void;
  required?: boolean;
}

export function UpdateIssueLocationField(props: Props) {
  const { projectId, platform } = useProjectContext();
  const { permittedAttributes } = useUpdateIssueContext();

  const locationsQuery = Queries.Projects.useListLocations({
    projectId,
    platform,
  });

  const canEdit = permittedAttributes.includes('locationId');

  const locations = useMemo(() => {
    if (locationsQuery.data?.results) {
      const map: Record<string, LocationItem> = {};

      for (const location of locationsQuery.data.results) {
        map[location.id] = {
          id: location.id,
          name: location.name,
          sublocations: [],
        };
      }

      const root: LocationItem[] = [];

      for (const location of locationsQuery.data.results) {
        const locationWithChildren = map[location.id];

        if (!locationWithChildren) {
          continue;
        }

        if (location.parentId) {
          const parent = map[location.parentId];

          if (parent) {
            parent.sublocations.push(locationWithChildren);
          }
        } else {
          root.push(locationWithChildren);
        }
      }

      return root;
    }

    return [];
  }, [locationsQuery.data]);

  const findParentTree = useCallback(
    (id: string) => {
      if (!locationsQuery.data) {
        return [];
      }

      let parents: { id: string; name: string }[] = [];

      const parent = locationsQuery.data.results.find(location => {
        return location.id === id;
      });

      if (parent) {
        parents.push({ id: parent.id, name: parent.name });

        if (parent.parentId) {
          parents = [...findParentTree(parent.parentId), ...parents];
        }
      }

      return parents;
    },
    [locationsQuery.data]
  );

  const value = useMemo(() => {
    const location = locationsQuery.data?.results.find(location => location.id === props.locationId);
    const parents = location?.parentId ? findParentTree(location.id) : [];

    const [_rootParent, nonRootParent, ...restParents] = parents;
    const parentNodes: ReactNode[] = [];

    if (nonRootParent) {
      parentNodes.push(
        <span key={0} className="flex items-center">
          {nonRootParent?.name}
          <ChevronRightIcon size={12} className="mx-2" />
        </span>
      );
    }

    if (restParents.length) {
      parentNodes.push(
        <span key={1} className="flex items-center">
          &hellip;
          <ChevronRightIcon size={12} className="mx-2" />
        </span>
      );
    }

    return (
      <Tooltip content={restParents.length ? parents.map(parent => parent.name).join(' > ') : undefined}>
        <div className={clsx('flex items-center text-left', !location && 'text-charcoal-500')}>
          {parentNodes}
          {location?.name || 'Unspecified'}
        </div>
      </Tooltip>
    );
  }, [locationsQuery.data, props.locationId, findParentTree]);

  // All projects will have a default "Project" location that we skip over.
  const locationCount = locations.reduce((acc, next) => {
    return (acc += 1 + next.sublocations.length);
  }, 0);

  if (!locationsQuery.isLoading && locationCount === 1) {
    return null;
  }

  return (
    <div>
      <Label className="mb-2" required={props.required}>
        Location
      </Label>

      <InlineEditable canEdit={canEdit}>
        {({ isEditing, setIsEditing }) => {
          return isEditing ? (
            <Dropdown open>
              <Dropdown.Trigger className="flex items-center p-2 border border-blue-500 shadow-halo-sm shadow-blue-100 rounded">
                {value}
                <PencilIcon className="ml-3" size={16} />
              </Dropdown.Trigger>

              <Dropdown.Content
                maxHeight={220}
                onClick={e => e.stopPropagation()}
                className="flex max-w-xs overflow-auto"
              >
                <Dropdown.RadioGroup
                  className="min-flex flex-col flex-1"
                  onValueChange={nextValue => {
                    props.onSubmit(nextValue);
                    setIsEditing(false);
                  }}
                >
                  <ul className="min-flex flex-col flex-1">
                    {locations.map(location => {
                      return (
                        <LocationListItem key={location.id} {...location} activeId={props.locationId} isRoot />
                      );
                    })}
                  </ul>
                </Dropdown.RadioGroup>
              </Dropdown.Content>
            </Dropdown>
          ) : (
            value
          );
        }}
      </InlineEditable>
    </div>
  );
}

interface LocationListItemProps extends LocationItem {
  activeId: string | null;
  key: Key;
  depth?: number;
  isRoot?: boolean;
}

function LocationListItem(props: LocationListItemProps) {
  const [isOpen, setIsOpen] = useState(props.isRoot);
  const isActive = props.id === props.activeId;

  const effectiveDepth = props.depth || 0;

  return (
    <li className="flex flex-col flex-1">
      {!props.isRoot && (
        <div className="hover:bg-charcoal-50" style={{ paddingLeft: `${effectiveDepth * 2}rem` }}>
          <Dropdown.RadioItem role="button" value={props.id}>
            <div className="flex items-center">
              {props.sublocations.length > 0 && (
                <div
                  role="button"
                  className="w-8 flex items-center justify-center pr-2 opacity-50 hover:opacity-100"
                  onClick={e => {
                    e.stopPropagation();
                    setIsOpen(!isOpen);
                  }}
                >
                  <ChevronRightIcon size={16} className={clsx(isOpen && 'rotate-90')} />
                </div>
              )}

              <p>{props.name}</p>

              <div className="w-10">{isActive && <CheckmarkIcon className="ml-auto text-blue-600" />}</div>
            </div>
          </Dropdown.RadioItem>
        </div>
      )}

      {isOpen && props.sublocations.length > 0 && (
        <ul className="w-full">
          {props.sublocations.map(sublocation => {
            return (
              <LocationListItem
                key={sublocation.id}
                {...sublocation}
                activeId={props.activeId}
                depth={props.isRoot ? 0 : effectiveDepth + 1}
              />
            );
          })}
        </ul>
      )}
    </li>
  );
}
