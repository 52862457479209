import ProgressRing from '@adsk/alloy-react-progress-ring';
import { Oasis } from '@oasis/sdk';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FadingEllipsis } from '~/shared/components/base/fading-ellipsis';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';
import { AppHistory } from '~/shared/utils/app-history';

export default function InstallingPage() {
  const [searchParams] = useSearchParams();
  const $session = Oasis.Session.useStore();
  const $bridge = Oasis.Bridge?.useStore();
  const branding = useTemporaryBranding();

  const isUnrealViewerDownload = !!searchParams.get('viewer');
  const hasDownloadedUnreal = isUnrealViewerDownload && $bridge?.unrealUpdateStatus === 'DONE';
  const exitPath = $session.status === 'AUTHENTICATED' ? '/projects' : '/login';

  useEffect(() => {
    (async () => {
      if (!Oasis.Bridge) {
        return AppHistory.navigate(exitPath);
      }

      Oasis.Bridge?.resizeTauriWindow(600, 420);

      if (!isUnrealViewerDownload) {
        const result = await Oasis.Bridge.installTauriUpdate();

        if (!result.ok) {
          NotificationManager.push({
            status: 'error',
            indefinite: true,
            content: close => (
              <div className="w-full">
                <p>Failed to install update. Please try again or contact support if the problem persists.</p>

                <div className="flex items-center justify-end mt-6 space-x-2 w-full -mr-10 translate-x-12">
                  <button
                    className="py-2 px-4 bg-white border border-charcoal-200 text-body-md"
                    onClick={() => {
                      AppHistory.navigate('/installing');
                      close();
                    }}
                  >
                    Try Again
                  </button>

                  <button
                    className="py-2 px-4 bg-white border border-charcoal-200 text-body-md"
                    onClick={close}
                  >
                    Ignore
                  </button>
                </div>
              </div>
            ),
          });
        }

        AppHistory.navigate(exitPath);
        Oasis.Bridge.resizeTauriWindow(1366, 768);
      }
    })();
  }, [isUnrealViewerDownload, exitPath]);

  useEffect(() => {
    if (hasDownloadedUnreal) {
      (async () => {
        // If we're on desktop then check for app updates again since
        // we defer to the viewer installation on first launch.
        Oasis.Bridge?.checkForTauriUpdates();

        // Resize the window and send them to their projects list.
        AppHistory.navigate(exitPath);
        Oasis.Bridge?.resizeTauriWindow(1366, 768);
      })();
    }
  }, [isUnrealViewerDownload, hasDownloadedUnreal, exitPath]);

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen space-y-5">
      <img src={branding.logoUrl} alt={branding.productName + ' logo'} className="block h-[2.125rem]" />

      {isUnrealViewerDownload &&
      $bridge?.unrealUpdateStatus === 'DOWNLOADING' &&
      $bridge.unrealUpdateProgress < 100 ? (
        <ProgressRing size="medium" now={$bridge.unrealUpdateProgress} />
      ) : (
        <ProgressRing size="medium" />
      )}

      <p>
        {isUnrealViewerDownload && $bridge ? (
          <>
            {$bridge.unrealUpdateProgress === 100 || $bridge.unrealUpdateStatus === 'UNPACKING'
              ? 'Installing'
              : 'Downloading'}{' '}
            viewer components
          </>
        ) : (
          'Installing updates'
        )}

        <FadingEllipsis />
      </p>
    </div>
  );
}
