import { Placement } from '@popperjs/core';
import clsx from 'clsx';
import { ComponentProps, Key, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

interface Props extends Omit<ComponentProps<'div'>, 'content'> {
  placement?: Placement;
  delay?: number;
  inline?: boolean;
  content: ReactNode;
  key?: Key;
  pointerEventsNone?: boolean;
  containerClass?: string;
}

export function Tooltip({
  placement = 'top',
  delay = 500,
  content,
  containerClass,
  children,
  ...props
}: Props) {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  });

  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(show ? 1 : 0);
  }, [show]);

  const isTop = placement.includes('top');
  const isBottom = placement.includes('bottom');
  const isLeft = placement.includes('left');
  const isRight = placement.includes('right');
  const isStart = placement.includes('start');
  const isEnd = placement.includes('end');
  const isX = isLeft || isRight;
  const isY = isTop || isBottom;

  return (
    <div
      className={clsx('max-w-full', containerClass)}
      onPointerEnter={() => setShow(true)}
      onPointerLeave={() => setShow(false)}
    >
      <div ref={setReferenceElement} className="max-w-full">
        {children}
      </div>

      {show &&
        Boolean(content) &&
        createPortal(
          <div
            {...props}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className={clsx('z-30', props.className)}
          >
            <div style={{ opacity, transitionDelay: `${delay}ms` }}>
              <div className="bg-charcoal-900 text-white rounded-sm py-2 px-3 text-label-sm">{content}</div>

              <div
                ref={setArrowElement}
                style={styles.arrow}
                className={clsx(
                  'absolute h-0 w-0 border-[6px]',
                  isY && 'border-x-[6px] border-x-transparent',
                  isX && 'border-y-[6px] border-y-transparent',
                  isTop && 'bottom-[-10px] border-t-[6px] border-b-transparent border-t-charcoal-900',
                  isBottom && 'top-[-10px] border-b-[6px] border-t-transparent border-b-charcoal-900',
                  isLeft && 'right-[-10px] border-l-[6px] border-r-transparent border-l-charcoal-900',
                  isRight && 'left-[-10px] border-r-[6px] border-l-transparent border-r-charcoal-900',
                  isStart || (isEnd && '!transform-none'),
                  isY && isStart && '!left-2',
                  isY && isEnd && '!right-2',
                  isX && isStart && '!top-2',
                  isX && isEnd && '!bottom-2'
                )}
              />
            </div>
          </div>,
          document.querySelector('body')!
        )}
    </div>
  );
}
