import { Oasis } from '@oasis/sdk';
import { useEffect } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { proxyMap } from 'valtio/utils';
import type { ViewerUser } from './web-viewer-rpc.types';

export const WebViewerRpc = {
  store: proxy({
    users: proxyMap<string, ViewerUser>(),
  }),

  useStore(opts?: { resetOnUnmount?: boolean }) {
    useEffect(() => {
      return () => {
        if (opts?.resetOnUnmount) {
          this.store.users.clear();
        }
      };
    }, [opts?.resetOnUnmount]);

    return useSnapshot(this.store);
  },

  addUser(user: ViewerUser) {
    this.store.users.set(user.id, user);
  },

  removeUser(id: string) {
    this.store.users.delete(id);
  },

  updateUser(id: string, user: Partial<ViewerUser>) {
    const existing = this.store.users.get(id);
    if (existing) this.store.users.set(id, { ...existing, ...user });
  },

  mute(deviceId: string) {
    this.emitViewerEvent('muteUser', JSON.stringify({ target: deviceId }));
  },

  gather(deviceId: string) {
    this.emitViewerEvent('gatherUser', JSON.stringify({ target: deviceId }));
  },

  goToUser(deviceId: string) {
    this.emitViewerEvent('goToUser', JSON.stringify({ target: deviceId }));
  },

  muteAll() {
    this.emitViewerEvent('muteAll', JSON.stringify({}));
  },

  gatherAll() {
    this.emitViewerEvent('gatherAll', JSON.stringify({}));
  },

  emitViewerEvent(event: unknown, json: unknown) {
    const ue = (window as any).ue;

    if (ue) {
      ue.javascripteventhandler.handleevent(event, json);
    } else {
      Oasis.Logger.error({ event, json, msg: `Could not send UE message` });
    }
  },
};
