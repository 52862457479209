import type { ComponentProps } from 'react';

type Props = ComponentProps<'svg'>;

export function EmptyTableIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={218} height={159} fill="none" {...props}>
      <path
        fill="#B4C5D0"
        fillRule="evenodd"
        d="M43.246 74.189v20.819l75.869 43.498 55.638-31.865V82.363l-20.232-12.14v-1.825c33.581 7.293 56.649 22.403 56.649 39.859 0 24.583-45.743 44.51-102.17 44.51-56.428 0-102.17-19.927-102.17-44.51 0-13.671 14.149-25.904 36.416-34.068Z"
        clipRule="evenodd"
      />
      <path
        fill="#1F323D"
        fillRule="evenodd"
        d="M154.521 63.121c11.486 2.503 21.862 5.92 30.698 10.05 19.529 9.125 31.271 21.582 31.271 35.085 0 13.504-11.742 25.961-31.271 35.086-19.467 9.097-46.414 14.744-76.219 14.744-29.805 0-56.752-5.647-76.22-14.744-19.528-9.125-31.27-21.582-31.27-35.086 0-13.503 11.742-25.96 31.27-35.085 3.283-1.535 6.78-2.97 10.466-4.298v-.797C17.416 77.32.76 91.88.76 108.256c0 27.935 48.46 50.58 108.24 50.58 59.779 0 108.24-22.645 108.24-50.58 0-20.336-25.683-37.868-62.719-45.902v.767Z"
        clipRule="evenodd"
      />
      <g opacity={0.3}>
        <path
          fill="#B4C5D0"
          d="m47.166 56.224-.15 14.297 20.072 11.992V67.652L47.166 56.224ZM72.73 70.356l-.149 14.297 20.07 11.993v-14.86l-19.92-11.43ZM72.73 92.67l-.149 14.296 20.07 11.617V103.72l-19.92-11.05ZM47.166 77.43l-.15 14.297 20.072 11.992v-14.86l-19.922-11.43Z"
        />
        <path
          fill="#CDD9E2"
          d="m99.057 14.285 55.315 32.083v24.229L174.673 82.6v24.283L99.057 62.907V14.285Z"
        />
        <path
          stroke="#1F323D"
          strokeDasharray="7 5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity={0.3}
          strokeWidth={0.75}
          d="m174.752 106.991-75.628-44.05-12.665 7.875"
        />
        <path
          stroke="#1F323D"
          strokeDasharray="1.5 1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity={0.6}
          strokeWidth={0.75}
          d="M154.495 46.26 98.872 14.18 43.248 46.312l55.624 31.912 55.623-31.964ZM99.124 102.747l55.624-31.964"
        />
        <path
          stroke="#1F323D"
          strokeDasharray="7 5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity={0.3}
          strokeWidth={0.75}
          d="M154.495 70.743 98.872 38.66 64.54 58.291"
        />
        <path
          stroke="#1F323D"
          strokeDasharray="1.5 1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity={0.6}
          strokeWidth={0.75}
          d="m43.5 95.074 75.628 43.88 55.624-31.964"
        />
        <path
          stroke="#1F323D"
          strokeDasharray="7 5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity={0.3}
          strokeWidth={0.75}
          d="M99.124 14.381V62.94"
        />
        <path
          stroke="#1F323D"
          strokeDasharray="1.5 1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity={0.6}
          strokeWidth={0.75}
          d="M99.124 78.226v24.412M154.495 46.313v24.412M154.748 70.783l20.004 11.812M174.752 106.989V82.595M119.121 139.08v-24.395M99.124 102.748l19.996 11.937M174.753 82.595l-55.632 32.089M43.247 70.138l.252 24.936M43.247 46.313v24.412"
        />
      </g>
    </svg>
  );
}
