import { ChevronDownIcon } from '@adsk/alloy-react-icon';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';

export function Dropdown({ children, ...props }: DropdownMenu.DropdownMenuProps) {
  return <DropdownMenu.Root {...props}>{children}</DropdownMenu.Root>;
}

Dropdown.Trigger = function DropdownTrigger({ children, ...props }: DropdownMenu.DropdownMenuTriggerProps) {
  return (
    <DropdownMenu.Trigger {...props}>
      <div className="flex items-center">
        {children}
        <ChevronDownIcon size={12} className="ml-2" />
      </div>
    </DropdownMenu.Trigger>
  );
};

Dropdown.Content = function DropdownContent({
  children,
  maxHeight,
  ...props
}: DropdownMenu.DropdownMenuContentProps & { maxHeight?: number }) {
  return (
    <DropdownMenu.Content
      {...props}
      align="start"
      side="bottom"
      sideOffset={8}
      className={clsx('w-full bg-white rounded-md shadow-high relative z-10 overflow-auto', props.className)}
      style={{ maxHeight: maxHeight ? `${maxHeight / 16}rem` : undefined }}
    >
      {children}
    </DropdownMenu.Content>
  );
};

Dropdown.RadioItem = function DropdownRadioItem({
  children,
  ...props
}: DropdownMenu.DropdownMenuRadioItemProps) {
  return (
    <DropdownMenu.RadioItem
      {...props}
      className={clsx(
        'p-2 min-w-[200px] max-w-[320px] cursor-pointer hover:bg-charcoal-50 hover:outline-none hover:border-transparent hover:shadow-transparent',
        props.disabled && 'text-charcoal-400',
        props.className
      )}
    >
      {children}
    </DropdownMenu.RadioItem>
  );
};

Dropdown.CheckboxItem = function DropdownCheckboxItem({
  children,
  ...props
}: DropdownMenu.DropdownMenuCheckboxItemProps) {
  return <DropdownMenu.CheckboxItem {...props}>{children}</DropdownMenu.CheckboxItem>;
};

Dropdown.Group = function DropdownSection({ children, ...props }: DropdownMenu.DropdownMenuGroupProps) {
  return (
    <DropdownMenu.Group {...props}>
      <p className="sticky top-0 bg-charcoal-50 px-3 py-2 text-label-sm">{props.title}</p>
      {children}
    </DropdownMenu.Group>
  );
};

Dropdown.RadioGroup = DropdownMenu.RadioGroup;
